import { forwardRef, useMemo } from 'react'
import cn from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import { createSerializers } from '../lib/serializers'
import { theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'

const RichContent = forwardRef(({ className, content, children, options }, ref) => {
  const styles = useStyles()
  const serializers = useMemo(() => createSerializers(options), [options])
  return (
    <div className={cn(styles.container, className)} ref={ref}>
      <BlockContent
        renderContainerOnSingleChild
        blocks={content}
        serializers={serializers}
      />
      {children}
    </div>
  )
})

RichContent.displayName = 'RichContent'

const useStyles = createUseStyles({
  container: {
    '& h2': {
      fontSize: 48,
      maxWidth: '7.1em'
    },
    '& > *:last-child': {
      marginBottom: 0
    },
    '& > div > *:last-child': {
      marginBottom: 0
    },
    '& p + hr': {
      marginTop: `calc(-1.5em + ${theme.spacing(1)}px)`
    }
  }
})

export default RichContent
