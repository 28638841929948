import cn from 'classnames'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { theme } from '../../../styles/theme'
import ResponsiveImage from '../../ResponsiveImage'
import Section from '../../Section'
import IntroHeroDetails from './IntroHeroDetails'

const IntroHero = ({
  data: { column1, column2, imageAlign = 'left', title: sliceTitle },
  page: { title: pageTitle, featuredImage },
  summary
}) => {
  const styles = useStyles()
  const title = useMemo(() => sliceTitle || pageTitle, [sliceTitle, pageTitle])
  return (
    <Section
      grid
      className={cn(styles.root, 'intro-hero', `image-align-${imageAlign}`)}
    >
      <h1 className={styles.mobileTitle}>{title}</h1>
      {featuredImage && (
        <ResponsiveImage
          className={cn(styles.image)}
          image={featuredImage}
          loading={summary ? 'eager' : 'lazy'}
          // loading='eager' eager will not work here, it will load in a image that is not the correct size
          showPreview={!summary}
        />
      )}
      <IntroHeroDetails
        className={styles.details}
        title={title}
        column1={column1}
        column2={column2}
      />
    </Section>
  )
}

const useStyles = createUseStyles({
  root: {
    alignItems: 'start',
    paddingTop: theme.headerSize.sm,
    rowGap: '0!important',
    '& > *': {
      gridColumn: '1 / -1'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.headerSize.md,
      '&.image-align-right': {
        paddingTop: theme.spacing(26)
      }
    },
    '.dialog-grid &': {
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.gutter.md
      }
    }
  },
  mobileTitle: {
    marginTop: theme.spacingPx(5),
    marginBottom: theme.spacingPx(9),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  image: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacingPx(4)
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 5',
      '.image-align-right &': {
        order: 2,
        gridColumnEnd: '-1'
      }
    }
  },
  details: {
    [theme.breakpoints.up('md')]: {
      gridColumn: '7 / span 6',
      '.image-align-left &': {
        gridColumnEnd: '-1',
        paddingTop: theme.spacing(26)
      }
    }
  }
})

IntroHero.propTypes = {
  data: PropTypes.shape({
    imageAlign: PropTypes.oneOf(['left', 'right']).isRequired,
    column1: PropTypes.array.isRequired,
    column2: PropTypes.array,
    title: PropTypes.string
  }).isRequired,
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    featuredImage: PropTypes.object.isRequired
  }).isRequired
}

export default IntroHero
