import { createUseStyles } from '../helpers/createStyles'
import usePrice from '../hooks/usePrice'
import { usePriceFormat } from '../hooks/usePriceFormat'
import { theme } from '../styles/theme'
import Placeholder from './Placeholder'

export default function ProductPrice ({ className, prefix, productId, variantId, placeholderWidth = 70, exTax, allowFree = false, colorVariantId }) {
  const { price, salePrice } = usePrice(productId, variantId, colorVariantId)
  const classes = useStyles()
  const formattedPrice = usePriceFormat(price, undefined, undefined, exTax)
  const formattedSalePrice = usePriceFormat(salePrice, undefined, undefined, exTax)
  return price === undefined
    ? <Placeholder type='text' height='1em' width={placeholderWidth} noBottomMargin />
    : formattedSalePrice
      ? <span className={className}>{prefix}<span className={classes.price}><span className={classes.strikeThrough}>{formattedPrice}</span><span className={classes.sale}>{formattedSalePrice}</span></span></span>
      : <span className={className}>{prefix}{allowFree && formattedPrice === '$0' ? 'Free' : formattedPrice}</span>
}

const useStyles = createUseStyles({
  price: {
    whiteSpace: 'nowrap'
  },
  sale: {
    display: 'inline-block',
    marginLeft: theme.spacing(1)
  },
  strikeThrough: {
    opacity: 0.5,
    textDecoration: 'line-through',
    marginLeft: theme.spacing(1)
  }
})
