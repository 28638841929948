import cn from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from '../../../helpers/createStyles'
import { span, theme } from '../../../styles/theme'
import RichContent from '../../RichContent'

const richContentOptions = { showExternalIcon: false }

const IntroHeroDetails = ({ title, column1, column2, className }) => {
  const styles = useStyles()
  const multi = Boolean(column1 && column2)

  return (
    <div className={cn(styles.root, className)}>
      <h1 className={styles.title}>{title}</h1>
      <div
        className={cn(styles.columns, [multi ? styles.multi : styles.single])}
      >
        {column1 && (
          <RichContent
            className={styles.column}
            content={column1}
            options={richContentOptions}
          />
        )}
        {column2 && (
          <RichContent
            className={styles.column}
            content={column2}
            options={richContentOptions}
          />
        )}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {},
  title: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginBottom: theme.spacingPx(12),
      whiteSpace: 'pre-wrap'
    }
  },
  columns: {
    [theme.breakpoints.up('md')]: {
      display: 'grid'
    }
  },
  single: {
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '3fr 2fr',
      '& > *': {
        gridColumn: '1 / span 1'
      }
    }
  },
  multi: {
    [theme.breakpoints.down('md')]: {
      '& > *:first-child': {
        marginBottom: theme.spacing(5)
      }
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: span(1, 'md'),
      '& > *': {
        gridColumn: 'auto / span 1'
      }
    }
  }
})

IntroHeroDetails.propTypes = {
  title: PropTypes.string.isRequired,
  column1: PropTypes.array.isRequired,
  column2: PropTypes.array,
  className: PropTypes.string
}

export default IntroHeroDetails
