import { useCallback, useRef, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { useScrollListener } from '../components/SmoothScrollbar/useSmoothScrollbar'
import afterFrame from '../helpers/afterFrame'
import { isBrowser } from '../lib/helpers'
import { theme } from '../styles/theme'

const mobileBreakpoint = theme.breakpoints.values.md

export const useStickyElement = (contentRef, containerRef, offset = 0, padding = 0) => {
  const locals = useRef({})

  const [isMobile, setIsMobile] = useState(isBrowser ? window.innerWidth <= mobileBreakpoint : true)

  useEffect(() => {
    if (isBrowser) {
      setIsMobile(window.innerWidth <= mobileBreakpoint)
      const handleResize = () => {
        setIsMobile(window.innerWidth <= mobileBreakpoint)
      }
      const debouncedHandleResize = debounce(handleResize, 500)
      window.addEventListener('resize', debouncedHandleResize, { passive: true })
      afterFrame(() => {
        if (contentRef.current) {
          let end = null
          if (containerRef) {
            const containerElement = typeof containerRef === 'string'
              ? document.getElementById(containerRef)
              : typeof containerRef === 'function'
                ? containerRef()
                : containerRef.current
            end = containerElement
              ? containerElement.offsetTop + containerElement.offsetHeight - contentRef.current.offsetHeight - (offset || 0)
              : null
          }
          locals.current = {
            start: contentRef.current.offsetTop - (offset || 0),
            end: end + padding
          }
        }
      })
      return () => {
        window.removeEventListener('resize', debouncedHandleResize)
      }
    }
  }, [])

  useScrollListener(useCallback(({ y }) => {
    const { start, end } = locals.current
    if (contentRef.current) {
      if (!isMobile) {
        if ((start || start === 0) && end) {
          if (y >= start && y <= end) {
            contentRef.current.style.transform = `translate3d(0,${y - start}px,0)`
          } else if (y > end) {
            contentRef.current.style.transform = `translate3d(0, ${end}px, 0)`
          } else {
            contentRef.current.style.transform = 'translate3d(0, 0, 0)'
          }
        } else if (start || start === 0) {
          if (y >= start) {
            contentRef.current.style.transform = `translate3d(0,${y - start}px,0)`
          } else {
            contentRef.current.style.transform = 'translate3d(0, 0, 0)'
          }
        }
      } else {
        contentRef.current.style.transform = 'translate3d(0, 0, 0)'
      }
    }
  }), [locals, isMobile])
}
