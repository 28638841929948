import Head from 'next/head'
import get from 'lodash/get'
import { builder as imageBuilder } from './ResponsiveImage/transform'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { resolveInternalLinkUrl } from '../lib/resolveLink'
import * as fonts from './../styles/fonts'

const Meta = ({ seo, page, settings, localeVariations }) => {
  const metaImage = get(seo, ['meta_image', 'asset'])
  const imageUrl = metaImage && imageBuilder.image(metaImage).width(1200).height(630).url()
  const { asPath: pathname } = useRouter()
  const baseUrl = settings.siteUrl

  const metadata = useMemo(() => {
    const defaultCanonicalUrl = `${baseUrl}${pathname}`
    const pageTitle = get(page, ['title'])
    const siteTitle = get(settings, ['siteTitle'])
    let title = pageTitle || siteTitle
    if (title !== siteTitle) {
      title = `${title} | ${siteTitle}`
    }
    return {
      ...(seo || {}),
      ...{
        meta_title: seo?.meta_title || pageTitle,
        site_name: siteTitle,
        og_url: defaultCanonicalUrl,
        canonical_url: defaultCanonicalUrl,
        title
      }
    }
  }, [seo, settings, page, pathname])

  const metaDesc = metadata.meta_description
  const metaTitle = metadata.meta_title
  const siteName = metadata.site_name

  // We add the other page locale variations as links in the header
  // See https://developers.google.com/search/docs/advanced/crawling/localized-versions#html

  return (
    <Head>
      <title>{metadata.title}</title>
      {localeVariations && localeVariations.map((page) => {
        return (
          <link
            key={page.locale}
            rel='alternate'
            hrefLang={page.locale.toLowerCase()}
            href={`${page.siteUrl}${resolveInternalLinkUrl(page)}`}
          />
        )
      })}
      <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no' />
      {metadata.meta_keywords && <meta name='keywords' content={metadata.meta_keywords} />}
      {metadata.og_url && <meta property='og:url' content={metadata.og_url} />}
      {metaTitle && (
        <>
          <meta property='title' content={metaTitle} />
          <meta property='og:title' content={metaTitle} />
          <meta name='twitter:title' content={metaTitle} />
        </>
      )}
      {metaDesc && (
        <>
          <meta name='description' content={metaDesc} />
          <meta property='og:description' content={metaDesc} />
          <meta name='twitter:description' content={metaDesc} />
        </>
      )}
      {metaImage && (
        <>
          <meta property='og:image' content={imageUrl} />
          <meta property='og:image:width' content={1200} />,
          <meta property='og:image:height' content={630} />
          <meta name='twitter:image' content={imageUrl} />
        </>
      )}
      {siteName && (
        <>
          <meta property='og:site_name' content={metadata.site_name} />
          <meta name='twitter:site' content={metadata.site_name} />
        </>
      )}

      <link rel='canonical' href={metadata.canonical_url} />

      <link rel='apple-touch-icon' sizes='120x120' href='/favicon/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
      <link rel='manifest' href='/favicon/site.webmanifest' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />

      <link rel='manifest' href='/favicon/site.webmanifest' />
      {fonts.preload.map(({ src }) => (
        <link
          key={src}
          rel='preload'
          href={src}
          type='font/woff2'
          as='font'
          crossOrigin=''
        />
      ))}
      <meta name='theme-color' content='#000' />
    </Head>
  )
}

export default Meta
