import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from '../../helpers/createStyles'
import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'
import gsap from 'gsap'
import CaretIcon from './caret.svg'
import { theme } from '../../styles/theme'
import cn from 'classnames'
import useOnClickOutside from '../../hooks/useOnOutsideClick'

const getOptionText = (option, emptyText) => {
  return get(option, ['text'], option) || emptyText
}

const getOptionValue = (option, emptyText) => {
  return get(option, ['value'], option)
}

export default function Dropdown ({ className, label, options, value, onChange, emptyText }) {
  const [open, setOpen] = useState()
  const styles = useStyles()
  const dropdownRef = useRef()
  const containerRef = useRef()
  const caretRef = useRef()

  const formattedText = useMemo(() => {
    const option = find(options, x => x === value || x.value === value)
    return getOptionText(option, emptyText)
  }, [value, options, emptyText])

  const [currentText, setCurrentText] = useState(formattedText)

  useEffect(() => {
    setCurrentText(formattedText)
  }, [formattedText])

  const onOptionClicked = useCallback((option) => {
    onChange(getOptionValue(option))
    setOpen(false)
    setCurrentText(getOptionText(option))
  }, [])

  const onClick = useCallback(() => {
    setOpen(value => !value)
  }, [])

  useEffect(() => {
    gsap.to(dropdownRef.current, {
      height: open ? 'auto' : '0%',
      pointerEvents: open ? 'all' : 'none',
      duration: 0.5,
      ease: 'expo.out'
    })
    gsap.to(caretRef.current, { rotate: open ? 180 : 0, duration: 0.15 })
  }, [open])

  useOnClickOutside(containerRef, useCallback(() => { setOpen(false) }, []), open)

  return (
    <div className={cn(styles.container, className)} ref={containerRef}>
      <button className={cn(styles.dropdownButton, { open })} onClick={onClick}>
        {label && <label className={styles.label}>{label}</label>}
        <span className={styles.value}>{currentText}</span>
        <div ref={caretRef} className={styles.caret}><CaretIcon /></div>
      </button>
      <div className={styles.dropdown} ref={dropdownRef}>
        <div className={styles.dropdownInner}>
          {map(options, option => (
            <button className={styles.dropdownItemButton} key={getOptionText(option)} onClick={() => onOptionClicked(option)}>{getOptionText(option)}</button>
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    height: 64,
    zIndex: theme.zIndex.dropDown, // this needs to go over any other elements on the page
    [theme.breakpoints.up('sm')]: {
      height: 72,
      maxWidth: 330
    }
  },
  dropdownButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.3)',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    minHeight: 42,
    margin: 0,
    borderRadius: 0,
    backgroundColor: theme.colors.background,
    position: 'relative',
    zIndex: theme.zIndex.dropDownButton,
    transition: 'border-color 0.5s ease-in-out',
    '&:hover, &.open': {
      borderColor: 'rgba(0,0,0,1)'
    }
  },
  label: {
    cursor: 'pointer',
    display: 'block',
    marginRight: 4
  },
  value: {
    opacity: 0.5
  },
  dropdown: {
    zIndex: theme.zIndex.dropDown,
    position: 'absolute',
    left: 0,
    top: '100%',
    height: 0,
    pointerEvents: 'none',
    marginTop: -2,
    width: '100%',
    overflow: 'hidden',
    border: '1px solid rgba(0,0,0,1)'
  },
  dropdownInner: {
    width: '100%',
    backgroundColor: theme.colors.background,
    padding: `${theme.spacing(1) + 2}px ${theme.spacing(2)}px`
  },
  dropdownItemButton: {
    textAlign: 'left',
    display: 'block',
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    opacity: 0.5,
    transition: 'opacity 0.15s ease-in-out',
    padding: '4px 0px',
    '&:hover': {
      opacity: 1
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  },
  caret: {
    marginLeft: theme.spacing(1),
    '& > svg': {
      display: 'block',
      width: 12,
      height: 7
    }
  }
})
