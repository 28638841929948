import { createUseStyles } from '../../helpers/createStyles'
import { theme } from '../../styles/theme'
import CheckIcon from '../../icons/check.svg'
import { setShopFilters } from '../../store/shopFiltersSlice'

const StockFilter = ({ label, filterState }) => {
  const styles = useStyles()

  const toggleStockFilter = () => {
    const prevFilterState = filterState.inStockOnly
    const newState = {
      ...filterState,
      inStockOnly: !prevFilterState
    }
    setShopFilters(newState)
  }

  return (
    <div className={styles.stockFilterWrapper}>
      <button className={styles.stockFilterButton} onClick={() => toggleStockFilter()}><span>{label}</span> <div className={styles.checkBox}>{filterState?.inStockOnly && <CheckIcon />}</div></button>
    </div>
  )
}

const useStyles = createUseStyles({
  stockFilterWrapper: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '&:first-child': {
      borderTop: '1px solid rgba(0, 0, 0, 0.1)'
    }
  },
  stockFilterButton: {
    width: '100%',
    fontSize: 18,
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px 0px`
  },
  checkBox: {
    height: 12,
    width: 12,
    border: '1px solid #000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default StockFilter
