import cn from 'classnames'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { createUseStyles } from '../../helpers/createStyles'
import Tab from './Tab'
import ArrowIcon from './arrow.svg'
import { theme } from '../../styles/theme'
import useWindowResize from '../../hooks/useWindowResize'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'

const Tabs = forwardRef(({ className, selected, onTabChanged, tabs, hideFilterPills }, ref) => {
  const styles = useStyles()
  const tabsRef = useRef()
  const [pager, setPager] = useState({ showNext: false, showPrevious: false })

  const onNextClick = useCallback(() => {
    tabsRef.current.scrollBy(100, 0)
  }, [])

  const onPreviousClick = useCallback(() => {
    tabsRef.current.scrollBy(-100, 0)
  }, [])

  const updatePagerStatus = useCallback(() => {
    const tabsElement = tabsRef.current
    if (tabsElement) {
      const pagerState = { showNext: false, showPrevious: false }
      if (tabsElement.scrollWidth > tabsElement.clientWidth) {
        pagerState.showPrevious = tabsElement.scrollLeft > 0
        pagerState.showNext = (tabsElement.scrollLeft + tabsElement.clientWidth) < tabsElement.scrollWidth
      }
      setPager(pagerState)
    }
  }, [])

  const debouncedUpdatePagerStatus = useDebouncedCallback(updatePagerStatus, 100)
  useWindowResize(debouncedUpdatePagerStatus)
  useEffect(() => {
    updatePagerStatus()
    tabsRef.current.addEventListener('scroll', debouncedUpdatePagerStatus, { passive: true })
  }, [debouncedUpdatePagerStatus])

  return (
    <div className={cn(className, styles.tabContainer)} ref={ref}>
      <div className={styles.tabs} ref={tabsRef}>
        {!hideFilterPills && (
          tabs && tabs.map((tab, i) => (
            <Tab key={i} tab={tab} index={i} selected={selected === i} onTabChanged={onTabChanged} />
          ))
        )}
      </div>
      <button className={cn(styles.pagerButton, styles.prev, { show: pager.showPrevious })} onClick={onPreviousClick}>
        <ArrowIcon />
      </button>
      <button className={cn(styles.pagerButton, styles.next, { show: pager.showNext })} onClick={onNextClick}>
        <ArrowIcon />
      </button>
    </div>
  )
})

export default Tabs

const useStyles = createUseStyles({
  tabContainer: {
    position: 'relative'
  },
  tabs: {
    display: 'flex',
    overflow: 'auto',
    scrollbarWidth: 'none',
    position: 'relative',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    margin: `0 -${theme.gutter.sm}px`,
    padding: `0 ${theme.gutter.sm}px`,
    [theme.breakpoints.up('md')]: {
      margin: `0 -${theme.gutter.md}px`,
      padding: `0 ${theme.gutter.md}px`
    }
  },
  pagerButton: {
    pointerEvents: 'none',
    opacity: 0,
    border: 'none',
    cursor: 'pointer',
    borderRadius: 0,
    outline: 'none',
    position: 'absolute',
    width: 60,
    display: 'flex',
    top: theme.spacing(1),
    bottom: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    background: `linear-gradient(270deg, ${theme.colors.background} 14.06%, rgba(249, 252, 248, 0) 100%)`,
    transition: 'opacity 0.25s ease-in-out',
    '&.show': {
      opacity: 1,
      pointerEvents: 'all'
    }
  },
  next: {
    right: -theme.gutter.sm,
    [theme.breakpoints.up('md')]: {
      right: -theme.gutter.md
    }
  },
  prev: {
    transform: 'rotate(180deg)',
    left: -theme.gutter.sm,
    [theme.breakpoints.up('md')]: {
      left: -theme.gutter.md
    }
  }
})
