import { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import gsap from 'gsap'
import cn from 'classnames'
import ChevronDownIcon from '../icons/chevron-down.svg'
import { createUseStyles } from '../helpers/createStyles'
import { theme } from '../styles/theme'

const Accordion = ({
  className,
  label,
  children,
  expanded: controlExpanded,
  setExpanded: controlSetExpanded
}) => {
  const styles = useStyles()
  const ref = useRef()
  const [internalExpanded, setInternalExpanded] = useState(false)

  const handleToggle = useCallback(() => {
    if (typeof controlSetExpanded === 'function') {
      controlSetExpanded(value => !value)
    } else {
      setInternalExpanded(value => !value)
    }
  }, [])

  const expanded = useMemo(
    () =>
      typeof controlExpanded === 'boolean' ? controlExpanded : internalExpanded,
    [internalExpanded, controlExpanded]
  )

  useEffect(() => {
    if (ref.current) {
      gsap.to(ref.current, {
        height: expanded ? 'auto' : 0,
        pointerEvents: expanded ? 'all' : 'none',
        duration: 0.25
      })
    }
  }, [expanded])

  return (
    <div className={cn(styles.accordion, className || null)}>
      <button
        type='button'
        className={styles.accordionBtn}
        onClick={handleToggle}
        aria-label={`${expanded ? 'Hide' : 'Reveal'} answer to: ${label}`}
      >
        <span className={styles.label}>{label}</span>
        <div className={cn(styles.icon, expanded && 'expanded')}>
          <ChevronDownIcon />
        </div>
      </button>
      <div ref={ref} className={cn(styles.hiddenContent, { expanded })}>
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  accordion: {},
  accordionBtn: {
    width: '100%',
    fontSize: 18,
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px 0px`
  },
  label: {
    textAlign: 'left',
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginLeft: theme.spacing(1),
    flexShrink: 0,
    flexGrow: 0,
    '&.expanded': {
      svg: {
        transform: 'rotateX(-180deg) !important'
      }
    },
    '& svg': {
      display: 'block',
      width: 10,
      height: 5,
      transition: 'transform 0.3s ease',
      transform: 'rotateX(0deg)',
      [theme.breakpoints.up('md')]: {
        width: 10,
        height: 5
      }
    }
  },
  hiddenContent: {
    height: 0,
    pointerEvents: 'none',
    overflow: 'hidden'
  },
  container: {
    padding: `0px 0px ${theme.spacing(2)}px `
  }
})

export default Accordion
