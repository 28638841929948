import cn from 'classnames'
import ResponsiveImage from '../ResponsiveImage'
import { createUseStyles } from '../../helpers/createStyles'
import { theme } from '../../styles/theme'
import Section from '../Section'
import CategoryTitle from '../CategoryTitle'
import ShareLink from './ShareLink'

const JournalHero = ({
  data: { desktop_image: desktopImage, mobile_image: mobileImage, hideShare },
  page
}) => {
  const styles = useStyles()
  return (
    <>
      <ResponsiveImage
        image={desktopImage}
        className={cn(styles.image)}
        mobileImage={mobileImage}
        loading='eager'
        aspect={2.4}
      />
      <Section grid noBottomMargin>
        <div className={cn(styles.content)}>
          <div className={cn(styles.meta)}>
            <CategoryTitle className={styles.category}>
              {page.category?.title}
            </CategoryTitle>
            {!hideShare && <ShareLink />}
          </div>
        </div>
        <h1 className={cn(styles.content, styles.title)}>{page.title}</h1>
      </Section>
    </>
  )
}

const useStyles = createUseStyles({
  image: {
    marginBottom: theme.spacingPx(5),
    paddingTop: theme.headerSize.sm,
    '.dialog-grid &': {
      marginTop: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(7),
      paddingTop: theme.headerSize.md,
      '.dialog-grid &': {
        marginTop: 0
      }
    }
  },
  content: {
    gridColumn: '1 / span 8',
    [theme.breakpoints.up('md')]: {
      gridColumnStart: 5,
      '.dialog-grid &': {
        gridColumnStart: 4
      }
    }
  },
  meta: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline'
    }
  },
  category: {
    fontSize: '11px!important'
  },
  title: {
    marginBottom: theme.spacingPx(5),
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      gridColumnEnd: 'span 6',
      marginBottom: theme.spacingPx(8)
    }
  }
})

export default JournalHero
