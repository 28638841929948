import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import cn from 'classnames'
import get from 'lodash/get'
import Section from '../../Section'
import Slices from '../../Slices'
import useSnapshot from '../../../store/useSnapshot'
import { getLayout, LAYOUT, loadNextPage, setLayout } from '../../../store/productListing'
import { getSiteData, getPageData, openShopFiltersDialog } from '../../../store/layoutSlice'
import ProductTile from '../ProductTiles/ProductTile'
import { createUseStyles } from '../../../helpers/createStyles'
import { grid, span, theme } from '../../../styles/theme'
import RichContent from '../../RichContent'
import useProductVariants from './useProductVariants'
import useProductVariantFilter from './useProductVariantFilter'
import NavigationTabs from '../NavigationTabs'
import delay from 'lodash/delay'
import { isShopFilterEmpty } from '../../ShopFiltersDialog'
import { getProductsLoaded } from '../../../store/productsSlice'
import range from 'lodash/range'
import useScrollTrigger from '../../../hooks/useScrollTrigger'
import FloatingFilterButton from './FloatingFilterButton'
import { setShopFiltersCategory } from '../../../store/shopFiltersSlice'
import useProductsWithoutDonations from './useProductsWithoutDonations'
import { getCurrencyCode } from '../../../store/pricesSlice'
import { trackProductImpression } from '../../../lib/gtag'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import FilterButton from './FilterButton'

export default function ProductListing ({ data, summary }) {
  const localsRef = useRef({ })
  const loadMoreRef = useRef()
  const styles = useStyles()
  const snap = useSnapshot()
  const { page: { slug: categorySlug } } = getPageData(snap)
  const page = get(data, ['page'])
  const currencyCode = getCurrencyCode(snap)
  const { title, copyItems, category, copy, onSaleProductsOnly, inStockProductsOnly, preListingSlices, featuredProductRefs } = data
  const layout = getLayout(snap)
  const products = useProductsWithoutDonations(snap)
  const productsLoaded = getProductsLoaded(snap)
  const variants = useProductVariants(products)
  const variantsInSlice = useProductVariants(data.products)
  const isFilterEmpty = isShopFilterEmpty(snap)

  const { useCompactGrid } = data

  const { shopFilterLabel = 'Filter & Sort' } = getSiteData(snap)

  const [paginatedFilteredVariants, allFilteredVariants] = useProductVariantFilter(
    variants,
    category,
    onSaleProductsOnly,
    inStockProductsOnly,
    featuredProductRefs
  )

  useEffect(() => {
    if (!useCompactGrid) {
      setLayout(isFilterEmpty ? 'styled' : 'uniform')
    } else {
      setLayout('uniform')
    }
  }, [useCompactGrid, isFilterEmpty])

  useEffect(() => {
    setShopFiltersCategory(category)
  }, [category])

  let copyIndex = 0

  const productItems = useMemo(() => {
    if (productsLoaded) return paginatedFilteredVariants
    return [
      ...variantsInSlice.slice(0, 3),
      ...range(11).map(() => ({ placeholder: true }))
    ]
  }, [paginatedFilteredVariants, productsLoaded])

  const listSource = useMemo(() => ({
    name: onSaleProductsOnly ? 'Sale' : category?.name,
    id: categorySlug
  }), [categorySlug, onSaleProductsOnly, category])

  const trackImpressions = useCallback((products) => {
    const datalayerObjects = products?.map(({ product, variantId }) => {
      return {
        id: variantId,
        name: product.title,
        category: product.primaryCategory?.name,
        variant: product.colors?.find((color) => color.bigCommerceId === variantId)?.label
      }
    })
    trackProductImpression(datalayerObjects, listSource, currencyCode)
  }, [currencyCode, onSaleProductsOnly])

  useEffect(() => {
    if (allFilteredVariants.length > 0 && !summary) {
      trackImpressions(allFilteredVariants)
    }
  }, [allFilteredVariants, summary])

  useEffect(() => {
    if (loadMoreRef.current) {
      const handler = (entries) => {
        const intersecting = entries[0]?.isIntersecting
        if (intersecting && !localsRef.current.delay) {
          loadNextPage(allFilteredVariants.length, paginatedFilteredVariants.length)
          localsRef.current.delay = true
          delay(() => { localsRef.current.delay = false }, 500)
        }
      }

      const observer = new window.IntersectionObserver(handler, { triggerOnce: false })
      observer.observe(loadMoreRef.current)
      return () => {
        observer.disconnect()
      }
    }
  }, [productsLoaded, productItems])

  // Refresh scrollTrigger positions to ensure productItem tiles animate in at the correct time
  useEffect(() => { ScrollTrigger.refresh() }, [productItems])
  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.out',
        duration: 0.8
      }
      tl.set(ref.current, { opacity: 1 })
      tl.from(ref.current.querySelectorAll(`.${styles.headerCopy}, .${styles.copy}, .${styles.tile}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults }, '+=0.25')
    }
  )

  return (
    <section>
      <NavigationTabs title={title} summary={summary}>
        <FilterButton onClick={openShopFiltersDialog} label={shopFilterLabel} containerRef={sectionRef} size='md'/>
      </NavigationTabs>

      <FloatingFilterButton onClick={openShopFiltersDialog} label={shopFilterLabel} containerRef={sectionRef} />

      <Section tag='div' ref={sectionRef} className={cn(styles.section)}>
        {copy && <RichContent content={copy} className={styles.headerCopy} />}
        <Slices slices={preListingSlices} page={page} />
        <div className={cn(styles.grid, layout, styles.sectionEndMargin)}>
          {productItems?.map(({ product, variantId, placeholder }, i) => {
            const showBlankSpace = layout === LAYOUT.styled && (
              i % 14 === 0 || (i - 3) % 14 === 0 || (i - 9) % 14 === 0)
            if (showBlankSpace) copyIndex += 1
            return (
              <React.Fragment key={variantId || i}>
                {showBlankSpace && (
                  <div key={i} className={styles.copy}>
                    {copyItems && copyItems[copyIndex - 1] && <RichContent content={copyItems[copyIndex - 1].copy} />}
                  </div>
                )}
                <div className={cn(
                  styles.tile,
                  i % 3 === 0 ? 'mobileLarge' : 'mobileSmall',
                  i % 14 === 1 || i % 14 === 5 || i % 14 === 13 ? 'large' : 'small'
                )}
                >
                  <ProductTile
                    tileIndex={i}
                    layout='portrait'
                    product={product}
                    colorVariantId={variantId}
                    placeholder={placeholder}
                    index={i}
                    mobileAspect={layout === LAYOUT.styled && i % 3 === 0 ? 372 / 350 : null}
                    showPreviewImage={false}
                    tileSource={listSource}
                    animateIn
                  />
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </Section>
      {allFilteredVariants.length > paginatedFilteredVariants.length && productsLoaded && <div ref={loadMoreRef} />}
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
  },
  section: {
    opacity: 0
  },
  sectionEndMargin: {
    [theme.breakpoints.between('sm', 'xl')]: {
      paddingBottom: 40
    }
  },
  toolbar: {
    marginBottom: theme.spacingPx(4),
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(18),
      display: 'flex'
    }
  },
  tile: {
    zIndex: 4
  },
  filterButton: {
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      minWidth: 330,
      width: 'auto'
    }
  },
  title: {
    marginBottom: theme.spacingPx(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  headerCopy: {
    marginBottom: theme.spacing(4),
    '& p': {
      fontSize: 16
    },
    [theme.breakpoints.up('md')]: {
      textIndent: '25.55vw',
      marginLeft: span(1, 'md'),
      width: span(8, 'md'),
      marginBottom: theme.spacing(18),
      '& p': {
        fontSize: 24
      }
    }
  },
  copy: {
    padding: theme.spacingPx(3),
    gridColumn: '1 / -1',
    textAlign: 'center',
    margin: 'auto',
    display: 'none',
    maxWidth: 283,
    '&:nth-child(1)': {
      display: 'block',
      gridRow: 3,
      [theme.breakpoints.up('md')]: {
        gridRow: 1
      }
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      gridColumn: 'span 3',
      padding: `0 ${theme.spacingPx(6)} 0 0`,
      textAlign: 'left',
      margin: '0'
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(${grid.sm.columns}, minmax(0px, 1fr))`,
    rowGap: theme.spacingPx(4),
    columnGap: `${grid.sm.gutter}px`,
    [theme.breakpoints.up('md')]: {
      rowGap: theme.spacingPx(10),
      columnGap: `${grid.md.gutter}px`,
      gridTemplateColumns: `repeat(${grid.md.columns}, minmax(0px, 1fr))`
    },
    '&.uniform': {
      '& > *': {
        gridColumn: 'span 4'
      },
      [theme.breakpoints.up('md')]: {
        '& > *': {
          gridColumn: 'span 3'
        }
      }
    },
    '&.styled': {
      '& .mobileSmall': {
        gridColumn: 'span 4'
      },
      '& .mobileLarge': {
        gridColumn: 'span 8'
      },
      '& .small': {
        [theme.breakpoints.up('md')]: {
          gridColumn: 'span 3'
        }
      },
      '& .large': {
        [theme.breakpoints.up('md')]: {
          gridColumn: 'span 6'
        }
      }
    }
  },
  dot: {
    display: 'inline-block',
    height: 6,
    width: 6,
    background: 'currentColor',
    borderRadius: '50%',
    marginLeft: 8
  }
})
