import { forwardRef, useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { theme } from '../../../styles/theme'
import cn from 'classnames'
import range from 'lodash/range'
import useComposeRefs from '../../../hooks/useComposeRefs'
import gsap from 'gsap'
import toArray from 'lodash/toArray'
import { useScrollListener } from '../../SmoothScrollbar/useSmoothScrollbar'

const Pager = forwardRef(({ className, index, totalPages, onIndexChanged }, ref) => {
  const containerRef = useRef()
  const styles = useStyles()
  useScrollListener(useCallback(({ y }) => {
    if (containerRef.current) {
      containerRef.current.style.transform = `translateY(${y}px)`
    }
  }, []), true, true)
  useEffect(() => {
    if (containerRef.current) {
      const elements = toArray(containerRef.current.children)
      const beforeActive = elements.slice(0, index)
      const afterActive = elements.slice(index + 1)
      const activeElement = containerRef.current.children[index]
      gsap.to(beforeActive, { scaleY: 1, y: -6, opacity: 0.5, duration: 1 })
      gsap.to(afterActive, { scaleY: 1, y: 6, opacity: 0.5, duration: 1 })
      gsap.to(activeElement, { scaleY: 2, y: 0, opacity: 1, duration: 1 })
    }
  }, [index])
  return (
    <div className={cn(className, styles.pager)} ref={useComposeRefs(ref, containerRef)}>
      {range(totalPages).map((_, i) => (
        <span key={i} className={styles.indicator} onClick={() => onIndexChanged(i)} />
      ))}
    </div>
  )
})

export default Pager

const useStyles = createUseStyles({
  pager: {
    position: 'absolute',
    top: '50vh',
    transform: 'translate(0, -50%)',
    flexDirection: 'column',
    zIndex: 100,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      left: theme.gutter.md
    }
  },
  indicator: {
    opacity: 0.5,
    cursor: 'pointer',
    width: 1,
    margin: '4px 0',
    border: 'none',
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    backgroundColor: theme.colors.white,
    height: 12
  }
})
