import { useContext } from 'react'
import Section from '../Section'
import ResponsiveImage from '../ResponsiveImage'
import { theme } from '../../styles/theme'
import { createUseStyles } from '../../helpers/createStyles'
import cn from 'classnames'
import { SmoothScrollContext } from '../SmoothScrollbar/useSmoothScrollbar'

export default function LandingHero ({ data, summary }) {
  const { title, image, copy } = data
  const styles = useStyles()
  const scrollContext = useContext(SmoothScrollContext)

  return (
    <Section fullWidth className={cn(styles.section)}>
      <div className={cn(styles.wrapper)}>
        <div className={cn(styles.content, { contained: copy, noImage: !image })}>
          {title && <h1 className={styles.title}>{title}</h1>}
        </div>
        {image && (
          <ResponsiveImage
            image={image}
            loading='eager'
            aspect={1440 / 900}
            mobileAspect={375 / 626}
            showPreview={!summary}
            className={styles.image}
          />
        )}
        <a className={cn(styles.arrow)} href="#" onClick={e => { e.preventDefault(); scrollContext?.current.setScrollY(window.innerHeight, 1500) }}>&#8595;</a>
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    overflow: 'hidden'
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100vh'
  },
  image: {
    width: '100vw',
    height: '100vh'
  },
  content: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    zIndex: 99,
    padding: '0 30px',

    h1: {
      color: theme.colors.white,
      marginBottom: 0,
      fontSize: 35
    },

    [theme.breakpoints.up('xs')]: {
      paddingLeft: 100
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 250
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 300,
      h1: {
        fontSize: 50,
        maxWidth: 700
      }
    },
    [theme.breakpoints.up('lg')]: {
      h1: {
        fontSize: 64,
        maxWidth: 900
      }
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 400
    }
  },
  arrow: {
    position: 'absolute',
    color: '#fff',
    fontSize: 60,
    zIndex: 999,
    textDecoration: 'none',
    fontFamily: 'Iskry',
    width: 60,
    bottom: 20,
    left: '50%',
    textAlign: 'center',
    transform: 'translateX(-30px)'
  }
})
