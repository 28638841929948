import cn from 'classnames'
import { keyframes } from 'goober'
import range from 'lodash/range'
import { createUseStyles } from '../helpers/createStyles'
import { theme } from '../styles/theme'

export function PlaceholderProductMeta () {
  const classes = useStyles()
  return (
    <div className={classes.meta}>
      <Placeholder type='text' variant='light' height='1em' />
      <Placeholder type='text' variant='light' height='1em' />
    </div>
  )
}

const lineWidths = ['80%', '30%', '50%']

export default function Placeholder ({ className, type, aspect, mobileAspect, multiline, variant, width, height, noBottomMargin, blink = true }) {
  const classes = useStyles()
  if (type === 'image') {
    return (
      <div className={cn(classes.image, className, variant, { blink, noBottomMargin })}>
        <div className={classes.desktopAspect} style={{ paddingTop: `${100 / aspect}%` }} />
        <div className={classes.mobileAspect} style={{ paddingTop: `${100 / (mobileAspect || aspect)}%` }} />
      </div>
    )
  }
  if (type === 'text') {
    if (multiline) {
      return range(multiline).map((_, i) => (
        <div key={i} className={cn(classes.text, className, { blink, noBottomMargin }, i % 2 === 1 ? 'light' : '')} style={{ width: lineWidths[i % lineWidths.length], height }} />
      ))
    } else {
      return <div className={cn(classes.text, className, variant, { blink, noBottomMargin })} style={{ height, width }} />
    }
  }
  return null
}

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`

const blinkLight = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 0.2; }
  100% { opacity: 0.5; }
`

const useStyles = createUseStyles({
  image: {
    backgroundColor: theme.colors.imageBackground,
    marginBottom: theme.spacing(1),
    '&.light': {
      opacity: 0.5
    },
    '&.blink': {
      animation: `${blink} 2s infinite`,
      '&.light': {
        animation: `${blinkLight} 2s infinite`,
        opacity: 0.5
      }
    },
    '&.noBottomMargin': {
      marginBottom: 0
    }
  },
  desktopAspect: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mobileAspect: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  text: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.colors.imageBackground,
    height: '1.5em',
    borderRadius: 5,
    '&.light': {
      opacity: 0.5
    },
    '&.blink': {
      animation: `${blink} 2s infinite`,
      '&.light': {
        animation: `${blinkLight} 2s infinite`
      }
    },
    '&.noBottomMargin': {
      marginBottom: 0
    }
  },
  meta: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    '& > *': {
      width: '20%'
    }
  }
})
