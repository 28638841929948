import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { isBrowser } from '../lib/helpers'
import { theme } from '../styles/theme'

const useIsMobile = (delay = 500) => {
  const [isMobile, setIsMobile] = useState(isBrowser ? theme.breakpoints.values.md <= 800 : false)

  useEffect(() => {
    if (isBrowser) {
      const handleResize = () => setIsMobile(window.innerWidth <= 1075)
      const debouncedHandleResize = debounce(handleResize, delay)
      window.addEventListener('resize', debouncedHandleResize, { passive: true })
      handleResize()
      return () => {
        window.removeEventListener('resize', debouncedHandleResize)
      }
    }
  }, [delay])

  return isMobile
}

export default useIsMobile
