import React, { useRef, useContext, useCallback } from 'react'
import cn from 'classnames'
import ResponsiveImage from '../../ResponsiveImage'
import ProductMeta from './ProductMeta'
import { theme } from '../../../styles/theme'
import Link from '../../Link'
import { useProductVariantDetails } from '../../../hooks/useProductVariantDetails'
import { useHoverEvents } from '../../../hooks/useHover'
import gsap from 'gsap'
import { createUseStyles } from '../../../helpers/createStyles'
import { primaryInput } from 'detect-it'
import Placeholder, { PlaceholderProductMeta } from '../../Placeholder'
import { DialogContext } from '../../Dialog/PageDialog'
import { useRouter } from 'next/router'
import { trackProductClick } from '../../../lib/gtag'
import useScrollTrigger from '../../../hooks/useScrollTrigger'
import usePrice from '../../../hooks/usePrice'
import first from 'lodash/first'

const ProductTile = (props) => {
  const {
    tileIndex,
    className,
    product,
    colorVariantId,
    variantId,
    image,
    layout,
    aspect,
    mobileAspect,
    showPreviewImage = true,
    placeholder,
    tileSource,
    animateIn
  } = props
  const isInDialog = useContext(DialogContext)?.isInDialog
  const router = useRouter()
  const styles = useStyles()
  const topShotImageRef = useRef()
  const details = useProductVariantDetails({ product, variantId, colorVariantId })
  const {
    title,
    colorVariant,
    url,
    featureImage,
    category,
    colorsCount,
    sizesCount,
    productId,
    productEnquiry,
    productVariants
  } = details

  let upToPercentOff = false
  let percentOff = 0

  productVariants?.forEach(currVariant => {
    const currColorVariantId = first(currVariant?.option_values?.filter(currOption => currOption?.option_display_name === 'Colour' || currOption?.option_display_name === 'Color'))?.id

    if (currColorVariantId === colorVariant?.bigCommerceId && currVariant?.price?.as_entered > 0) {
      const { price: currPrice, salePrice: currSalePrice } = usePrice(productId, currVariant?.id)

      if (currSalePrice) {
        const currPercentOff = 100 - Math.floor((currSalePrice?.as_entered / currPrice?.as_entered * 100))

        // Display 'Up to' prefix on sale badge when there is a sale percentage range between variants
        upToPercentOff = percentOff > 0 && currPercentOff !== percentOff ? true : upToPercentOff
        // Set sale percentage to highest amount
        percentOff = currPercentOff > percentOff ? currPercentOff : percentOff
      } else {
        // Current variant not on sale - display 'Up to' prefix if other variants on sale
        upToPercentOff = true
      }
    }
  })

  const tileRef = animateIn ? useScrollTrigger(() => ({
    trigger: tileRef.current,
    start: () => 'top bottom',
    scrub: false
  }),
  (tl, ref) => {
    tl.from(ref.current, { y: 40, opacity: 0, delay: 0.2 * (tileIndex % 4), ease: 'expo.out', duration: 1.5 })
  }) : useRef()

  const resolvedImage = image && image.asset ? image : featureImage
  const topShotImage = colorVariant?.topShot
  const layoutAspect = layout === 'portrait' ? 563 / 752 : 566 / 424
  const desktopAspect = aspect || layoutAspect
  const ref = useHoverEvents(primaryInput !== 'touch', useCallback((hovering) => {
    if (topShotImageRef.current) {
      gsap.to(topShotImageRef.current, { opacity: hovering ? 1 : 0, duration: 0.15, ease: 'sine.inOut' })
    }
  }, []))

  const onLinkClick = useCallback((e) => {
    const datalayerObject = {
      id: colorVariantId,
      name: title,
      category: category?.name,
      variant: colorVariant?.label
    }
    trackProductClick(datalayerObject, tileSource, tileIndex)
    if (isInDialog) {
      e.preventDefault()
      e.stopPropagation()
      router.replace(url)
    }
  }, [isInDialog])

  if (placeholder) {
    return (
      <article className={className}>
        <div className={styles.link}>
          <Placeholder type='image' aspect={desktopAspect} mobileAspect={mobileAspect} />
          <PlaceholderProductMeta />
          <Placeholder type='text' multiline={2} />
        </div>
      </article>
    )
  }

  if (!product) return null

  return (
    <article className={className} ref={tileRef}>
      <Link to={url} className={styles.link} ref={ref} onClick={onLinkClick}>
        {resolvedImage && (
          <ResponsiveImage
            className={cn(styles.image, { showBackgroundColor: !showPreviewImage })}
            image={resolvedImage}
            aspect={desktopAspect}
            mobileAspect={mobileAspect}
            showPreview={showPreviewImage}
          >
            {topShotImage && (
              <ResponsiveImage
                className={cn(styles.topShotImage)}
                image={topShotImage}
                aspect={desktopAspect}
                ref={topShotImageRef}
                showPreview={showPreviewImage}
                background='transparent'
              />
            )}
          </ResponsiveImage>
        )}
        {!resolvedImage && <Placeholder type='image' aspect={desktopAspect} mobileAspect={mobileAspect} blink={false} />}
        {percentOff > 0 && (<span className={styles.saleBadge}>{upToPercentOff && 'Up to '}{percentOff}% Off</span>)}
        <ProductMeta
          className={styles.meta}
          product={product}
          productId={productId}
          variantId={variantId}
          category={category}
          colorsCount={colorsCount}
          sizesCount={sizesCount}
          productEnquiry={productEnquiry}
          colorVariantId={colorVariantId}
          productName={title}
          productColor={colorVariant?.label}
        />
        <span className={styles.title}>
          <span className={styles.titleText}>{title}</span>{colorVariant && <span className={styles.variant}>{colorVariant?.label}</span>}
        </span>
      </Link>
    </article>
  )
}

const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
    display: 'block'
  },
  image: {
    marginBottom: theme.spacingPx(1),
    '&.showBackgroundColor': {
      backgroundColor: theme.colors.imageBackground
    }
  },
  meta: {
    marginBottom: theme.spacingPx(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(2)
    }
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    fontFamily: theme.fonts.heading,
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '0.02em',
    fontSize: '16px',
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
      fontSize: '18px'
    }
  },
  titleText: {
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1)
    }
  },
  variant: {
    display: 'block',
    opacity: 0.5,
    [theme.breakpoints.up('md')]: {
      display: 'inline-block'
    }
  },
  topShotImage: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    backgroundColor: theme.colors.imageBackground
  },
  saleBadge: {
    position: 'absolute',
    left: 10,
    top: 10,
    padding: '0 8px',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '26px',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0.12em',
    color: '#566160',
    backgroundColor: '#fafcf8',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      top: 16,
      left: 16,
      lineHeight: '30px'
    }
  }
})

export default ProductTile
