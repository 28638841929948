import { useCallback, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'
import useSnapshot from '../store/useSnapshot'
import { getSiteData } from '../store/layoutSlice'
import InputContainer from './TradeRegistration/InputContainer'
import Button from './Button'
import CountrySelect from './TradeRegistration/CountrySelect'

const NewsletterForm = ({ joinLabel = 'Join', successMessage }) => {
  const styles = useStyles()
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      country: ''
    }
  })

  const snap = useSnapshot()
  const locale = getSiteData(snap).locale

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const onSubmit = useCallback(async (data) => {
    setLoading(true)
    const response = await window.fetch('/api/newsletter', {
      method: 'post',
      body: JSON.stringify({
        ...data,
        locale
      })
    })
    const mailchimpResponse = await response.json()
    setLoading(false)
    if (response.ok) {
      setSuccess(true)
    } else {
      setError(mailchimpResponse.error.detail || 'Oops, something went wrong')
    }
  }, [locale])

  return (
    <div className={styles.root}>
      <FormProvider {...methods}>
        {success && (
          <div className={styles.successMessageContainer}>
            <h2>{successMessage}</h2>
          </div>
        )}
        {!success && (
          <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.formContainer}>
            <InputContainer name='first_name' required placeholder='First Name' />
            <InputContainer name='last_name' required placeholder='Last Name' />
            <InputContainer name='email' type='email' required placeholder='Email' />
            <InputContainer name='country' required placeholder='Country' InputComponent={CountrySelect} />
            {error && <div className={styles.error}>{error}</div>}
            <Button size='lg' className={styles.signUpBtn} disabled={loading} type='submit'>{loading ? 'Sending...' : joinLabel}</Button>
          </form>
        )}
      </FormProvider>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    flexShrink: 1
  },
  successMessageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    flexShrink: 1
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 1,
    flexShrink: 1
  },
  error: {
    color: theme.colors.error,
    margin: '0 !important',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(2.5)}px`
  },
  signUpBtn: {
    width: '100%',
    marginBottom: theme.spacing(3)
  }
})

export default NewsletterForm
