import { createUseStyles } from '../../helpers/createStyles'
import { grid, theme } from '../../styles/theme'
import cn from 'classnames'

export default function DialogHeader ({ className, children }) {
  const styles = useStyles()
  return (
    <header className={cn(styles.header, className, 'dialog-header')}>
      {children}
    </header>
  )
}

const useStyles = createUseStyles({
  header: {
    gridColumn: '1 / -1',
    paddingTop: grid.sm.gutter,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    fontFamily: theme.fonts.heading,
    [theme.breakpoints.up('md')]: {
      paddingTop: 60,
      marginBottom: theme.spacing(2)
    }
  }
})
