import cn from 'classnames'
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useRouter } from 'next/router'
import get from 'lodash/get'
import PageScrollContainer from '../PageScrollContainer'
import useScrollRestoration from '../SmoothScrollbar/useScrollRestoration'
import Dialog from './Dialog'
import { span, theme } from '../../styles/theme'
import { createUseStyles } from '../../helpers/createStyles'
import useSnapshot from '../../store/useSnapshot'
import { isCartOpen, isSamplesDialogOpen, getPageData, setDialogData } from '../../store/layoutSlice'
import delay from 'lodash/delay'
import { resolveInternalLinkUrl } from '../../lib/resolveLink'

export const DialogContext = React.createContext({})

function DialogProvider (props) {
  const contextValue = useMemo(() => ({ isInDialog: true }), [])
  return <DialogContext.Provider {...props} value={contextValue} />
}

export default function PageDialog ({ page, dialog }) {
  const [dialogState, setDialogState] = useState({ dialog, theme })
  const [dialogAnimationTimeout, setDialogAnimationTimeout] = useState()
  const scrollRef = useRef()
  const styles = useStyles()
  const snap = useSnapshot()
  // Avoid showing 2 sets of back arrows when something open above
  const overlayAbove = isCartOpen(snap) || isSamplesDialogOpen(snap)
  const [forceCloseDialog, setForceCloseDialog] = useState()
  const pageData = getPageData(snap)
  const open = !!dialog

  useEffect(() => {
    const colorTheme = theme.colors.pageTheme[get(dialog, 'page.color.title')]
    const size = get(dialog, 'page.dialogSize', 'lg')

    if (dialogAnimationTimeout) clearTimeout(dialogAnimationTimeout)
    setForceCloseDialog(false)

    // If opening a new dialog when dialog is already open, then we want to close it first before we open it with the new dialog state
    if (dialogState?.dialog && dialog) {
      setForceCloseDialog(true)
      setDialogAnimationTimeout(delay(() => {
        setDialogState({ dialog, theme: colorTheme, size })
        setForceCloseDialog(false)
      }, 800))
      return
    }

    setDialogState({ dialog, theme: colorTheme, size })
  }, [dialog])

  const colorTheme = get(dialogState, 'theme')

  const router = useRouter()

  const onClose = useCallback(() => {
    const url = resolveInternalLinkUrl(pageData?.page)
    router.push(url, null, { shallow: false })
    setForceCloseDialog(true)
    setDialogAnimationTimeout(delay(() => {
      setForceCloseDialog(false)
      setDialogData(null)
    }, 800))
  }, [page])

  useScrollRestoration(dialog, scrollRef)

  return (
    <DialogProvider>
      <Dialog
        open={open && !forceCloseDialog}
        onClose={onClose}
        className={cn('dialog-grid', styles.dialog, get(dialogState, 'size'))}
        colorTheme={colorTheme}
        showArrow={!overlayAbove}
      >
        <PageScrollContainer
          scrollRef={scrollRef}
          data={get(dialogState, 'dialog')}
          footer={false}
          tag='div'
          showMeta={!!get(dialogState, 'dialog')}
          isDialog
        />
      </Dialog>
    </DialogProvider>
  )
}

const useStyles = createUseStyles({
  dialog: {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: span(1, 'md')
    },
    '&.xxs': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(8, 'md')
      }
    },
    '&.xs': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(7, 'md')
      }
    },
    '&.sm': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(3, 'md')
      }
    },
    '&.md': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(2, 'md')
      }
    }
  }
})
