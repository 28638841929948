import { useState, useEffect, useCallback, useRef } from 'react'
import cn from 'classnames'
import StyledLink from '../StyledLink'
import { createUseStyles } from '../../helpers/createStyles'
import * as gtag from '../../lib/gtag'

const SHARE_LABEL = 'Share'

const ShareLink = ({ className, text = SHARE_LABEL }) => {
  const classes = useStyles()
  const [shareText, setShareText] = useState(text)
  const shareTextInputRef = useRef()

  const onShareClick = useCallback(() => {
    /* Select the text field */
    shareTextInputRef.current.value = window.location.href
    shareTextInputRef.current.select()
    shareTextInputRef.current.setSelectionRange(0, 99999)
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(shareTextInputRef.current.value)
    setShareText('Copied to clipboard')
    gtag.event({
      action: 'share',
      label: 'Shared',
      value: window.location.href
    })
  }, [])

  useEffect(() => {
    if (shareText !== SHARE_LABEL) {
      setTimeout(() => { setShareText(SHARE_LABEL) }, 5000)
    }
  }, [shareText])

  return (
    <StyledLink className={cn(className, 'styled-link')} onClick={onShareClick}>
      {shareText}
      <input type='text' ref={shareTextInputRef} className={classes.shareInput} />
    </StyledLink>
  )
}

const useStyles = createUseStyles({
  shareInput: {
    display: 'none'
  }
})

export default ShareLink
