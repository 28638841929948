import { useRef, useCallback, useState, useEffect } from 'react'
import { useScrollListener } from './SmoothScrollbar/useSmoothScrollbar'
import { createUseStyles } from '../helpers/createStyles'
import cn from 'classnames'
import { primaryInput } from 'detect-it'
import useSnapshot from '../store/useSnapshot'
import { isChatBotOpen, setChatBotOpen, isShopFiltersDialogOpen } from '../store/layoutSlice'
import CrossIcon from '../icons/cross.svg'
import { isGorgiasChatBotReady } from './GorgiasChatBot'
import { theme } from '../styles/theme'
import { expoOut } from '../styles/easing'

const ChatIcon = () => {
  return (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12.5 8C12.5 8.39782 12.3595 8.77936 12.1095 9.06066C11.8594 9.34196 11.5203 9.5 11.1667 9.5H5.412C3.98381 9.5 2.62379 10.1107 1.67495 11.1782L0.5 12.5V2C0.5 1.60218 0.640476 1.22064 0.890524 0.93934C1.14057 0.658036 1.47971 0.5 1.83333 0.5H11.1667C11.5203 0.5 11.8594 0.658036 12.1095 0.93934C12.3595 1.22064 12.5 1.60218 12.5 2V8Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

const ChatButton = ({ isDialog, pageThemeColor }) => {
  const styles = useStyles()
  const ref = useRef(null)
  const [hidden, setHidden] = useState(true)
  const snap = useSnapshot()
  const open = isChatBotOpen(snap)
  const filterOpen = isShopFiltersDialogOpen(snap)

  const isMobile = primaryInput === 'touch'

  useScrollListener(useCallback(({ y }) => {
    if (!isGorgiasChatBotReady()) {
      setHidden(true)
      return
    }

    if (!isMobile && ref.current) {
      ref.current.style.transform = `translate3d(calc(-100% - 24px), ${y - 53 - 22}px ,0)`
    }

    const offset = isMobile ? 100 : 200
    if (y > offset) {
      setHidden(false)
    } else {
      if (!open) {
        setHidden(true)
      }
    }
  }), [])

  const toggleOpen = () => {
    setChatBotOpen(!open)
  }

  useEffect(() => {
    if (filterOpen) {
      setChatBotOpen(false)
    }
  }, [filterOpen])

  useEffect(() => {
    if (!isGorgiasChatBotReady()) return
    if (open) {
      window.GorgiasChat.open()
    } else {
      window.GorgiasChat.close()
    }
  }, [open])

  const isBgDark = (pageThemeColor === theme.colors.pageTheme.moss || pageThemeColor === theme.colors.pageTheme.mushroom || pageThemeColor === theme.colors.pageTheme.gunsmoke)

  return (
    <button className={cn(styles.button, hidden && styles.hidden, isDialog && styles.isDialog, isMobile && styles.isMobile, isBgDark && styles.isDark)} ref={ref} onClick={toggleOpen}>
      {!open ? <ChatIcon /> : <CrossIcon />}
      <span className={cn(styles.copy, 'copy')} aria-hidden>
          <span className={styles.copyText}>{!open ? <ChatIcon /> : <CrossIcon />}</span>
        </span>
    </button>
  )
}

const useStyles = createUseStyles({
  button: {
    position: 'fixed',
    opacity: 1,
    top: '100vh',
    left: '100vw',
    border: `1px solid ${theme.colors.border}`,
    zIndex: theme.zIndex.chatButton,
    width: 53,
    height: 53,
    background: 'var(--background)',
    transition: 'opacity 0.3s ease-out, margin-top 0.3s ease-out',
    transform: 'translate(calc(-100% - 0px), calc(-100% - 0px))',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      boxShadow: '0px 7px 15px rgba(0, 0, 0, 0.15)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      transition: 'opacity 0.3s ease-out'
    },
    '&:hover:after': {
      opacity: 1
    },
    '& svg': {
      width: 13,
      height: 13
    },
    '&:hover:not(:disabled), &.active': {
      borderColor: theme.colors.button.backgroundHover,
      '& .copy': {
        transform: 'translate(0, 0%)',
        '& > span': {
          transform: 'translate(0, 0%)',
          opacity: 1
        }
      }
    }
  },
  isMobile: {
    top: 'unset',
    bottom: 0,
    marginTop: 0,
    transform: 'translate(calc(-100% - 0px), -24px)'
  },
  hidden: {
    pointerEvents: 'none',
    opacity: '0 !important',
    marginTop: 20
  },
  isDialog: {
    marginLeft: 'calc((((100vw - 264px - 0px) / 12) * -1) + 0px)'
  },
  copy: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.button.backgroundHover,
    color: theme.colors.button.foregroundHover,
    overflow: 'hidden',
    transform: 'translate(0, 100%)',
    transition: `transform 0.5s ${expoOut}`
  },
  copyText: {
    padding: `0 ${theme.spacingPx(3)}`,
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
    transform: 'translate(0, -100%)',
    opacity: 0,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    transition: `transform 0.5s ${expoOut}`,
    '.md > span > &, .md-lg > span > &, .lg > span > &': {
      [theme.breakpoints.up('md')]: {
        padding: `0 ${theme.spacingPx(4)}`
      }
    }
  },
  isDark: {
    borderColor: 'rgba(0, 0, 0, 0.25) !important'
  }
})

export default ChatButton
