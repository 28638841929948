import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import cn from 'classnames'

import Link from '../components/Link'
import StyledLink from '../components/StyledLink'
import Button from '../components/Button'
import { span, theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'

export const createSerializers = (options = {}) => {
  return {
    types: {
      block: (props) => {
        const { style = 'normal' } = props.node
        const styles = useStyles()

        if (style === 'h1') {
          return (
            <h1>{props.children}</h1>
          )
        }

        if (style === 'h1mock') {
          return (
            <p className='is-h1'>
              {props.children}
            </p>
          )
        }

        if (style === 'h2mock') {
          return (
            <p className='is-h2'>
              {props.children}
            </p>
          )
        }

        if (style === 'h3mock') {
          return (
            <p className='is-h3'>
              {props.children}
            </p>
          )
        }

        if (style === 'h4mock') {
          return (
            <p className='is-h4'>
              {props.children}
            </p>
          )
        }

        if (style === 'indent') {
          return (
            <p className={cn(styles.indent, 'is-indent')}>
              {props.children}
            </p>
          )
        }

        if (style === 'caption') {
          return (
            <p className={cn(styles.caption, 'is-caption')}>
              {props.children}
            </p>
          )
        }

        // handle all other blocks with the default serializer
        return BlockContent.defaultSerializers.types.block(props)
      },
      cta: ({ node }) => {
        const styles = useStyles()
        const { buttonCtas } = options
        return buttonCtas
          ? (
            <Button link={node} className={styles.button} />
            )
          : (
            <StyledLink link={node} />
            )
      },
      quote: ({ node }) => {
        const { quote, attribution } = node
        const styles = useStyles()
        return (
          <>
            <blockquote className={styles.blockquote}>{quote}</blockquote>
            {attribution && <span className={styles.attribution}>- {attribution}</span>}
          </>
        )
      },
      break: props => {
        const { style } = props.node
        if (style === 'horizontalRule') {
          return <hr />
        }
        return null
      }
    },
    marks: {
      link: ({ mark, children }) => {
        const { styledLinks, showExternalIcon } = options
        return <Link link={{ ...mark }} showExternalIcon={showExternalIcon} showText={false} className={cn(styledLinks && 'styled-link styled-link-underlined')}>{children}</Link>
      }
    }
  }
}

const useStyles = createUseStyles({
  blockquote: {
    marginBottom: theme.spacingPx(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(4)
    }
  },
  indent: {
    fontSize: '18px',
    textIndent: '86px',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      textIndent: '24.55vw',
      '.dialog-grid &': {
        textIndent: '16.55vw'
      }
    }
  },
  caption: {
    opacity: 0.5,
    marginBottom: theme.spacingPx(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(4)
    }
  },
  attribution: {
    display: 'block',
    marginTop: theme.spacingPx(-3),
    marginBottom: theme.spacingPx(4),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacingPx(-5)
    }
  },
  button: {
    minWidth: span(3, 'sm'),
    marginBottom: theme.spacingPx(4),
    [theme.breakpoints.up('md')]: {
      minWidth: span(3, 'md')
    }
  }
})
