import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import cn from 'classnames'
import Section from '../../Section'
import useSnapshot from '../../../store/useSnapshot'
import { loadNextPage } from '../../../store/productListing'
import { getSiteData, getPageData, openShopFiltersDialog } from '../../../store/layoutSlice'
import SampleTile from './SampleTile'
import { createUseStyles } from '../../../helpers/createStyles'
import { grid, span, theme } from '../../../styles/theme'
import useProductVariants from '../ProductListing/useProductVariants'
import useProductVariantFilter from '../ProductListing/useProductVariantFilter'
import NavigationTabs from '../NavigationTabs'
import delay from 'lodash/delay'
import { getProductsLoaded } from '../../../store/productsSlice'
import range from 'lodash/range'
import useScrollTrigger from '../../../hooks/useScrollTrigger'
import FloatingFilterButton from '../ProductListing/FloatingFilterButton'
import { setShopFiltersCategory } from '../../../store/shopFiltersSlice'
import useProductsWithoutDonations from '../ProductListing/useProductsWithoutDonations'
import { getCurrencyCode } from '../../../store/pricesSlice'
import { trackProductImpression } from '../../../lib/gtag'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import FilterButton from '../ProductListing/FilterButton'
import gsap from 'gsap'
import useIsMobile from '../../../hooks/useIsMobile'
import RichContent from '../../RichContent'

export default function SampleListing ({ data, summary }) {
  const { title, introduction, category, instructionHeading, instructionSteps } = data
  const styles = useStyles()
  const localsRef = useRef({ })
  const loadMoreRef = useRef()
  const instructionContainerRef = useRef()
  const snap = useSnapshot()
  const { page: { slug: categorySlug } } = getPageData(snap)
  const { shopFilterLabel = 'Filter & Sort', addToCartLabel } = getSiteData(snap)
  const currencyCode = getCurrencyCode(snap)
  const products = useProductsWithoutDonations(snap)
  const productsLoaded = getProductsLoaded(snap)
  const variants = useProductVariants(products)
  const variantsInSlice = useProductVariants(data.products)
  const isMobile = useIsMobile()

  const [paginatedFilteredSampleVariants, allFilteredSampleVariants] = useProductVariantFilter(variants, category, false, false, false, false, true)

  const sampleVariants = useMemo(() => {
    if (productsLoaded) return paginatedFilteredSampleVariants

    // If products not loaded, render placeholders
    return [...variantsInSlice.slice(0, 3), ...range(11).map(() => ({ placeholder: true }))]
  }, [paginatedFilteredSampleVariants, productsLoaded])

  const introRef = useScrollTrigger(
    () => ({
      trigger: introRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.querySelectorAll(`.${styles.container}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults })
    }
  )

  useEffect(() => {
    setShopFiltersCategory(category)
  }, [category])

  const listSource = useMemo(() => ({
    name: category?.name,
    id: categorySlug
  }), [categorySlug, category])

  const trackImpressions = useCallback((products) => {
    const datalayerObjects = products?.map(({ product, variantId }) => {
      return {
        id: variantId,
        name: product.title,
        category: product.primaryCategory?.name,
        variant: product.colors?.find((color) => color.bigCommerceId === variantId)?.label
      }
    })
    trackProductImpression(datalayerObjects, listSource, currencyCode)
  }, [currencyCode])

  useEffect(() => {
    if (allFilteredSampleVariants.length > 0 && !summary) {
      trackImpressions(allFilteredSampleVariants)
    }
  }, [allFilteredSampleVariants, summary])

  useEffect(() => {
    if (loadMoreRef.current) {
      const handler = (entries) => {
        const intersecting = entries[0]?.isIntersecting
        if (intersecting && !localsRef.current.delay) {
          loadNextPage(allFilteredSampleVariants.length, paginatedFilteredSampleVariants.length)
          localsRef.current.delay = true
          delay(() => { localsRef.current.delay = false }, 500)
        }
      }

      const observer = new window.IntersectionObserver(handler, { triggerOnce: false })
      observer.observe(loadMoreRef.current)
      return () => {
        observer.disconnect()
      }
    }
  }, [productsLoaded, sampleVariants, loadMoreRef])

  // Refresh scrollTrigger positions to ensure productItem tiles animate in at the correct time
  useEffect(() => { ScrollTrigger.refresh() }, [sampleVariants])

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.out',
        duration: 0.8
      }
      tl.set(ref.current, { opacity: 1 })
      tl.from(ref.current.querySelectorAll(`.${styles.headerCopy}, .${styles.copy}, .${styles.tile}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults }, '+=0.25')
    }
  )

  useEffect(() => {
    if (instructionContainerRef.current) {
      gsap.fromTo([instructionContainerRef.current.children[0], instructionContainerRef.current.children[1].children],
        { opacity: 0, y: '40' },
        { opacity: 1, y: 0, stagger: 0.2, duration: 0.8 }
      )
    }
  }, [instructionContainerRef])

  return (
    <section>
      <NavigationTabs title={title} summary={summary} hideFilterPills>
        {!isMobile && <FilterButton onClick={openShopFiltersDialog} label={shopFilterLabel} containerRef={sectionRef} size='md' hideViewToggle />}
      </NavigationTabs>
      <FloatingFilterButton onClick={openShopFiltersDialog} label={shopFilterLabel} containerRef={sectionRef} hideViewToggle/>
      <Section tag='div' ref={sectionRef} className={cn(styles.section)}>
        <div className={styles.introSection} ref={introRef}>
          <RichContent content={introduction} className={cn(styles.container)} />
        </div>
        <div className={styles.instructionSection} ref={instructionContainerRef}>
          <div className={cn('is-caption', styles.instructionSectionHeading)}>{instructionHeading}</div>
          <div className={styles.instructionSteps}>
            { instructionSteps.map((step, index) => {
              return (
                <div className={styles.instructionStep} key={index}>
                  <span>{index + 1}</span>
                  <div className={styles.instructionCopy}>{step}</div>
                </div>
              )
            })}
          </div>
        </div>
        {isMobile && <FilterButton onClick={openShopFiltersDialog} label={shopFilterLabel} containerRef={sectionRef} size='md' hideViewToggle={true}/>}

        <div className={cn(styles.grid, styles.sectionEndMargin)}>
          {sampleVariants?.map(({ product, variantId: colorVariantId, placeholder }, i) => (
            <div className={styles.tile} key={colorVariantId || i}>
              <SampleTile
                tileIndex={i}
                layout='portrait'
                product={product}
                colorVariantId={colorVariantId}
                placeholder={placeholder}
                mobileAspect={null}
                animateIn
                addToCartLabel={addToCartLabel}
                currencyCode={currencyCode}
              />
            </div>
          ))}
        </div>
      </Section>
      {allFilteredSampleVariants.length > paginatedFilteredSampleVariants.length && productsLoaded && <div ref={loadMoreRef} />}
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
  },
  section: {
    opacity: 0
  },
  sectionEndMargin: {
    [theme.breakpoints.between('sm', 'xl')]: {
      paddingBottom: 40
    }
  },
  toolbar: {
    marginBottom: theme.spacingPx(4),
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(18),
      display: 'flex'
    }
  },
  tile: {
    zIndex: 4
  },
  filterButton: {
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      minWidth: 330,
      width: 'auto'
    }
  },
  title: {
    marginBottom: theme.spacingPx(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  headerCopy: {
    marginBottom: theme.spacing(4),
    '& p': {
      fontSize: 16
    },
    [theme.breakpoints.up('md')]: {
      textIndent: '25.55vw',
      marginLeft: span(1, 'md'),
      width: span(8, 'md'),
      marginBottom: theme.spacing(18),
      '& p': {
        fontSize: 24
      }
    }
  },
  introSection: {
    marginBottom: theme.spacing(4),
    '& p': {
      fontSize: 16
    },
    [theme.breakpoints.up('md')]: {
      textIndent: '25.55vw',
      marginLeft: span(1, 'md'),
      width: span(8, 'md'),
      marginBottom: theme.spacing(18),
      '& p': {
        fontSize: 24
      }
    }
  },
  copy: {
    padding: theme.spacingPx(3),
    gridColumn: '1 / -1',
    textAlign: 'center',
    margin: 'auto',
    display: 'none',
    maxWidth: 283,
    '&:nth-child(1)': {
      display: 'block',
      gridRow: 3,
      [theme.breakpoints.up('md')]: {
        gridRow: 1
      }
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      gridColumn: 'span 3',
      padding: `0 ${theme.spacingPx(6)} 0 0`,
      textAlign: 'left',
      margin: '0'
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(${grid.sm.columns}, minmax(0px, 1fr))`,
    rowGap: theme.spacingPx(4),
    columnGap: `${grid.sm.gutter}px`,
    marginTop: theme.spacingPx(7),
    '& > *': {
      gridColumn: 'span 4'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      rowGap: theme.spacingPx(10),
      columnGap: `${grid.md.gutter}px`,
      gridTemplateColumns: `repeat(${grid.md.columns}, minmax(0px, 1fr))`,
      '& > *': {
        gridColumn: 'span 3'
      }
    }
  },
  dot: {
    display: 'inline-block',
    height: 6,
    width: 6,
    background: 'currentColor',
    borderRadius: '50%',
    marginLeft: 8
  },
  instructionSection: {
    width: '100%',
    marginBottom: theme.spacingPx(9),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(6)
    }
  },
  instructionSectionHeading: {
    paddingBottom: theme.spacingPx(2)
  },
  instructionSteps: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${grid.sm.columns}, minmax(0px, 1fr))`,
    rowGap: theme.spacingPx(4),
    columnGap: `${grid.sm.gutter}px`,
    [theme.breakpoints.up('md')]: {
      rowGap: theme.spacingPx(10),
      columnGap: `${grid.md.gutter}px`,
      gridTemplateColumns: `repeat(${grid.md.columns}, minmax(0px, 1fr))`
    }
  },
  instructionStep: {
    gridColumn: '1 / -1',
    borderTop: '0.5px solid #566160',
    paddingTop: theme.spacingPx(1),
    display: 'flex',
    '& span': {
      marginRight: theme.spacingPx(4)
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 3'
    }
  },
  instructionCopy: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 300
    }
  }
})
