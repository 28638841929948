import React from 'react'
import cn from 'classnames'
import Section from '../../Section'
import { createUseStyles } from '../../../helpers/createStyles'
import { grid, span, theme } from '../../../styles/theme'
import useScrollTrigger from '../../../hooks/useScrollTrigger'
import CustomColorTile from './CustomColorTile'

export default function CustomColorListing ({ data, summary }) {
  const { product } = data
  const customColors = product?.customColors
  const styles = useStyles()

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.out',
        duration: 0.8
      }
      tl.set(ref.current, { opacity: 1 })
      tl.from(ref.current.querySelectorAll(`.${styles.headerCopy}, .${styles.copy}, .${styles.tile}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults }, '+=0.25')
    }
  )

  return (
    <section>
      <Section tag='div' ref={sectionRef} className={cn(styles.section)}>
        <div className={cn(styles.grid, styles.sectionEndMargin)}>
          {customColors?.map(({ label, topShot, featureImage, landingHoverImage, colorSwatch }, i) => (
            <div className={styles.tile} key={i}>
              <CustomColorTile product={product} label={label} landingHoverImage={landingHoverImage} topShot={topShot} featureImage={featureImage} colorSwatch={colorSwatch} />
            </div>
          ))}
        </div>
      </Section>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
  },
  section: {
    opacity: 0
  },
  sectionEndMargin: {
    [theme.breakpoints.between('sm', 'xl')]: {
      paddingBottom: 40
    }
  },
  toolbar: {
    marginBottom: theme.spacingPx(4),
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(18),
      display: 'flex'
    }
  },
  tile: {
    zIndex: 4
  },
  filterButton: {
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      minWidth: 330,
      width: 'auto'
    }
  },
  title: {
    marginBottom: theme.spacingPx(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  headerCopy: {
    marginBottom: theme.spacing(4),
    '& p': {
      fontSize: 16
    },
    [theme.breakpoints.up('md')]: {
      textIndent: '25.55vw',
      marginLeft: span(1, 'md'),
      width: span(8, 'md'),
      marginBottom: theme.spacing(18),
      '& p': {
        fontSize: 24
      }
    }
  },
  introSection: {
    marginBottom: theme.spacing(4),
    '& p': {
      fontSize: 16
    },
    [theme.breakpoints.up('md')]: {
      textIndent: '25.55vw',
      marginLeft: span(1, 'md'),
      width: span(8, 'md'),
      marginBottom: theme.spacing(18),
      '& p': {
        fontSize: 24
      }
    }
  },
  copy: {
    padding: theme.spacingPx(3),
    gridColumn: '1 / -1',
    textAlign: 'center',
    margin: 'auto',
    display: 'none',
    maxWidth: 283,
    '&:nth-child(1)': {
      display: 'block',
      gridRow: 3,
      [theme.breakpoints.up('md')]: {
        gridRow: 1
      }
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      gridColumn: 'span 3',
      padding: `0 ${theme.spacingPx(6)} 0 0`,
      textAlign: 'left',
      margin: '0'
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(${grid.sm.columns}, minmax(0px, 1fr))`,
    rowGap: theme.spacingPx(4),
    columnGap: `${grid.sm.gutter}px`,
    marginTop: theme.spacingPx(7),
    '& > *': {
      gridColumn: 'span 4'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      rowGap: theme.spacingPx(10),
      columnGap: `${grid.md.gutter}px`,
      gridTemplateColumns: `repeat(${grid.md.columns}, minmax(0px, 1fr))`,
      '& > *': {
        gridColumn: 'span 3'
      }
    }
  },
  dot: {
    display: 'inline-block',
    height: 6,
    width: 6,
    background: 'currentColor',
    borderRadius: '50%',
    marginLeft: 8
  },
  instructionSection: {
    width: '100%',
    marginBottom: theme.spacingPx(9),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(6)
    }
  },
  instructionSectionHeading: {
    paddingBottom: theme.spacingPx(2)
  },
  instructionSteps: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${grid.sm.columns}, minmax(0px, 1fr))`,
    rowGap: theme.spacingPx(4),
    columnGap: `${grid.sm.gutter}px`,
    [theme.breakpoints.up('md')]: {
      rowGap: theme.spacingPx(10),
      columnGap: `${grid.md.gutter}px`,
      gridTemplateColumns: `repeat(${grid.md.columns}, minmax(0px, 1fr))`
    }
  },
  instructionStep: {
    gridColumn: '1 / -1',
    borderTop: '0.5px solid #566160',
    paddingTop: theme.spacingPx(1),
    display: 'flex',
    '& span': {
      marginRight: theme.spacingPx(4)
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 3'
    }
  },
  instructionCopy: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 300
    }
  }
})
