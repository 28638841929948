import { useCallback, useEffect, useRef } from 'react'
import cn from 'classnames'
import { isMenuOpen, preloadMenu, toggleMenu } from '../../store/layoutSlice'
import useSnapshot from '../../store/useSnapshot'
import { theme } from '../../styles/theme'
import gsap from 'gsap'
import { createUseStyles } from '../../helpers/createStyles'

const Hamburger = ({ className }) => {
  const snap = useSnapshot()
  const open = isMenuOpen(snap)
  const ref = useRef()
  const styles = useStyles()

  const handleToggleMenu = useCallback(() => {
    toggleMenu()
  }, [])

  const onMouseEnter = useCallback(() => {
    preloadMenu()
  }, [])

  useEffect(() => {
    if (ref.current) {
      const line1 = ref.current.children[0]
      const line2 = ref.current.children[1]
      gsap.to(line1, { y: open ? 4 : 0, rotate: open ? 45 : 0, duration: 0.8, ease: 'power3.out' })
      gsap.to(line2, { y: open ? -4 : 0, rotate: open ? -45 : 0, duration: 0.8, ease: 'power3.out' })
    }
  }, [open])

  return (
    <button
      ref={ref}
      className={cn(className || null, styles.hamburger)}
      onClick={handleToggleMenu}
      onMouseEnter={onMouseEnter}
      aria-label='Toggle Menu'
    >
      <span className={styles.line1} />
      <span className={styles.line2} />
    </button>
  )
}

const useStyles = createUseStyles({
  hamburger: {
    zIndex: 10, // This needs to sit in-front of the mobile menu
    pointerEvents: 'all',
    height: 42,
    width: 20,
    fontSize: 20,
    background: 'none',
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    padding: 0,
    margin: 0,
    position: 'relative',
    cursor: 'pointer',
    flexShrink: 0,
    flexGrow: 0,
    [theme.breakpoints.up('md')]: {
      width: 42,
      fontSize: 24
    }
  },
  line1: {
    pointerEvents: 'none',
    position: 'absolute',
    display: 'block',
    right: 0,
    height: '1px',
    top: 'calc(50% - 4px)',
    width: '1em',
    backgroundColor: theme.colors.text
  },
  line2: {
    pointerEvents: 'none',
    position: 'absolute',
    display: 'block',
    right: 0,
    height: '1px',
    top: 'calc(50% + 4px)',
    width: '1em',
    backgroundColor: theme.colors.text
  }
})

export default Hamburger
