import { useEffect, useRef, useState } from 'react'

export default function useHover (enabled) {
  const [value, setValue] = useState(false)
  const ref = useRef(null)
  useEffect(() => {
    if (enabled) {
      const handleMouseEnter = () => setValue(true)
      const handleMouseLeave = () => setValue(false)
      const node = ref.current
      if (node) {
        node.addEventListener('mouseenter', handleMouseEnter, { passive: true })
        node.addEventListener('mouseleave', handleMouseLeave, { passive: true })
        return () => {
          node.removeEventListener('mouseenter', handleMouseEnter)
          node.removeEventListener('mouseleave', handleMouseLeave)
        }
      }
    } else {
      setValue(false)
    }
  }, [ref.current, enabled])
  return [ref, value]
}

export function useHoverEvents (enabled, onHoverCallback, ref) {
  if (!ref) ref = useRef(null)
  useEffect(() => {
    if (enabled) {
      const onEnter = () => {
        onHoverCallback(true)
      }
      const onLeave = () => {
        onHoverCallback(false)
      }
      const node = ref.current
      if (node) {
        node.addEventListener('mouseenter', onEnter, { passive: true })
        node.addEventListener('mouseleave', onLeave, { passive: true })
        return () => {
          node.removeEventListener('mouseenter', onEnter)
          node.removeEventListener('mouseleave', onLeave)
        }
      }
    }
  }, [ref.current, enabled, onHoverCallback])
  return ref
}
