import { theme } from '../../styles/theme'
import Overlay from './Overlay'
import { useCallback, useEffect, useRef } from 'react'
import gsap from 'gsap'
import cn from 'classnames'
import { createUseStyles } from '../../helpers/createStyles'
import { isMobile } from '../../lib/helpers'
import { isCurrentBreakpointMobile } from '../../store/layoutSlice'
import useSnapshot from '../../store/useSnapshot'
import UpArrow from './up.svg'
import LeftArrow from './left.svg'
import useBodyScrollLock from '../../hooks/useBodyScrollLock'
import useOnOutsideClick from '../../hooks/useOnOutsideClick'
import { primaryInput } from 'detect-it'
import CustomEase from 'gsap/dist/CustomEase'

gsap.registerPlugin(CustomEase)

export default function Dialog ({
  children, className, open, onClose,
  showArrow = true, colorTheme = theme.colors.pageTheme.default,
  onAnimationComplete, hideOverlayAtDesktop, footer,
  zIndex
}) {
  const snap = useSnapshot()
  const styles = useStyles()
  const dialogRef = useRef()
  const mobile = isCurrentBreakpointMobile(snap)
  const localsRef = useRef({})
  const innerRef = useRef()

  const scrollLockRef = useBodyScrollLock(open && primaryInput === 'touch')

  useEffect(() => {
    const tl = gsap.timeline()
    const axis = isMobile() ? 'y' : 'x'
    if (dialogRef.current) {
      // Initialise dialog styles
      if (!localsRef.current.open) {
        tl.set(dialogRef.current, {
          [axis]: '110%'
        })
        tl.set(dialogRef.current.querySelectorAll(`.${styles.up}, .${styles.left}`), {
          [axis]: '25px',
          opacity: 0
        })

        if (innerRef?.current) {
          tl.set(innerRef.current, {
            opacity: 0
          })
        }
      }

      localsRef.current.open = open
      tl.to(dialogRef.current, {
        [axis]: open ? 0 : '110%',
        duration: 1,
        ease: CustomEase.create('dialog', '0.23, 0.00, 0.00, 1.00'),
        pointerEvents: open ? 'all' : 'none',
        onComplete: () => { if (onAnimationComplete) onAnimationComplete(open) }
      })
      tl.to(dialogRef.current.querySelectorAll(`.${styles.up}, .${styles.left}`), {
        [axis]: open ? 0 : '25px',
        opacity: open ? 1 : 0,
        duration: 0.4,
        ease: CustomEase.create('dialog', '0.23, 0.00, 0.00, 1.00')
      }, open ? '-=0.4' : '-=1')

      if (innerRef?.current) {
        tl.to(innerRef.current, {
          opacity: open ? 1 : 0,
          delay: 0.2,
          duration: 1,
          ease: 'expo.out'
        }, 0)
      }

      return () => {
        tl.kill()
      }
    }
  }, [open])

  // This just puts the dialog in the correct state when the screen resizes
  useEffect(() => {
    if (dialogRef.current) {
      gsap.set(dialogRef.current, { clearProps: 'x,y' })
      if (localsRef.current.open) {
        gsap.set(dialogRef.current, { [mobile ? 'y' : 'x']: '0%' })
      }
    }
  }, [mobile])

  useOnOutsideClick(dialogRef, useCallback(() => {
    onClose()
  }, []), open && hideOverlayAtDesktop)

  return (
    <>
      <Overlay
        show={open}
        onClick={onClose}
        hideAtDesktop={hideOverlayAtDesktop}
        zIndex={zIndex}
      />
      <div
        ref={dialogRef}
        className={cn(styles.dialog, className)}
        style={{
          '--background': colorTheme.background,
          '--transparentBackground': colorTheme.transparentBackground,
          '--foreground': colorTheme.foreground,
          '--border': colorTheme.border,
          zIndex
        }}
      >
        {showArrow && !(!mobile && hideOverlayAtDesktop) && <UpArrow className={cn(styles.up, 'arrow')} />}
        {showArrow && !(!mobile && hideOverlayAtDesktop) && <LeftArrow className={cn(styles.left, 'arrow')} />}
        <div
          className={styles.inner} ref={(ref) => {
            scrollLockRef(ref)
            innerRef.current = ref
          }}
        >
          {children}
        </div>
        {footer}
      </div>
    </>
  )
}

export const useStyles = createUseStyles({
  dialog: {
    backgroundColor: theme.colors.background,
    color: theme.colors.text,
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    overflow: 'visible',
    zIndex: theme.zIndex.dialog,
    pointerEvents: 'none',
    transform: 'translate(0, 110%)',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(110%, 0)',
      height: '100vh'
    }
  },
  inner: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    overscrollBehavior: 'contain',
    opacity: 0
  },
  up: {
    pointerEvents: 'none',
    zIndex: theme.zIndex.dialog,
    position: 'absolute',
    top: -theme.spacing(6),
    left: '50%',
    transform: 'translate(-50%, 0)',
    color: theme.colors.pageTheme.default.background,
    display: 'block',
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  left: {
    pointerEvents: 'none',
    zIndex: theme.zIndex.dialog,
    position: 'absolute',
    left: `calc(-2.5vw - ${theme.gutter.sm}px)`,
    top: '50%',
    transform: 'translate(0%, -50%)',
    color: theme.colors.pageTheme.default.background,
    display: 'none',
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      left: `calc(-2.5vw - ${theme.gutter.md}px)`,
      display: 'block'
    }
  }
})
