
import { createGlobalStyles } from 'goober/global'
import { theme } from '../styles/theme'

const GlobalStyles = createGlobalStyles({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
  },
  html: {
  },
  ':root': {
    '--background': theme.colors.pageTheme.default.background,
    '--transparentBackground': theme.colors.pageTheme.default.transparentBackground,
    '--foreground': theme.colors.pageTheme.default.foreground,
    '--border': theme.colors.pageTheme.default.border,
    minWidth: '100%'
  },
  body: {
    padding: 0,
    margin: 0,
    backgroundColor: theme.colors.background,
    color: theme.colors.text,
    borderColor: theme.colors.border,
    width: '100%',
    '-webkitFontSmoothing': 'antialiased',
    '-mozOsxFontSmoothing': 'grayscale',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    fontSize: '14px',
    lineHeight: 1.5,
    letterSpacing: '0.02em',
    height: '100vh',
    overflowX: 'hidden'
  },
  'h1,h2,h3,h4,h5,h6,.is-h1,.is-h2,.is-h3,.is-h4,.is-h5,.is-h6': {
    marginTop: 0,
    letterSpacing: 0,
    fontFamily: theme.fonts.heading,
    fontWeight: theme.fonts.headingFontWeight,
    marginBottom: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      marginBottom: `${theme.spacing(4)}px`
    }
  },
  'h1, .is-h1': {
    fontSize: '42px',
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: '64px'
    }
  },
  'h2, .is-h2': {
    fontSize: '32px',
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: '40px'
    }
  },
  'h3, .is-h3': {
    fontSize: '24px',
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: '40px'
    }
  },
  'h4, .is-h4': {
    fontSize: '32px',
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: '32px'
    }
  },
  h5: {
    fontSize: '24px',
    letterSpacing: '0.02em'
  },
  h6: {
    fontSize: '15px'
  },
  'p, .is-p1': {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    fontSize: '14px',
    lineHeight: 1.5,
    margin: '0 0 1.5em'
  },
  '.is-caption': {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    fontSize: '11px',
    lineHeight: 1,
    letterSpacing: '0.12em',
    textTransform: 'uppercase'
  },
  blockquote: {
    fontFamily: theme.fonts.heading,
    fontWeight: theme.fonts.headingFontWeight,
    fontSize: '18px',
    margin: 0,
    lineHeight: 1.5,
    letterSpacing: '0.02em',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px'
    }
  },
  hr: {
    width: '100%',
    borderColor: theme.colors.black,
    height: '1px',
    borderWidth: '1px 0px 0px',
    opacity: 0.2
  },
  '.srOnly': {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  },
  a: {
    color: theme.colors.text,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  button: {
    background: 'none',
    border: 'none',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    color: theme.colors.text,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '.styled-link': {
    lineHeight: 1.25,
    display: 'inline-block',
    color: 'currentColor',
    position: 'relative',
    textDecoration: 'none',
    '&.is-active': {
      pointerEvents: 'none',
      '&:before': {
        transformOrigin: '0% 50%',
        transform: 'scale3d(1, 1, 1)'
      }
    },
    '&.is-active-with-pointer': {
      '&:before': {
        transformOrigin: '0% 50%',
        transform: 'scale3d(1, 1, 1)'
      }
    },
    '&:before, &:after': {
      position: 'absolute',
      width: '100%',
      height: '1px',
      backgroundColor: 'currentColor',
      top: '100%',
      left: '0',
      pointerEvents: 'none'
    },
    '&:before': {
      content: "''",
      transformOrigin: '100% 50%',
      transform: 'scale3d(0, 1, 1)',
      transition: 'transform 0.3s'
    },
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        '&:before': { transformOrigin: '0% 50%', transform: 'scale3d(1, 1, 1)' }
      }
    },
    '&.styled-link-underlined': {
      '&:before': { transition: 'transform 0.3s 0.3s' },
      '&:after': {
        content: "''",
        transformOrigin: '0% 50%',
        transform: 'scale3d(1, 1, 1)',
        transition: 'transform 0.3s'
      },
      '&:hover': {
        '&:after': {
          transformOrigin: '100% 50%',
          transform: 'scale3d(0, 1, 1)'
        }
      }
    }
  },
  '.scrollbar-track': {
    zIndex: '1001 !important'
  },
  '::selection': {
    background: '#c5dfdd'
  },
  '::-moz-selection': {
    background: '#c5dfdd'
  },
  '#chat-button': {
    display: 'none !important'
  },
  '.nprogress-busy *': {
    cursor: 'wait !important'
  }
})

export default function Global () {
  return (
    <GlobalStyles />
  )
}
