import cn from 'classnames'
import { createUseStyles } from '../helpers/createStyles'
import MinusLine from '../icons/minus-line.svg'
import { theme } from '../styles/theme'

const PlusMinus = () => {
  const styles = useStyles()
  return (
    <div aria-hidden className={cn(styles.root, 'plus-minus-icon')}>
      <MinusLine className={cn(styles.icon, styles.plus)} />
      <MinusLine className={styles.icon} />
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    width: 16,
    height: 16
  },
  icon: {
    position: 'absolute',
    top: 7,
    left: 0
  },
  plus: {
    transition: `all 300ms ${theme.eases.easeInOut}`,
    transform: 'rotate(90deg)',
    '[data-state=open] &': {
      transform: 'rotate(0deg)',
      opacity: 0
    }
  }
})

export default PlusMinus
