import { useMemo } from 'react'
import { formatCurrency } from '../helpers/format'
import { getCartTotalPrice, getCurrencyCode, getCurrencyLocale, getLineItemPrice, getPriceWithTax, isTaxInclusive } from '../store/pricesSlice'
import useSnapshot from '../store/useSnapshot'

export function useLineItemPrice (lineItem, currencyDisplay) {
  return usePriceFormat(lineItem, currencyDisplay, getLineItemPrice)
}

export function useCartTotalAmount (cart, currencyDisplay) {
  return usePriceFormat(cart, currencyDisplay, getCartTotalPrice)
}

export function usePriceFormat (price, currencyDisplay, getPrice = getPriceWithTax, exTax) {
  const snap = useSnapshot()
  const currencyCode = getCurrencyCode(snap)
  const locale = getCurrencyLocale(snap)
  const taxInclusive = isTaxInclusive(snap)
  return useMemo(() => {
    if (price === undefined || price == null || !locale) return
    const priceValue = exTax ? getPrice(price, false) : getPrice(price, taxInclusive)
    const result = formatCurrency(priceValue, locale, currencyCode, currencyDisplay)
    if (result.endsWith('.00')) return result.substring(0, result.length - 3)
    return result
  }, [currencyCode, locale, price, currencyDisplay, taxInclusive])
}
