import React from 'react'
import ResponsiveImage from '../../ResponsiveImage'
import { theme } from '../../../styles/theme'
import Link from '../../Link'
import { resolveInternalLinkUrl } from '../../../lib/resolveLink'
import cn from 'classnames'
import { createUseStyles } from '../../../helpers/createStyles'
import CategoryTitle from '../../CategoryTitle'

export default function PostTile ({
  post, className, layout = 'landscape', aspect,
  mobileAspect, smallMobile, largeTile,
  renderLinks = true, summary, eagerLoadImage
}) {
  const styles = useStyles()
  const { title, excerpt, featuredImage, category } = post
  const url = resolveInternalLinkUrl(post)
  const resolvedAspect = aspect || (layout === 'landscape' ? 1.29 : 0.69)

  return (
    <article className={className}>
      <Link to={renderLinks ? url : null} className={styles.link}>
        {featuredImage && (
          <ResponsiveImage loading={ eagerLoadImage ? 'eager' : undefined } className={styles.image} image={featuredImage} aspect={resolvedAspect} mobileAspect={mobileAspect} showPreview={!summary} />
        )}
        <CategoryTitle className={styles.category}>{category?.title}</CategoryTitle>
        <div className={cn(styles.content, { largeTile })}>
          <h4 className={cn(styles.title, { smallMobile })}>{title}</h4>
          <p className={cn(styles.description, { smallMobile })}>{excerpt}</p>
        </div>
      </Link>
    </article>
  )
}

const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
    display: 'block'
  },
  image: {
    marginBottom: theme.spacingPx(2)
  },
  category: {
    marginBottom: theme.spacingPx(1.2),
    display: 'block'
  },
  content: {
    '&.largeTile': {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        '& > *': {
          width: '50%'
        },
        '& > *:first-child': {
          marginRight: `${theme.gutter.md / 2}px`,
          marginBottom: 0
        },
        '& > *:last-child': {
          marginLeft: `${theme.gutter.md / 2}px`,
          marginBottom: 0
        }
      }
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: 1.4,
    letterSpacing: '0.02em',
    marginBottom: theme.spacingPx(1),
    '&.smallMobile': {
      fontSize: '16px',
      [theme.breakpoints.up('md')]: {
        fontSize: '20px'
      }
    }
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    '&.smallMobile': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        display: '-webkit-box' // eslint-disable-line
      }
    }
  }
})
