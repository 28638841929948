import { forwardRef } from 'react'
import get from 'lodash/get'
import Slices from './Slices'
import Footer from './Footer'
import Meta from './Meta'
import SmoothScrollbarProvider from './SmoothScrollbar/SmoothScrollbarProvider'
import PageForm from './PageForm'
import LandingPage from './LandingPage'
import isEmpty from 'lodash/isEmpty'
import ChatButton from './ChatButton'
export const PageScrollContainer = forwardRef(({
  data, className, footer = true, tag = 'main',
  showMeta = true, bodyScroll, scrollRef, topBannerRef, children, isDialog = false,
  id, pageThemeColor
}, ref) => {
  const page = get(data, ['page'])
  const settings = get(data, ['settings'])
  const localeVariations = get(data, ['localeVariations'])
  const seo = get(page, ['seo'])
  const slices = get(page, ['slices'])
  const pageType = get(page, ['pageType'])
  if (!page) return null
  return (
    <>
      {showMeta && <Meta seo={seo} page={page} settings={settings} localeVariations={localeVariations} />}
      <SmoothScrollbarProvider ref={ref} tag={tag} bodyScroll={bodyScroll} scrollRef={scrollRef} id={id} pageThemeColor={pageThemeColor} disableHorizontalScroll={pageType === 'form'}>
        {children}
        <Slices slices={slices} page={page} topBannerRef={topBannerRef} />
        {pageType === 'form' && <PageForm page={page} first={isEmpty(slices)} />}
        {pageType === 'landing-page' && <LandingPage page={page} />}
        {footer && (
          <Footer key={page._id} pageThemeColor={pageThemeColor}/>
        )}
        <ChatButton isDialog={isDialog} pageThemeColor={pageThemeColor}/>
      </SmoothScrollbarProvider>
    </>
  )
})

PageScrollContainer.displayName = 'PageScrollContainer'

export default PageScrollContainer
