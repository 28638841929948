import React, { forwardRef, useMemo } from 'react'
import NextLink from 'next/link'
import { resolveLink } from '../lib/resolveLink'
import ExternalLinkIcon from '../icons/externalLink.svg'
import { createUseStyles } from '../helpers/createStyles'

const Link = forwardRef(({ nonLinkTag = 'span', link, to, children, showText = true, showExternalIcon = true, ...rest }, ref) => {
  const styles = useStyles()
  const isSocialLink = link?._type === 'socialLink'
  const { url, text } = useMemo(() => {
    if (isSocialLink) return { url: link.url, text: link.title }
    if (to) return { url: to }
    if (link) {
      return resolveLink(link)
    }
    return {}
  }, [link, to])

  // External Link
  if (url && (url.indexOf('http') >= 0 || url.indexOf('tel:') >= 0 || url.indexOf('mailto:') >= 0)) {
    return (
      <a href={url} target='_blank' rel='noreferrer noopener' {...rest} ref={ref}>
        {showText && text}{children}
        {showExternalIcon && !isSocialLink && <ExternalLinkIcon className={styles.externalLinkIcon} />}
      </a>
    )
  }

  // No Link
  if (!url) {
    const Tag = nonLinkTag
    return <Tag {...rest} ref={ref}>{showText && text}{children}</Tag>
  }

  // Internal Link
  return (
    <NextLink href={url} scroll={false} locale={false} prefetch={false}>
      <a {...rest} ref={ref}>{showText && text}{children}</a>
    </NextLink>
  )
})

const useStyles = createUseStyles({
  externalLinkIcon: {
    width: '0.5em',
    height: '0.5em',
    display: 'inline-block',
    marginLeft: '2px'
  }
})

Link.displayName = 'Link'

export default Link
