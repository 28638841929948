import { useCallback } from 'react'
import { theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'
import ModalDialog from './Dialog/ModalDialog'
import useSnapshot from '../store/useSnapshot'
import { dismiss, isOpen } from '../store/salesPopup'
import ResponsiveImage from './ResponsiveImage'
import { getSiteData } from '../store/layoutSlice'
import RichContent from './RichContent'
import NewsletterForm from './NewsletterForm'

const SalesPopup = () => {
  const styles = useStyles()
  const snap = useSnapshot()
  const open = isOpen(snap)
  const { image, title, copy, joinButtonLabel, emailPlaceholderLabel, subscribedMessage } = getSiteData(snap)?.salesPopup || {}
  const onClose = useCallback(() => {
    dismiss()
  }, [])

  return (
    <>
      <ModalDialog
        open={open}
        className={styles.cart}
        onClose={onClose}
        size='xxxs'
      >
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <ResponsiveImage image={image} aspect={330 / 266} />
          </div>
          <div className={styles.content}>
            <h4 className={styles.title}>{title}</h4>
            <RichContent content={copy} className={styles.copy} />
            <NewsletterForm size='sm' placeholder={emailPlaceholderLabel} subscribedMessage={subscribedMessage} joinLabel={joinButtonLabel} successMessage={subscribedMessage} />
          </div>
        </div>
      </ModalDialog>
    </>
  )
}

const useStyles = createUseStyles({
  cart: {
    backgroundColor: theme.colors.pageTheme.default.background,
    zIndex: theme.zIndex.dialog + 1,
    color: theme.colors.text,
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.cart
    }
  },
  container: {
    height: 'calc(100vh - 96px)',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.gutter.sm,
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      padding: theme.gutter.md,
      paddingBottom: 0
    }
  },
  imageContainer: {
    marginBottom: theme.spacing(4.25)
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  copy: {
    marginBottom: theme.spacing(5.5)
  }
})

export default SalesPopup
