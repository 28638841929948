import cn from 'classnames'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { theme } from '../../../styles/theme'
import Section from '../../Section'
import Buttons from './Buttons'
import useScrollTrigger from '../../../hooks/useScrollTrigger'

const TradeChildHero = ({ data, page }) => {
  const { title: sliceTitle, intro, siblings } = data
  const { title: pageTitle } = page
  const styles = useStyles()
  const title = useMemo(() => sliceTitle || pageTitle, [sliceTitle, pageTitle])
  const links = useMemo(
    () => [{ ...page.parent, title: 'Back' }, ...siblings.map(x => x.page)],
    [siblings, page.parent]
  )

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.querySelectorAll(`.${styles.inner} > *`), { y: 40, opacity: 0, stagger: 0.1, ...defaults })
    }
  )

  return (
    <Section grid className={cn(styles.root)} ref={sectionRef}>
      <div className={styles.inner}>
        <h1 className={styles.title}>{title}</h1>
        {!!links && <Buttons items={links} className={cn(intro && styles.buttonSpacing)} />}
        {!!intro && <p>{intro}</p>}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  root: `
    padding-top: ${theme.spacingPx(14)};
    row-gap: 0!important;
    ${theme.breakpoints.up('md')} {
      padding-top: ${theme.spacingPx(26)};
    }
  `,
  inner: `
    grid-column: 1 / -1;
    max-width: 500px;
    ${theme.breakpoints.up('md')} {
      grid-column: 5 / -1;
      max-width: 600px;
    }
  `,
  title: `
    margin-bottom: ${theme.spacingPx(5)};
  `,
  buttonSpacing: `
    margin-bottom: 56px;
  `
})

TradeChildHero.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    intro: PropTypes.string
  }).isRequired,
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    siblings: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
}

export default TradeChildHero
