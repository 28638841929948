import get from 'lodash/get'
import compact from 'lodash/compact'
import { getRouteByLocale } from '../../locales'

function resolveSlug (document) {
  const slug = get(document, ['slug'], get(document, ['current'], document))
  if (slug !== 'home') { // home resolves to `/`
    return slug
  }
}

export function resolveUrlWithLocal ({ url, locale }) {
  const parts = [
    getRouteByLocale(locale),
    url
  ]
  return `/${compact(parts).join('/')}`
}

export function resolveInternalLinkUrl ({ parent, slug, type, locale }) {
  const parts = [
    getRouteByLocale(locale),
    resolveSlug(parent),
    resolveSlug(slug)
  ]
  return `/${compact(parts).join('/')}`
}

export function resolveLink (linkOrPage) {
  if (!linkOrPage) return null
  if (linkOrPage.linkType) {
    if (linkOrPage.linkType === 'external') {
      return linkOrPage
    }
    return {
      text: get(linkOrPage, ['text'], get(linkOrPage, ['page', 'title'])),
      url: linkOrPage.page ? resolveInternalLinkUrl(linkOrPage.page) : ''
    }
  }
  return {
    text: get(linkOrPage, ['title']),
    url: resolveInternalLinkUrl(linkOrPage)
  }
}
