import cn from 'classnames'
import { theme, span, grid } from '../../styles/theme'
import Section from '../Section'
import { createUseStyles } from '../../helpers/createStyles'
import useSnapshot from '../../store/useSnapshot'
import { getSharedProductResources, getSiteData } from '../../store/layoutSlice'
import { useMemo } from 'react'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import isSampleProduct from '../../helpers/isSampleProduct'
import { useConvertUnitSystem } from '../../hooks/useConvertUnitSystem'
import FileIcon from '../../icons/file.svg'

export default function ProductDetails ({ data, page }) {
  const styles = useStyles()
  const product = page
  const {
    // description: productDescription,
    sizeList,
    room_tags: roomTags,
    fibre_tags: fibreTags
  } = product
  const { description, resources } = data

  const snap = useSnapshot()
  const convertSizeString = useConvertUnitSystem()
  const sharedResources = getSharedProductResources(snap)
  const fiberLabel = getSiteData(snap).fiberLabel

  const allResources = useMemo(() => {
    return compact([
      ...(sharedResources || []),
      ...(resources || [])
    ])
  }, [sharedResources, resources])

  const availableSizes = useMemo(() => {
    return sizeList && sizeList.filter(sizeObject => !isSampleProduct(sizeObject.value)).map(sizeObject => (<span key={sizeObject.value}>{convertSizeString(sizeObject)}</span>))
  }, [sizeList, convertSizeString])

  return (
    <Section className={styles.productDetails} grid>
      <div className={styles.container}>
        <div className={styles.description}>
          {description}
        </div>
        <div className={styles.detailsTable}>
          {!isEmpty(availableSizes) && (
            <div className={styles.tableColumn}>
              <div className={cn(styles.columnHeading, 'is-caption')}>
                <span>Available Sizes</span>
              </div>
              <div className={styles.columnContent}>
                {availableSizes}
              </div>
            </div>
          )}
          <div className={styles.tableColumn}>
            <div className={cn(styles.columnHeading, 'is-caption')}>
              <span>Areas of Use</span>
            </div>
            <div className={styles.columnContent}>
              {roomTags && roomTags.map(tag => (<span key={tag._id}>{tag.name}</span>))}
            </div>
          </div>
          <div className={styles.tableColumn}>
            <div className={cn(styles.columnHeading, 'is-caption')}>
              <span>{fiberLabel}</span>
            </div>
            <div className={styles.columnContent}>
              {fibreTags && fibreTags.map(tag => (<span key={tag._id}>{tag.name}</span>))}
            </div>
          </div>
          <div className={styles.tableColumn}>
            <div className={cn(styles.columnHeading, 'is-caption')}>
              <span>Resources</span>
            </div>
            <div className={styles.columnContent}>
              {allResources?.map(resource => (
                <a key={resource.file.url} href={`${resource.file.url}?dl=`}>
                  {resource.label} <FileIcon />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  productDetails: {},
  container: {
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      gridColumn: '4 / -1'
    }
  },
  description: {
    fontSize: '18px',
    marginBottom: `${theme.spacing(8)}px`,
    [theme.breakpoints.up('md')]: {
      marginBottom: `${theme.spacing(10)}px`,
      fontSize: '24px',
      textIndent: span(2, 'md', 0, true, 16)
    }
  },
  detailsTable: {
    display: 'grid',
    gridTemplateColumns: `repeat(${grid.sm.columns}, minmax(0px, 1fr))`,
    gap: `${grid.sm.gutter}px`,
    rowGap: `${grid.md.gutter}px`,
    [theme.breakpoints.up('md')]: {
      gap: `${grid.md.gutter}px`,
      gridTemplateColumns: 'repeat(8, minmax(0px, 1fr))'
    }
  },
  tableColumn: {
    gridColumn: 'span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 2'
    }
  },
  columnHeading: {
    paddingTop: `${theme.spacing(1.5)}px`,
    paddingBottom: `${theme.spacing(1.5)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    borderBottom: '1px solid var(--border)',
    [theme.breakpoints.up('md')]: {
      marginBottom: `${theme.spacing(3)}px`
    }
  },
  columnContent: {
    fontSize: 12,
    '& span': {
      display: 'block'
    },
    '& a': {
      display: 'block',
      color: 'currentColor',
      textDecoration: 'none'
    }
  }
})
