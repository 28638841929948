import { useContext, useEffect, useMemo } from 'react'
import { getIndex, getLimit } from '../../../store/productListing'
import {
  SORT_RECENT,
  SORT_PRICE,
  getSortMode,
  getRoomFilters,
  getRoomStyleFilters,
  getColourFilters,
  getSizeFilters,
  getMinPriceFilter,
  getMaxPriceFilter,
  getCollectionFilters,
  getSuitabilityFilters,
  getFibreFilters,
  setShopFiltersResultCount,
  getInStockOnlyFilter,
  SORT_NAME
} from '../../../store/shopFiltersSlice'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import find from 'lodash/find'
import reverse from 'lodash/reverse'
import minBy from 'lodash/minBy'
import useSnapshot from '../../../store/useSnapshot'
import { setIndex } from '../../../store/productCollectionSlice'
import { SmoothScrollContext } from '../../SmoothScrollbar/useSmoothScrollbar'
import { getColorVariant, getSizeVariant } from '../../../helpers/productHelper'
import isSampleProduct from '../../../helpers/isSampleProduct'

export default function useProductVariantFilter (variants, category, onSaleOnly, inStockOnly, featuredProductRefs, unlimited, samplesOnly) {
  const snap = useSnapshot()
  const index = getIndex(snap)
  const limit = getLimit(snap)
  const sortMode = getSortMode(snap)
  const roomFilters = getRoomFilters(snap)
  const roomStyleFilters = getRoomStyleFilters(snap)
  const colorFilters = getColourFilters(snap)
  const inStockOnlyFilter = getInStockOnlyFilter(snap)
  const sizeFilters = getSizeFilters(snap)
  const minPriceFilter = getMinPriceFilter(snap)
  const maxPriceFilter = getMaxPriceFilter(snap)
  const collectionFilters = getCollectionFilters(snap)
  const sustainabilityFilters = getSuitabilityFilters(snap)
  const fibreFilters = getFibreFilters(snap)
  const scrollContext = useContext(SmoothScrollContext)

  const filteredItems = useMemo(() => {
    if (variants) {
      setIndex(0)
      let results = variants
      const featuredItems = []

      if (onSaleOnly) {
        results = variants.filter(prod => prod.onSale)
      }
      if (inStockOnly) {
        results = filterByInStockOnly(results)
      }
      results = filterOutHidden(results)

      if (category) results = filterByCategory(results, category)
      if (minPriceFilter || maxPriceFilter) results = filterByPrice(results, minPriceFilter, maxPriceFilter)
      if (roomFilters && roomFilters.length > 0) results = filterByTag(results, 'room_tags', roomFilters)
      if (roomStyleFilters && roomStyleFilters.length > 0) results = filterByTag(results, 'room_style_tags', roomStyleFilters)
      if (colorFilters && colorFilters.length > 0) results = filterByColors(results, colorFilters)
      if (sizeFilters && sizeFilters.length > 0) results = filterBySizes(results, sizeFilters)
      if (collectionFilters && collectionFilters.length > 0) results = filterByCategories(results, collectionFilters)
      if (sustainabilityFilters && sustainabilityFilters.length > 0) results = filterByTag(results, 'suitability_tags', sustainabilityFilters)
      if (fibreFilters && fibreFilters.length > 0) results = filterByTag(results, 'fibre_tags', fibreFilters)
      if (inStockOnlyFilter) results = filterByInStockOnly(results)
      if (samplesOnly) results = filterBySamplesOnly(results)

      if (featuredProductRefs && featuredProductRefs.length > 0) {
        for (let i = 0; i < featuredProductRefs.length; i++) {
          for (let j = 0; j < results.length; j++) {
            if (featuredProductRefs[i]._id === results[j].product?._id) {
              featuredItems.push(results[j])
              results.splice(j--, 1)
            }
          }
        }
      }

      setShopFiltersResultCount(results.length + featuredItems.length)
      return [results, featuredItems]
    }
    return []
  }, [
    variants,
    category,
    sortMode,
    roomFilters,
    roomStyleFilters,
    colorFilters,
    minPriceFilter,
    maxPriceFilter,
    collectionFilters,
    sustainabilityFilters,
    fibreFilters,
    onSaleOnly,
    inStockOnly,
    inStockOnlyFilter,
    featuredProductRefs
  ])

  // If any of the filters change then we need to put the user back to the top of the page
  useEffect(() => {
    scrollContext?.current.setScrollY(0, 0)
  }, [
    category,
    sortMode,
    roomFilters,
    roomStyleFilters,
    colorFilters,
    minPriceFilter,
    maxPriceFilter,
    collectionFilters,
    sustainabilityFilters,
    fibreFilters,
    inStockOnlyFilter
  ])

  const sortedAndPagedResults = useMemo(() => {
    const [results, featuredItems] = filteredItems
    const sortedItems = sort(results, sortMode)
    const sortedFeatured = sortMode !== SORT_NAME ? sort(featuredItems, sortMode) : featuredItems
    return sortedFeatured.concat(sortedItems.slice(0, (index * limit) + limit))
  }, [filteredItems, sortMode, index, limit])

  const sortedResults = useMemo(() => {
    const [results, featuredItems] = filteredItems
    const sortedItems = sort(results, sortMode)
    const sortedFeatured = sortMode !== SORT_NAME ? sort(featuredItems, sortMode) : featuredItems
    return sortedFeatured.concat(sortedItems)
  }, [filteredItems, sortMode])
  return [sortedAndPagedResults, sortedResults]
}

const getVariantData = (variantId, product) => {
  const variantData = find(product?.colors, c => c.bigCommerceId === variantId)
  return variantData
}

const filterByCategory = (variants, category) => {
  const categoryId = get(category, ['_id'])
  if (!categoryId) return variants
  return variants.filter(({ product }) =>
    get(product, ['primaryCategory', '_id']) === categoryId ||
    !!find(get(product, ['categories']), cat => cat._id === categoryId)
  )
}

const filterByCategories = (variants, filterCategories) => {
  return variants.filter(({ product }) => {
    const variantCategories = [...get(product, ['categories'], []), get(product, ['primaryCategory'])]
    const matchingCategories = variantCategories ? variantCategories.filter(tag => filterCategories.findIndex(filter => filter._id === tag?._id) !== -1) : []
    return matchingCategories && matchingCategories.length > 0
  })
}

const filterByTag = (variants, tagKey, filters) => {
  return variants.filter(({ product }) => {
    const productTags = get(product, [tagKey], [])
    const matchingTags = productTags ? productTags.filter(tag => filters.findIndex(filter => filter._id === tag._id) !== -1) : []
    return matchingTags && matchingTags.length > 0
  })
}

const filterBySamplesOnly = (allVariants) => {
  return allVariants.filter(({ variantId, product }) => {
    const productVariants = get(product, 'product.variants')
    const matchingVariant = productVariants.find(currentVariant => {
      const { option_values: optionValues } = currentVariant
      const currentVariantSize = getSizeVariant(optionValues)
      const currentVariantColor = getColorVariant(optionValues)
      return (isSampleProduct(currentVariantSize?.label) && currentVariantColor?.id === variantId)
    })
    return !!matchingVariant
  })
}

const filterByInStockOnly = (variants) => {
  return variants.filter(({ product }) => {
    const inventoryLevel = get(product, ['product', 'inventory_level'], '')
    return inventoryLevel > 0
  })
}

const filterByColors = (variants, colourFilters) => {
  return variants.filter(({ variantId, product }) => {
    const variantData = getVariantData(variantId, product)
    const colourTag = get(variantData, 'generic_colour', null)
    return colourTag ? colourFilters.findIndex(filter => filter._id === colourTag._id) !== -1 : false
  })
}

const filterBySizes = (variants, sizeFilters) => {
  return variants.filter(({ variantId, product }) => {
    return product.product.variants.some(
      currVariant => currVariant.option_values.some(
        currOptionValue => currOptionValue.option_display_name === 'Size' && sizeFilters.some(
          currSize => currSize.value === currOptionValue.label
        )
      ) && currVariant.option_values.some(
        currOptionValue => (currOptionValue.option_display_name.indexOf('Colour') >= 0 || currOptionValue.option_display_name.indexOf('Color') >= 0) && currOptionValue.id === variantId
      )
    )
  })
}

const filterByPrice = (variants, filterMin, filterMax) => {
  return variants.filter(({ minPrice: variantMin, maxPrice: variantMax }) => {
    // if (variantMin === 0) return null
    return variantMax === undefined || (variantMax >= filterMin && variantMin >= filterMin && variantMin <= filterMax)
  })
}

const filterOutHidden = (variants) => {
  return variants.filter(({ variantId, product }) => {
    const variantData = getVariantData(variantId, product)
    const isDisabled = variantData?.disabled
    return !isDisabled ? product : false
  })
}

const sort = (variants, sortMode) => {
  let results = variants
  let desc = false
  switch (sortMode) {
    case SORT_NAME:
      results = sortBy(variants, x => {
        return get(x, ['product', 'title'])
      })
      break
    case SORT_PRICE:
      results = sortBy(variants, x => {
        const productVariants = get(x, ['product', 'product', 'variants'])
        const cheapestVariant = minBy(productVariants, variant => {
          const variantPrice = get(variant, 'calculated_price')

          if (variantPrice > 0) { // Ignore variants priced at $0
            return variantPrice
          }
        })

        return get(cheapestVariant, 'calculated_price')
      })
      break
    case SORT_RECENT:
      desc = true
      results = sortBy(variants, x => {
        return get(x, ['product', 'product', 'date_created'])
      })
      break
  }
  if (desc) {
    reverse(results)
  }
  return results
}
