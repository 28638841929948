import { useEffect } from 'react'
import { isBrowser } from '../lib/helpers'
import { getPageData, getSiteData, setChatBotOpen } from '../store/layoutSlice'
import useSnapshot from '../store/useSnapshot'

let gorgiasChatInitiated = false
let loading = false

export function isGorgiasChatBotReady () {
  if (!isBrowser) return false
  return gorgiasChatInitiated
}

export default function GorgiasChatBot () {
  const snap = useSnapshot()
  const site = getSiteData(snap)
  const pageData = getPageData(snap)

  const { chatbotApplicationId, defaultTimezone, showChatbot, businessHours } = site

  useEffect(() => {
    if (showChatbot && !loading && !gorgiasChatInitiated) {
      window.GORGIASCHAT_DISABLE_AUTO_OPEN = true

      loading = true
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]

      script.setAttribute('id', 'gorgias-chat-widget-install-v3')
      script.src = `https://config.gorgias.chat/bundle-loader/${chatbotApplicationId}`
      body.appendChild(script)

      const timezone = businessHours.find(x => x.timezone === currentTimezone)?.timezone || defaultTimezone
      const hours = businessHours.filter(x => x.timezone === timezone)
      const chatBusinessHours = {
        timezone: timezone,
        businessHours: hours.map(({ days, fromTime, toTime }) => ({
          days: days.split(',').map(value => parseInt(value)), fromTime, toTime
        }))
      }

      script.addEventListener('load', () => {
        window.GorgiasChat?.init().then(function () {
          gorgiasChatInitiated = true
          loading = false
          window.GorgiasChat.setCustomBusinessHours(chatBusinessHours)
          window.GorgiasChat.hidePoweredBy(true)
        })
      })
    }
  }, [])

  useEffect(() => {
    if (!isGorgiasChatBotReady()) return
    setChatBotOpen(false)
    window.GorgiasChat.close()
  }, [pageData])

  return null
}
