import PropTypes from 'prop-types'
import cn from 'classnames'

import { createUseStyles } from '../../../helpers/createStyles'
import { theme } from '../../../styles/theme'
import RoundButton from '../../RoundButton'

const Buttons = ({ items, className, summary }) => {
  const styles = useStyles()
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.slider}>
        {items.map((page, i) => (
          <RoundButton key={page._id || i} link={page} className={styles.item} summary={summary} />
        ))}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  root: `
    margin-left: -24px;
    margin-right: -24px;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  slider: `
    display: flex;
    flex-wrap: nowrap;
    padding: 0 24px;
  `,
  item: `
    flex-shrink: 0;
    margin-right: ${theme.spacingPx(0.5)}!important;
    ${theme.breakpoints.up('md')} {
      margin-right: ${theme.spacingPx(1)}!important;
    }
    &:last-child {
      margin-right: 0!important;
    }
  `
})

Buttons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Buttons
