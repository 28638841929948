import React, { useCallback, useEffect, useRef } from 'react'
import cn from 'classnames'
import Link from './Link'
import { theme } from '../styles/theme'
import useHover from '../hooks/useHover'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'
import { createUseStyles } from '../helpers/createStyles'

const StyledLink = ({ className, children, onClick, ...props }) => {
  const [ref, hovering] = useHover(primaryInput !== 'touch')
  const lineRef = useRef()
  const localsRef = useRef({})
  const styles = useStyles()

  const animate = () => {
    if (localsRef.current.timeline) localsRef.current.timeline.kill()
    const tl = gsap.timeline()
    localsRef.current.timeline = tl
    tl.fromTo(lineRef.current, { x: '0%' }, { x: '160%', duration: 0.5, ease: 'sine.out' })
  }

  useEffect(animate, [hovering])

  const animateClick = useCallback(animate, [])

  const handleClick = useCallback(() => {
    if (onClick) onClick()
    animateClick()
  }, [])

  return (
    <Link className={cn(className, styles.link)} ref={ref} nonLinkTag={onClick ? 'button' : 'span'} onClick={handleClick} {...props}>
      {children}
      <span className={styles.line} ref={lineRef} />
    </Link>
  )
}

const useStyles = createUseStyles({
  link: {
    display: 'inline-block',
    fontFamily: theme.fonts.body,
    fontSize: '11px',
    lineHeight: 1.18,
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
    padding: `${theme.spacing(1)}px 0`,
    position: 'relative',
    textDecoration: 'none',
    overflow: 'hidden',
    color: 'currentColor'
  },
  line: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '1px',
    '&:after': {
      content: '""',
      display: 'block',
      backgroundColor: 'currentColor',
      position: 'absolute',
      width: '100%',
      left: 0,
      height: '1px'
    },
    '&:before': {
      content: '""',
      display: 'block',
      backgroundColor: 'currentColor',
      position: 'absolute',
      left: '-160%',
      width: '100%',
      height: '1px'
    }
  }
})

export default StyledLink
