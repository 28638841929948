import { useMemo } from 'react'
import useSnapshot from '../../../store/useSnapshot'
import { getDonationProduct, getProducts } from '../../../store/productsSlice'

export default function useProductsWithoutDonations () {
  const snap = useSnapshot()
  const products = getProducts(snap)
  const donationProduct = getDonationProduct(snap)
  return useMemo(() => {
    return products?.filter(p => p !== donationProduct)
  }, [products, donationProduct])
}
