import { useEffect, useRef } from 'react'
import SmoothScrollbarProvider from '../SmoothScrollbar/SmoothScrollbarProvider'
import { createUseStyles } from '../../helpers/createStyles'
import { span, theme } from '../../styles/theme'
import Dialog from './Dialog'
import gsap from 'gsap'
import cn from 'classnames'

export default function ModalDialog ({
  columns = 9, children, open, onClose,
  header, footer, size = 'sm', colorTheme,
  hideOverlayAtDesktop = false, className,
  zIndex
}) {
  const styles = useStyles()
  const footerRef = useRef()

  const contentRef = useRef()

  useEffect(() => {
    if (footerRef.current) {
      const { height } = footerRef.current.getBoundingClientRect()
      gsap.set(contentRef.current, { paddingBottom: height })
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={cn(styles.dialog, size, className)}
      colorTheme={colorTheme}
      hideOverlayAtDesktop={hideOverlayAtDesktop}
      footer={footer && <footer className={styles.footer} ref={footerRef}>{footer}</footer>}
      zIndex={zIndex}
    >
      {header && <header className={styles.header}>{header}</header>}
      <SmoothScrollbarProvider ref={contentRef} tag='div'>
        {children}
      </SmoothScrollbarProvider>
    </Dialog>
  )
}

const useStyles = createUseStyles({
  dialog: {
    marginTop: theme.spacing(12),
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    },
    '&.xxxs': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(9, 'md')
      }
    },
    '&.xxs': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(8, 'md')
      }
    },
    '&.xs': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(7, 'md')
      }
    },
    '&.sm': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(3, 'md')
      }
    },
    '&.md': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(2, 'md')
      }
    },
    '&.lg': {
      [theme.breakpoints.up('md')]: {
        marginLeft: span(1, 'md')
      }
    }
  },
  header: {
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1
  },
  footer: {
    display: 'block',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2
  }
})
