import React, { useMemo, forwardRef, useEffect, useCallback, useRef } from 'react'
import transform, { MOBILE_WIDTHS } from './transform'
import get from 'lodash/get'
import { theme } from '../../styles/theme'
import cn from 'classnames'
import gsap from 'gsap'
import { createUseStyles } from '../../helpers/createStyles'
import BlurHash from './BlurHash'

import { menuIsTransitioning } from '../Header/Menu'

export const getObjectPosition = (hotspot, crop) => {
  if (!hotspot) return 'center bottom'
  if (!crop) return `${hotspot.x * 100}% ${hotspot.y * 100}%`
  return `${((hotspot.x - crop.left) / (1 - (crop.left + crop.right))) * 100}% ${((hotspot.y - crop.top) / (1 - (crop.top + crop.bottom))) * 100}%`
}

const Source = ({ media, sizes, lazy }) => {
  const srcName = lazy ? 'data-srcset' : 'srcSet'
  const srcset = key => sizes.map(item => (`${item[key]} ${item.width}w`)).join()
  return (
    <source media={media} {...{ [srcName]: srcset('url') }} />
  )
}

const Picture = forwardRef(({ pictureClassName, style, className, alt, sizes, mobileSizes, onLoad, loading = 'lazy' }, ref) => {
  const lazy = loading === 'lazy'

  return (
    <picture className={pictureClassName}>
      {mobileSizes && sizes && (
        <>
          <Source sizes={mobileSizes} media={`(max-width: ${theme.breakpoints.values.md - 1}px)`} lazy={lazy} />
          <Source sizes={sizes} media={`(min-width: ${theme.breakpoints.values.md}px)`} lazy={lazy} />
        </>
      )}
      {!mobileSizes && sizes && <Source sizes={sizes} lazy={lazy} />}
      <img
        ref={ref}
        data-sizes='auto'
        alt={alt}
        className={cn((sizes && lazy && 'lazyload') || (!lazy && 'lazyloaded'), className)}
        onLoad={onLoad}
        style={style}
      />
    </picture>
  )
})

Picture.displayName = 'Picture'

const Preview = forwardRef(({ className, aspect, alt, lqip, blurHash }, ref) => {
  if (blurHash) {
    return (
      <BlurHash blurHash={blurHash} />
    )
  }
  return (
    <img ref={ref} alt={alt} src={lqip} className={className} />
  )
})

Preview.displayName = 'Preview'

const ResponsiveImage = forwardRef(({
  className, classNames = {}, aspect, mobileAspect,
  children, image, mobileImage, onLoad, loading = 'lazy',
  showPreview = true, style,
  fadeIn = true, fadeInDelay = 0, fadeInDuration = 1,
  background
}, ref) => {
  const data = useMemo(() => transform(image, aspect, background), [image, aspect])
  const mobileData = useMemo(() => mobileImage ? transform(mobileImage, mobileAspect, background, MOBILE_WIDTHS) : null, [mobileImage, mobileAspect])
  const { hotspot, crop } = { hotspot: get(image, ['hotspot']), crop: get(image, ['crop']) }
  const classes = useStyles()
  const localsRef = useRef({ fadingIn: false })

  // This is a bit strange, i put this here to fix a reload of the image when the menuIsTransitioning value changes and
  // you change the loading type to 'lazy'. It will then reload the image
  const staticPropsRef = useRef({
    loading: menuIsTransitioning ? 'eager' : loading,
    showPreview: menuIsTransitioning ? false : showPreview,
    fadeIn: menuIsTransitioning ? false : fadeIn
  })

  const pictureRef = useRef()

  const lqip = get(image, ['asset', 'metadata', 'lqip'])
  const blurHash = get(image, ['asset', 'metadata', 'blurHash'])
  const preview = lqip || blurHash

  const hasAlpha = get(image, ['asset', 'metadata', 'hasAlpha'])

  const handleLoad = useCallback(() => {
    if (onLoad) onLoad()
    if (staticPropsRef.current.fadeIn && localsRef.current.fadingIn === false) {
      localsRef.current.fadingIn = true
      gsap.to(pictureRef.current, {
        opacity: 1,
        duration: fadeInDuration,
        ease: 'power2.inOut',
        delay:
        fadeInDelay,
        onComplete: () => { localsRef.current.fadingIn = false }
      })
    }
  }, [onLoad, staticPropsRef.current.fadeIn, fadeInDelay, fadeInDuration])

  useEffect(() => {
    if (pictureRef.current && pictureRef.current.complete) {
      handleLoad()
    }
  }, [handleLoad])

  const imageClassName = cn(classes.image, classNames.image)

  const sizes = get(data, ['sizes'])
  const mobileSizes = get(mobileData, ['sizes'])
  const alt = get(image, ['alt']) || get(image, ['asset', 'altText'])

  const resolvedMobileAspect = (mobileAspect || get(mobileData, ['sourceAspect']))
  const resolvedAspect = aspect || get(data, 'sourceAspect')

  // Needed to add theses silly div's to set the aspect for mobile and desktop as there is no way
  // to have a dynamic prop in a media query and have the css ssr
  return (
    <div className={cn(className, classes.imageContainer)} ref={ref} style={style}>
      {!resolvedMobileAspect && <div style={{ paddingTop: `${100 / resolvedAspect}%` }} />}
      {resolvedMobileAspect && (
        <>
          <div className={classes.desktopAspect} style={{ paddingTop: `${100 / resolvedAspect}%` }} />
          <div className={classes.mobileAspect} style={{ paddingTop: `${100 / resolvedMobileAspect}%` }} />
        </>
      )}
      {!hasAlpha && staticPropsRef.current.showPreview && preview && <Preview lqip={lqip} aspect={resolvedAspect} blurHash={blurHash} alt={alt} className={imageClassName} />}
      <Picture
        className={cn(imageClassName, { fadeIn: staticPropsRef.current.fadeIn })}
        alt={alt}
        sizes={sizes}
        mobileSizes={mobileSizes}
        onLoad={handleLoad}
        ref={pictureRef}
        loading={staticPropsRef.current.loading}
        style={{ objectPosition: getObjectPosition(hotspot, crop) }}
      />
      {children}
    </div>
  )
})

ResponsiveImage.displayName = 'ResponsiveImage'

export default ResponsiveImage

const useStyles = createUseStyles({
  imageContainer: {
    position: 'relative',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    '& > *, & > picture > img': {
      zIndex: theme.zIndex.image
    }
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&.fadeIn': {
      opacity: 0
    }
  },
  desktopAspect: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mobileAspect: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
})
