import React, { forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { primaryInput } from 'detect-it'
import useDebouncedCallback from '../hooks/useDebouncedCallback'
import useWindowResize from '../hooks/useWindowResize'
import { theme } from '../styles/theme'
import ResponsiveImage from './ResponsiveImage'
import { SmoothScrollContext } from './SmoothScrollbar/useSmoothScrollbar'
import gsap from 'gsap'
import { lerp } from '../helpers/math'
import Link from './Link'
import { useProductVariantDetails } from '../hooks/useProductVariantDetails'
import useHover from '../hooks/useHover'
import useComposeRefs from '../hooks/useComposeRefs'
import round from 'lodash/round'
import { createUseStyles } from '../helpers/createStyles'
import ProductPrice from './ProductPrice'
import useSnapshot from '../store/useSnapshot'
import { getSiteData } from '../store/layoutSlice'
import { useRouter } from 'next/router'
import useOnClickOutside from '../hooks/useOnOutsideClick'
import cn from 'classnames'
import sortBy from 'lodash/sortBy'

// Set the pageOffset to false for elements that are not inside the scroll area
const useStickyCursorPosition = (options = { distance: 100, pageOffset: true, ease: 0.05 }) => {
  const scrollContext = useContext(SmoothScrollContext)
  const boundsRef = useRef({ })
  const transformTarget = useRef({ currentX: 0, currentY: 0, x: 0, y: 0 })
  const ref = useRef()

  const checkSticky = (mouse, target, element) => {
    const { getScrollY } = scrollContext.current
    const distance = {
      x: target.x - mouse.x,
      y: target.y - mouse.y - (target.pageOffset ? getScrollY() : 0)
    }

    const a = Math.atan2(distance.x, distance.y)
    const h = Math.sqrt(distance.x * distance.x + distance.y * distance.y)

    if (h < options.distance) {
      target.stick = true
      const x = -Math.sin(a) * h / 2.5
      const y = -Math.cos(a) * h / 2.5
      transformTarget.current = { ...transformTarget.current, x, y }
    } else if (h > options.distance && target.stick) {
      target.stick = false
      transformTarget.current = { ...transformTarget.current, x: 0, y: 0 }
    }
  }

  useEffect(() => {
    if (primaryInput !== 'touch') {
      const tick = () => {
        if (ref.current) {
          const { x, y, currentX, currentY } = transformTarget.current
          const targetY = lerp(currentY, y, options.ease)
          const targetX = lerp(currentX, x, options.ease)
          ref.current.style.transform = `translate(${round(targetX, 1)}px, ${round(targetY, 1)}px)`
          transformTarget.current.currentX = targetX
          transformTarget.current.currentY = targetY
        }
      }
      gsap.ticker.add(tick)
      return () => {
        gsap.ticker.remove(tick)
      }
    }
  }, [])

  useEffect(() => {
    if (primaryInput !== 'touch') {
      const updateMousePosition = (evnt) => {
        const { scrollElement } = scrollContext.current
        if (scrollElement) {
          const e = evnt.detail && evnt.detail.pageX ? evnt.detail : evnt
          const y = e.pageY - scrollElement.scrollTop
          checkSticky({ x: e.pageX, y }, boundsRef.current, ref.current)
        }
      }
      window.addEventListener('mousemove', updateMousePosition, { passive: true })
      window.addEventListener('dragover', updateMousePosition, { passive: true })
      // window.addEventListener('flickity-dragMove', updateMousePosition, { passive: true })
      return () => {
        window.removeEventListener('mousemove', updateMousePosition)
        window.removeEventListener('dragover', updateMousePosition)
        // window.removeEventListener('flickity-dragMove', updateMousePosition)
      }
    }
  }, [])

  const updateElementPosition = useCallback(() => {
    const { getScrollY } = scrollContext.current
    if (ref.current) {
      const bounds = ref.current.getBoundingClientRect()
      boundsRef.current = {
        x: bounds.left + bounds.width / 2,
        y: (options.pageOffset ? (bounds.top + getScrollY()) : bounds.top) + bounds.height / 2,
        pageOffset: options.pageOffset
      }
    }
  }, [])

  useWindowResize(useDebouncedCallback(updateElementPosition, 200, []))
  useEffect(updateElementPosition)
  return ref
}

const Field = ({ label, value }) => {
  const styles = useStyles()
  return (
    <div className={styles.field}>
      {label && <span className={styles.label}>{label}</span>}
      <span className={styles.value}>{value}</span>
    </div>
  )
}

const Hotspot = ({ productVariant, top, left, asLink }) => {
  const styles = useStyles()
  const localsRef = useRef({ hovering: false })
  const stickyRef = useStickyCursorPosition()
  const snap = useSnapshot()
  const siteData = getSiteData(snap)
  const colorLabel = siteData?.colorLabel || 'Color'
  const enquireLabel = siteData?.enquireLabel || 'Enquire'
  const priceLabel = siteData?.priceLabel || 'Price'
  const collectionLabel = siteData?.collectionLabel || 'Collection'
  const router = useRouter()

  const popupRef = useRef()
  const spotRef = useRef()
  const [hoverRef, hovering] = useHover(primaryInput !== 'touch')
  const [open, setOpen] = useState(false)
  const [showMoreLink, setShowMoreLink] = useState(false)
  const { productEnquiry, title, url, colorVariant, category, productType, productId, variantId } = useProductVariantDetails({
    product: productVariant?.product,
    colorVariantId: productVariant?.variantId,
    variantId: sortBy(productVariant?.product?.product?.variants.filter(x => x.calculated_price > 0), ['calculated_price'])?.find((x) => {
      return x.option_values.find(option => option.id === productVariant?.variantId)
    })?.id
  })

  const ref = useComposeRefs(stickyRef, hoverRef)

  useEffect(() => {
    localsRef.current.hovering = hovering
    const show = open || hovering
    if (popupRef.current) {
      gsap.set(popupRef.current, { x: 0 })
      gsap.to(popupRef.current, { opacity: show ? 1 : 0, duration: 0.25, ease: 'sine.inOut' })
      gsap.to(spotRef.current, { scale: show ? 1.5 : 1, backgroundColor: show ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)', duration: 0.25, ease: 'sine.inOut' })
      const rect = popupRef.current.getBoundingClientRect()
      const isPopupOffScreen = (rect.left + rect.width) > window.innerWidth
      if (isPopupOffScreen) {
        gsap.set(popupRef.current, { x: -(rect.width + theme.spacing(7)) })
      }
    }
  }, [hovering, open])

  const onClick = useCallback((e) => {
    if (primaryInput === 'touch') {
      e.preventDefault()
    }
  }, [])

  const onTouchStart = useCallback((e) => {
    if (primaryInput === 'touch') {
      setOpen(value => !value)
    }
  }, [])

  const onPopupClick = useCallback((e) => {
    if (primaryInput === 'touch') {
      router.push(url)
      e.preventDefault()
    }
  }, [router, url])

  const linkRef = useRef()
  const composedRefs = useComposeRefs(ref, linkRef)
  useOnClickOutside(linkRef, useCallback(() => {
    if (primaryInput === 'touch') {
      setOpen(false)
    }
  }, []), primaryInput === 'touch' && open)

  useEffect(() => {
    setShowMoreLink(primaryInput === 'touch')
  }, [])

  return (
    <Link ref={composedRefs} to={asLink ? url : null} className={styles.hotspot} style={{ top: `${top}%`, left: `${left}%` }} onClick={onClick} onTouchStart={onTouchStart}>
      <span className={styles.spot} ref={spotRef} />
      <div className={styles.popup} ref={popupRef} onClick={onPopupClick}>
        <Field label={productType} value={title} />
        <Field label={colorLabel} value={colorVariant?.label} />
        <Field label={priceLabel} value={productEnquiry ? <span>{enquireLabel}</span> : <ProductPrice prefix='From ' productId={productId} variantId={variantId} />} />
        <Field label={collectionLabel} value={category?.name} />
        {showMoreLink && (
          <div className={cn(styles.field, styles.viewProduct)}>
            <span>View Product</span>
          </div>
        )}
      </div>
    </Link>
  )
}

const ImageWithHotspots = forwardRef(({ className, imageClassName, image, hotspots, aspect, mobileAspect, hostspotAsLink = true }, ref) => {
  return (
    <div className={className} ref={ref}>
      <ResponsiveImage className={imageClassName} image={image} style={{ overflow: 'visible' }} aspect={aspect} mobileAspect={mobileAspect}>
        {hotspots && hotspots.map((hotspot, i) => (
          <Hotspot key={i} {...hotspot} asLink={hostspotAsLink} />
        ))}
      </ResponsiveImage>
    </div>
  )
})

ImageWithHotspots.displayName = 'ImageWithHotspots'

export default ImageWithHotspots

const useStyles = createUseStyles({
  hotspot: {
    position: 'absolute',
    border: 'none',
    width: theme.spacingPx(3),
    height: theme.spacingPx(3),
    cursor: 'pointer',
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.imageContent
  },
  spot: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderColor: theme.colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,0)'
  },
  popup: {
    position: 'absolute',
    top: 0,
    left: `calc(100% + ${theme.spacing(2)}px)`,
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    padding: theme.spacingPx(1),
    opacity: 0,
    pointerEvents: primaryInput === 'touch' ? 'auto' : 'none',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacingPx(2)
    }
  },
  field: {
    fontSize: 8,
    display: 'flex',
    padding: '4px 0',
    borderBottom: '1px solid rgba(0,0,0,0.3)',
    '&:first-child': {
      paddingTop: 0
    },
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 10
    }
  },
  label: {
    opacity: 0.5,
    width: 40,
    marginRight: theme.spacingPx(1),
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: 60
    }
  },
  value: {
    whiteSpace: 'nowrap'
  },
  viewProduct: {
    justifyContent: 'center',
    textDecoration: 'underline'
  }
})
