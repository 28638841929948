import get from 'lodash/get'
import Link from './StyledLink'
import ResponsiveImage from './ResponsiveImage'
import RichContent from './RichContent'
import Section from './Section'
import { theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'

export default function LandingPage ({ page, preview }) {
  const styles = useStyles()
  const title = get(page, ['title'])
  const introduction = get(page, ['introduction'])
  const featuredImage = get(page, ['featuredImage'])
  const link = get(page, ['link'])
  return (
    <Section className={styles.section} fullWidth>
      <ResponsiveImage image={featuredImage} className={styles.image} />
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        {introduction && (
          <RichContent content={introduction} />
        )}
        {link && (
          <Link link={link} className={styles.link} />
        )}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    height: '100vh',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      ...theme.gridStyle,
      overflow: 'hidden'
    }
  },
  image: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      position: 'absolute !important',
      top: 0,
      right: 0
    }
  },
  content: {
    position: 'relative',
    color: theme.colors.black,
    zIndex: theme.zIndex.imageContent,
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 160,
      right: 0,
      gridColumn: '5 / span 6',
      maxWidth: 460,
      color: theme.colors.white
    },
    [theme.breakpoints.up('lg')]: {
      // gridColumn: '5 / span 6',
      maxWidth: 540
    }
  },
  title: {
    fontSize: 32,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
      fontSize: 48
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(4),
      fontSize: 56
    }
  },
  link: {
    color: 'currentColor',
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(4)
    }
  }
})
