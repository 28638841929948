import cn from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import get from 'lodash/get'
import Accordion from '../Accordion'
import { setShopFilters } from '../../store/shopFiltersSlice'
import { createUseStyles } from '../../helpers/createStyles'
import { theme } from '../../styles/theme'
import { sortBy } from 'lodash'

const RoomStyleFilter = ({ filterState, options }) => {
  const styles = useStyles()

  const selectedRoomFilter = get(filterState, ['room', '0'])
  const currentFilters = filterState.roomStyle

  const onClick = useCallback((option, optionIsActive) => {
    if (optionIsActive) {
      const prevFilterState = filterState.roomStyle
      const newFilterState = prevFilterState.filter(item => item._id !== option._id)
      const newState = {
        ...filterState,
        roomStyle: [
          ...newFilterState
        ]
      }
      setShopFilters(newState)
    } else {
      // Option is not already active, add to state
      setShopFilters({
        ...filterState,
        roomStyle: [
          ...filterState.roomStyle,
          option
        ]
      })
    }
  }, [filterState])

  const [expanded, setExpanded] = useState(false)

  const matchingOptions = useMemo(() => {
    const results = options.filter(o => o.room_tag._id === selectedRoomFilter?._id)
    return sortBy(results, ({ name, order }) => {
      return order || name
    })
  }, [options, selectedRoomFilter])

  useEffect(() => {
    if (matchingOptions.length > 0) {
      const timeout = setTimeout(() => {
        setExpanded(true)
      }, 0)
      return () => clearTimeout(timeout)
    } else {
      setExpanded(false)
    }
  }, [matchingOptions.length])

  if (!selectedRoomFilter || matchingOptions.length < 1) return null

  return (
    <div className={styles.roomStyleFilter}>
      <Accordion
        className={styles.accordion}
        label={`${selectedRoomFilter.name} Style`}
        expanded={expanded}
        setExpanded={setExpanded}
      >
        <div className={styles.roomStyleOptions}>
          {matchingOptions.map(option => {
            const { icon, name } = option
            const active = currentFilters.find(filter => filter._id === option._id)
            return (
              <button
                key={option._id}
                className={cn(styles.option, { active })}
                onClick={() => onClick(option, active)}
              >
                <div className={styles.iconContainer}>
                  {icon?.asset?.url && (
                    <img className={cn(styles.icon, { active })} alt={name} src={icon.asset.url} />
                  )}
                </div>
                <span className={cn(styles.label, 'is-caption')}>{name}</span>
              </button>
            )
          })}
        </div>
      </Accordion>
    </div>
  )
}

const useStyles = createUseStyles({
  roomStyleFilter: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  },
  roomStyleOptions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: theme.spacingPx(1),
    rowGap: theme.spacingPx(2),
    paddingTop: theme.spacingPx(2),
    paddingBottom: theme.spacingPx(2)
  },
  option: {
    gridColumn: 'span 1',
    padding: '0',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    opacity: 0.5,
    width: 'auto',
    height: 60,
    maxWidth: 60,
    marginBottom: theme.spacingPx(2),
    '&.active': {
      opacity: 1
    }
  },
  label: {
    fontSize: '9px',
    lineHeight: '11px',
    textAlign: 'center',
    letterSpacing: '0.12em'
  }
})

export default RoomStyleFilter
