import React, { useCallback, useEffect, useRef } from 'react'
import { theme } from '../../../styles/theme'
import cn from 'classnames'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { createUseStyles } from '../../../helpers/createStyles'
import ProductPrice from '../../ProductPrice'
import usePrice from '../../../hooks/usePrice'
import toArray from 'lodash/toArray'
import forEach from 'lodash/forEach'
import afterFrame from '../../../helpers/afterFrame'
import { getSiteData } from '../../../store/layoutSlice'
import useSnapshot from '../../../store/useSnapshot'

export default function ProductMeta ({ productEnquiry, productId, variantId, className, category, colorsCount, sizesCount, colorVariant, colorVariantId }) {
  const styles = useStyles()
  const categoryRef = useRef()
  const innerRef = useRef()
  const snap = useSnapshot()
  const colorLabel = getSiteData(snap)?.colorLabel || 'Color'
  const enquireLabel = getSiteData(snap)?.enquireLabel || 'Enquire'
  const sizeLabel = getSiteData(snap)?.sizeLabel || 'Size'

  const onResize = useCallback(() => {
    afterFrame(() => {
      if (innerRef.current && ref.current) {
        const items = toArray(innerRef.current.children)
        forEach(items, item => {
          item.style.display = 'block'
        })

        // The first thing to go if there is no room is the category
        if (ref.current.clientWidth < (ref.current.scrollWidth - 1)) {
          categoryRef.current.style.display = 'none'
        }
        // We then want to try and hide the last x items if needed
        for (let i = 0; i < innerRef.current.children.length; i++) {
          if (ref.current.clientWidth >= (ref.current.scrollWidth - 1)) {
            break
          }
          if (i !== 1) { // Dont hide the price ever
            innerRef.current.children[i].style.display = 'none'
          }
        }
      }
    })
  }, [])

  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshOptions: { leading: false, trailing: true },
    refreshRate: 500,
    onResize
  })

  const { price } = usePrice(productId, variantId, colorVariant?.bigCommerceId)
  // We need to trigger the resize when the prices changes
  useEffect(onResize, [price])

  return (
    <div className={cn(className, styles.container)} ref={ref}>
      <div className={styles.inner} ref={innerRef}>
        <span ref={categoryRef}>{category?.name}</span>
        {!productEnquiry && <ProductPrice prefix='From ' productId={productId} variantId={variantId} colorVariantId={colorVariantId}/>}
        {productEnquiry && <span>{enquireLabel}</span>}
        {sizesCount > 0 && (
          <span>{sizesCount} {sizeLabel}{sizesCount > 1 ? 's' : ''}</span>
        )}
        {colorsCount > 0 && (
          <span>{colorsCount} {colorLabel}{colorsCount > 1 ? 's' : ''}</span>
        )}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    overflow: 'hidden'
  },
  inner: {
    fontSize: 10,
    lineHeight: 1.2,
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      whiteSpace: 'nowrap',
      display: 'block'
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(4)
    }
  }
})
