import Section from '../Section'
import ResponsiveImage from '../ResponsiveImage'
import { theme } from '../../styles/theme'
import { createUseStyles } from '../../helpers/createStyles'
import RichContent from '../RichContent'
import cn from 'classnames'

export default function PageHero ({ data, summary }) {
  const { title, image, copy } = data
  const styles = useStyles()

  return (
    <Section grid>
      <div className={cn(styles.content, { contained: copy, noImage: !image })}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {copy && <RichContent content={copy} className={styles.copy} />}
      </div>
      {image && (
        <ResponsiveImage
          image={image}
          loading='eager'
          aspect={1440 / 900}
          mobileAspect={375 / 626}
          showPreview={!summary}
          className={styles.image}
        />
      )}
    </Section>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: `${theme.spacing(14)}px 0`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(26)}px 0`,
      '& h1': {
        maxWidth: '20em'
      }
    },
    '&.noImage': {
      padding: `${theme.spacing(14)}px 0 ${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        height: 386,
        padding: `${theme.spacing(26)}px 0 ${theme.spacing(8)}px`
      }
    },
    gridColumn: '1 / -1',
    '&.contained': {
      padding: `${theme.spacing(14)}px 0 ${theme.spacing(4)}px`,
      gridColumn: '1 / -1',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(26)}px 0 ${theme.spacing(14)}px`,
        gridColumn: '5 / span 3'
      }
    }
  },
  copy: {
    marginTop: theme.spacing(4)
  },
  image: {
    gridColumn: '1 / -1'
  }
})
