import { keyframes } from 'goober'
import PropTypes from 'prop-types'
import * as Accordion from '@radix-ui/react-accordion'
import { createUseStyles } from '../../helpers/createStyles'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import Section from '../Section'
import PlusMinus from '../PlusMinus'
import useScrollTrigger from '../../hooks/useScrollTrigger'

const QuestionAnswerList = ({ data: { questions }, page: { title } }) => {
  const styles = useStyles()
  if (!questions || !questions.length) {
    return null
  }

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.querySelectorAll(`.${styles.title}, .${styles.item}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults }, '+=0.25')
    }
  )

  return (
    <Section grid className={styles.root} ref={sectionRef}>
      <h1 className={styles.title}>{title}</h1>
      <Accordion.Root className={styles.accordion} type='multiple'>
        {questions.map(question => (
          <Accordion.Item
            className={styles.item}
            value={question._key}
            key={question._key}
          >
            <Accordion.Header className={styles.header} asChild>
              <Accordion.Trigger>
                <p className={styles.question}>{question.question}</p>
                <PlusMinus />
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className={styles.content}>
              <RichContent
                className={styles.answer}
                content={question.answer}
              />
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </Section>
  )
}

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`

const useStyles = createUseStyles({
  root: {
    paddingTop: theme.headerSize.sm,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.headerSize.md
    }
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(21),
      marginBottom: theme.spacing(16)
    }
  },
  accordion: {
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 8'
    }
  },
  item: {
    borderTop: '1px solid var(--border)',
    '&:last-child': {
      borderBottom: '1px solid var(--border)'
    }
  },
  header: {
    height: 64,
    width: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .plus-minus-icon': {
      flexShrink: '0',
      marginLeft: theme.spacing(1)
    }
  },
  question: {
    marginBottom: 0,
    fontSize: 16,
    lineHeight: '2em',
    textAlign: 'left'
  },
  answer: {
    paddingBottom: theme.spacing(11),
    p: {
      fontSize: 16,
      lineHeight: '2em'
    }
  },
  content: {
    overflow: 'hidden',
    '&[data-state="open"]': {
      animation: `${slideDown} 300ms ${theme.eases.easeInOut}`
    },
    '&[data-state="closed"]': {
      animation: `${slideUp} 300ms ${theme.eases.easeInOut}`
    }
  }
})

QuestionAnswerList.propTypes = {
  data: PropTypes.shape({
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: PropTypes.array.isRequired
      })
    )
  }).isRequired,
  page: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired
}

export default QuestionAnswerList
