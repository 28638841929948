import cn from 'classnames'
import { theme } from '../../styles/theme'
import Section from '../Section'
import Link from '../Link'
import Button from '../Button'
import LetterMark from '../../icons/lettermark.svg'
import { createUseStyles } from '../../helpers/createStyles'
import { getPageData, getSiteData } from '../../store/layoutSlice'
import useSnapshot from '../../store/useSnapshot'
import get from 'lodash/get'
import { openSalesPopup } from '../../store/salesPopup'

import { setUnitSystemToImperial, setUnitSystemToMetric, getIsImperialUnitSystem } from '../../store/unitSystemSlice'
import useScrollTrigger from '../../hooks/useScrollTrigger'

const FooterLinks = ({ className, links }) => {
  const styles = useStyles()
  return (
    <ul className={cn(className || null, styles.linksList)}>
      {links?.map((link, i) => (
        <li key={i}>
          <Link className={cn(styles.link, 'styled-link')} link={link} />
        </li>
      ))}
    </ul>
  )
}

const Footer = ({ pageThemeColor }) => {
  const styles = useStyles()
  const snap = useSnapshot()
  const isImperialUnits = getIsImperialUnitSystem(snap)
  const { footer, socialLinks } = getSiteData(snap)
  const locale = getPageData(snap)?.page?.locale
  const {
    footerLinks,
    acknowledgement_of_country: acknowledgementOfCountry,
    bcorpCopy,
    bcorpLogo,
    newsletterEmailPlaceholder
  } = footer
  const {
    linksLeft,
    linksRight,
    legalPages
  } = footerLinks || {}

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.querySelectorAll(`.${styles.letterMarkSvg}, .${styles.newsletterWrapper}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults })
      tl.from(ref.current.querySelectorAll(`.${styles.linkColumn} li`), { y: 40, opacity: 0, stagger: 0.015, ...defaults }, '-=0.6')
      tl.from(ref.current.querySelectorAll(`.${styles.certBCorp}, .${styles.acknowledgementWrap}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults }, '-=0.6')
      tl.from(ref.current.querySelectorAll(`.${styles.bottomContent}`), { opacity: 0, stagger: 0.1, ...defaults }, '-=0.6')
    }
  )

  const logoAspect = get(bcorpLogo, ['asset', 'metadata', 'dimensions', 'aspectRatio'])
  const isBgDark = (pageThemeColor === theme.colors.pageTheme.moss || pageThemeColor === theme.colors.pageTheme.mushroom || pageThemeColor === theme.colors.pageTheme.gunsmoke)

  return (
    <Section tag='footer' noBottomMargin className={styles.footer} grid ref={sectionRef}>
      <LetterMark className={styles.letterMarkSvg} />
      <div className={styles.newsletterWrapper}>
        <Button className={cn(styles.newsletterCta, isBgDark && styles.newsletterCtaDark)} onClick={() => openSalesPopup()}><div className={styles.buttonCopy}><div className={styles.desktopNewsletterSignupCopy}>{newsletterEmailPlaceholder}</div><div>Sign up</div></div></Button>
        <p className={styles.mobileNewsletterSignupCopy}>{newsletterEmailPlaceholder}</p>
      </div>
      <div className={cn(styles.linkColumn, styles.linkColumnLeft)}>
        <FooterLinks links={linksLeft} />
      </div>
      <div className={cn(styles.linkColumn, styles.linkColumnRight)}>
        <FooterLinks links={linksRight} />
      </div>
      <div className={styles.certBCorp}>
        <div>{bcorpCopy}</div>
        {bcorpLogo && <img className={styles.bcorpLogo} src={bcorpLogo.asset.url} alt='B-Corp Logo' height={32 / (logoAspect || 1)} width={32} />}
      </div>

      {acknowledgementOfCountry &&
        <div className={styles.acknowledgementWrap}>
          <p className={styles.acknowledgementOfCountry}>
            {acknowledgementOfCountry}
          </p>
        </div>}

      <div className={cn(styles.grid, styles.bottomContent)}>
        <div className={styles.legalPages}>
          <FooterLinks
            className={cn(styles.bottomLinks, 'is-caption')}
            links={legalPages}
          />
        </div>
        <div className={styles.socials}>
          <FooterLinks
            className={cn(styles.bottomLinks, 'is-caption')}
            links={socialLinks}
          />
        </div>
        <div className={cn('is-caption', styles.unitSystemAndLocation)}>
          Size:
          <button role='button' className={cn('is-caption', styles.unitToggle, isImperialUnits && styles.active)} onClick={() => setUnitSystemToImperial()}>IMPERIAL</button>
          <button role='button' className={cn('is-caption', styles.unitToggle, !isImperialUnits && styles.active)} onClick={() => setUnitSystemToMetric()}>METRIC</button>
          <div className={cn(styles.locationPicker, 'is-caption')}>
            Current Location: {locale}
          </div>
        </div>
      </div>
    </Section>

  )
}

const useStyles = createUseStyles({
  footer: {
  },
  mainContent: {
    marginTop: `${theme.spacing(20)}px`,
    marginBottom: `${theme.spacing(8)}px`,
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap'
    }
  },
  linkColumn: {
    flex: 1,
    display: 'block',
    lineHeight: '2.1em',
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& li': {
        marginBottom: `${theme.spacing(1)}px`,
        '&:last-child': {
          marginBottom: 0
        }
      }
    }
  },
  linkColumnLeft: {
    textAlign: 'left',
    display: 'block',
    gridColumn: '5 / span 2',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / span 4'
    }
  },
  linkColumnRight: {
    display: 'block',
    gridColumn: '7 / span 2',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '-5 / -1',
      textAlign: 'right'
    }
  },
  certBCorp: {
    gridColumn: '10 / -1',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
      textAlign: 'center',
      justifySelf: 'center',
      marginTop: theme.spacing(8)
    },
    '& img': {
      marginTop: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3)
      }
    }
  },
  linksLeft: {
    display: 'block'
  },
  letterMark: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: `${theme.spacing(8)}px`
  },
  caption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.7
  },
  letterMarkSvg: {
    width: '64px',
    display: 'block',
    gridColumn: '1 / span 4',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
      justifySelf: 'center',
      marginBottom: theme.spacing(7)
    }
  },
  newsletterWrapper: {
    gridColumn: '5 / -1',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1'
    }
  },
  newsletterCta: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    '& span': {
      width: '100%',
      textAlign: 'left',
      justifyContent: 'space-between'
    }
  },
  newsletterCtaDark: {
    border: '1px solid rgba(0, 0, 0, 0.25) !important'
  },
  desktopNewsletterSignupCopy: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  mobileNewsletterSignupCopy: {
    opacity: 0.5,
    marginTop: theme.spacing(2),
    marginBottom: 0,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  buttonCopy: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    }
  },
  linksRight: {
    display: 'block'
  },
  acknowledgementWrap: {
    marginTop: theme.spacing(8),
    gridColumn: '5 / span 4',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
      textAlign: 'center'
    }
  },
  acknowledgementOfCountry: {
    marginBottom: 0,
    opacity: 0.7,
    maxWidth: '31em',
    letterSpacing: '0.02em',
    [theme.breakpoints.down('sm')]: {
      opacity: 0.5,
      maxWidth: '23em',
      margin: 'auto'
    }
  },
  grid: {
    ...theme.gridStyle
  },
  bottomContent: {
    gridColumn: '1/ -1',
    gridGap: 0,
    marginTop: `${theme.spacing(8.5)}px`,
    padding: `0 0 ${theme.spacing(4)}px`,
    '& > *': {
      padding: `${theme.spacing(1.5)}px 0`,
      borderTop: '1px solid #00000010',
      '&:last-child': {
        borderBottom: '1px solid #00000010'
      }
    },
    [theme.breakpoints.up('md')]: {
      marginTop: `${theme.spacing(10)}px`,
      padding: `${theme.spacing(5)}px 0`,
      borderTop: '1px solid #00000010',
      '& > *': {
        border: 'none !important',
        padding: 0,
        flex: 1
      }
    }
  },
  bottomLinks: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    letterSpacing: '0.12em',
    fontSize: '9px',
    '& li': {
      marginRight: `${theme.spacing(4)}px`,
      '&:last-child': {
        marginRight: 0
      },
      [theme.breakpoints.up('md')]: {
        marginRight: `${theme.spacing(5)}px`
      }
    }
  },
  socials: {
    order: 1,
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      order: 'unset',
      gridColumn: '5 / span 4'
    },
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-evenly'
      }
    }
  },
  unitSystemAndLocation: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    letterSpacing: '0.12em',
    fontSize: '9px',
    gridColumn: '1 / -1',
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 'unset',
      gridColumn: '10 / span 3',
      justifyContent: 'flex-start'
    }
  },
  unitToggle: {
    opacity: 0.5,
    letterSpacing: '0.12em',
    fontSize: '9px',
    padding: 0,
    marginLeft: 8
  },
  active: {
    opacity: 1
  },
  legalPages: {
    order: 2,
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      order: 'unset',
      justifyContent: 'flex-start',
      gridColumn: '1 / span 4'
    }
  },
  locationPicker: {
    display: 'flex',
    justifyContent: 'center',
    letterSpacing: '0.12em',
    fontSize: '9px',
    marginLeft: 32,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    }
  }
})

export default Footer
