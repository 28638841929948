// import { useCallback, useState } from 'react'
import { useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'
import useSnapshot from '../store/useSnapshot'
// import { getSiteData, getPageData, getSelectedCustomColor } from '../store/layoutSlice'
import { getSiteData, getPageData } from '../store/layoutSlice'
import InputContainer from './TradeRegistration/InputContainer'
import Button from './Button'

const EnquiryForm = ({ selectedColor, disclaimerMessage, submitButtonLabel, successMessage }) => {
  const styles = useStyles()
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      product: '',
      color: '',
      width: '',
      length: ''
    }
  })

  const snap = useSnapshot()
  const locale = getSiteData(snap).locale
  const product = getPageData(snap)?.page?.product?.name
  const { enquiryEmail } = getSiteData(snap)
  // const [loading, setLoading] = useState(false)
  // const [error, setError] = useState(null)
  // const [success, setSuccess] = useState(false)
  const onSubmit = useCallback(async (data) => {
    // setLoading(true)
    // console.log('data', JSON.stringify({
    //   ...data,
    //   product,
    //   color: getSelectedCustomColor(snap)?.label,
    //   locale
    // }))
    // const response = await window.fetch('/api/enquiry', {
    //   method: 'post',
    //   body: JSON.stringify({
    //     ...data,
    //     product,
    //     color: getSelectedCustomColor(snap)?.label,
    //     locale
    //   })
    // })
    // const klaviyoResponse = await response.json()
    // setLoading(false)
    // if (response.ok) {
    //   setSuccess(true)
    // } else {
    //   setError(klaviyoResponse.error.detail || 'Oops, something went wrong')
    // }
    window.location.href = `mailto:${enquiryEmail}?subject=${window.encodeURIComponent(`${product} Enquiry`)}&body=Product:%20${window.encodeURIComponent(product)}%0AColo${locale !== 'en-AU' ? '' : 'u'}r:%20${window.encodeURIComponent(selectedColor)}%0AFirst%20Name:%20${window.encodeURIComponent(data?.first_name)}%0ALast%20Name:%20${window.encodeURIComponent(data?.last_name)}%0AEmail:%20${window.encodeURIComponent(data?.email)}%0APhone:%20${window.encodeURIComponent(data?.phone)}%0ACustom%20Width%20(${locale !== 'en-AU' ? 'feet' : 'metres'}):%20${window.encodeURIComponent(data?.width)}%0ACustom%20Length%20(${locale !== 'en-AU' ? 'feet' : 'metres'}):%20${window.encodeURIComponent(data?.length)}`
  }, [locale, selectedColor])

  return (
    <div className={styles.root}>
      <FormProvider {...methods}>
        {/* {success && (
          <div className={styles.successMessageContainer}>
            <h2>{successMessage}</h2>
          </div>
        )} */}
        {/* {!success && ( */}
          <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.formContainer}>
            <InputContainer name='first_name' required placeholder='First Name' />
            <InputContainer name='last_name' required placeholder='Last Name' />
            <InputContainer name='email' type='email' required placeholder='Email' />
            <InputContainer name='phone' type='phone' required placeholder='Phone' />
            <InputContainer name='width' placeholder={`Custom Width (${locale !== 'en-AU' ? 'feet' : 'metres'})`} />
            <InputContainer name='length' placeholder={`Custom Length (${locale !== 'en-AU' ? 'feet' : 'metres'})`} />
            {/* <label htmlFor='optout' className={styles.checkboxLabel}>
              <InputContainer containerClassName={styles.checkboxInput} name='optout' type='checkbox' />
              {disclaimerMessage && (
                <span>{disclaimerMessage}</span>
              )}
            </label> */}
            {/* {error && <div className={styles.error}>{error}</div>} */}
            {/* <Button size='lg' className={styles.signUpBtn} disabled={loading} type='submit'>{loading ? 'Sending...' : submitButtonLabel}</Button> */}
            <Button size='lg' className={styles.signUpBtn} type='submit'>{submitButtonLabel}</Button>
          </form>
        {/* )} */}
      </FormProvider>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    flexShrink: 1
  },
  successMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
    height: '100%',

    '> *': {
      fontSize: 30
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 1,
    flexShrink: 1
  },
  checkboxLabel: {
    display: 'flex',
    gap: 12,
    marginBottom: 24
  },
  checkboxInput: {
    textAlign: 'left',
    '> input': {
      margin: 0
    },
    '> span': {
      display: 'none'
    }
  },
  error: {
    color: theme.colors.error,
    margin: '0 !important',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(2.5)}px`
  },
  signUpBtn: {
    width: '100%',
    marginBottom: theme.spacing(3)
  }
})

export default EnquiryForm
