import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import imageUrlBuilder from '@sanity/image-url'
import forEach from 'lodash/forEach'

export const SANITY_REF_PATTERN = /^image-([a-f\d]+)-(\d+x\d+)-(\w+)$/

export const builder = imageUrlBuilder(null)
  .projectId(process.env.SANITY_PROJECT_ID)
  .dataset(process.env.SANITY_PROJECT_DATASET)

function transformPalette (palette) {
  const schemes = pickBy(palette, { _type: 'sanity.imagePaletteSwatch' })
  return mapValues(schemes, scheme => pickBy(scheme, (value, key) => key !== '_type'))
}

const getAspect = (asset, vector, width, targetAspect) => {
  const sourceAspect = get(asset, ['metadata', 'dimensions', 'aspectRatio'])
  const resolvedAspect = targetAspect || sourceAspect
  const needsCrop = !vector && resolvedAspect && resolvedAspect !== sourceAspect
  return {
    width,
    needsCrop: needsCrop,
    aspect: resolvedAspect
  }
}

const addOriginalFilenameToUrl = (url, originalFilename) => {
  if (!originalFilename) return url
  const u = new URL(url)
  u.pathname = `${u.pathname}/${originalFilename}`
  return u.toString()
}

export const MOBILE_WIDTHS = [320, 420, 640, 1024]
const DEFAULT_WIDTHS = [320, 420, 640, 1024, 1280, 1600, 1920, 2240, 2560, 3200, 7680]

export default function transform (image, targetAspect, background = 'F0EEE9', WIDTHS = DEFAULT_WIDTHS) {
  if (!image || !image.asset) {
    return
  }

  const { asset } = image

  const imageMeta = parseImageRef(asset._id || asset._ref)

  const vector = imageMeta.format === 'svg'

  const hasAlpha = get(asset, ['metadata', 'hasAlpha'])
  const opaque = get(asset, ['metadata', 'isOpaque'])
  const width = get(asset, ['metadata', 'dimensions', 'width']) || imageMeta.dimensions.width
  const height = get(asset, ['metadata', 'dimensions', 'height']) || imageMeta.dimensions.height
  let sourceAspect = get(asset, ['metadata', 'dimensions', 'aspectRatio']) || (imageMeta.dimensions.width / imageMeta.dimensions.height)
  const palette = transformPalette(get(image, ['metadata', 'palette']))
  const sizes = []

  if (image.crop) {
    // If we are cropping the image then we need to change the source aspect
    const { left, right, top, bottom } = image.crop
    const cropWidth = imageMeta.dimensions.width * (1 - (left + right))
    const cropHeight = imageMeta.dimensions.height * (1 - (top + bottom))
    sourceAspect = cropWidth / cropHeight
  }

  const result = {
    sourceAspect,
    hasAlpha,
    opaque,
    vector,
    palette,
    sizes
  }

  let widths = WIDTHS
  // If the image is smaller then the smallest width, then just use the image width
  if (WIDTHS[0] > imageMeta.dimensions.width) {
    widths = [imageMeta.dimensions.width]
  }
  const aspects = widths.map(width => ({
    ...getAspect(asset, vector, width, targetAspect || sourceAspect)
  }))

  if (image.alt) {
    result.alt = image.alt
  }
  if (image.caption) {
    result.title = image.caption
  }
  if (image.attribution) {
    result.credit = image.attribution
  }
  if (image.hotspot) {
    const { _type: type, ...hotspot } = image.hotspot
    result.hotspot = {
      type,
      ...hotspot
    }
  }
  if (image.crop) {
    const { _type: type, ...crop } = image.crop
    result.crop = {
      type,
      ...crop
    }
  }

  if (vector) {
    sizes.push({
      width,
      height,
      url: addOriginalFilenameToUrl(builder.image(image.asset).url(), image.asset.originalFilename)
    })
  } else {
    const maxWidth = width
    let resizeBuilder = builder.image(image.asset)

    forEach(aspects, ({ needsCrop, aspect, width: w, hasAlpha }) => {
      if (w <= maxWidth) {
        if (needsCrop) {
          if (image.hotspot) {
            resizeBuilder = resizeBuilder.fit('crop').crop('focalpoint').focalPoint(image.hotspot.x, image.hotspot.y)
          } else {
            resizeBuilder = resizeBuilder.fit('min')
          }
        }
        let sizeBuilder = resizeBuilder.width(w)
        if (needsCrop) {
          sizeBuilder = sizeBuilder.height(Math.round(w / aspect))
        }
        sizeBuilder = sizeBuilder
          .auto('format')
        if (background !== 'transparent') {
          sizeBuilder = sizeBuilder.bg(background)
        }
        const size = {
          width: w,
          height: Math.round(w / aspect),
          url: addOriginalFilenameToUrl(sizeBuilder.url(), image.asset.originalFilename)
        }

        sizes.push(size)
      }
    })
  }

  return result
}

export const parseImageRef = (id) => {
  try {
    const [, assetId, dimensions, format] = SANITY_REF_PATTERN.exec(id)
    const [width, height] = dimensions.split('x').map((v) => parseInt(v, 10))

    return {
      assetId,
      dimensions: { width, height },
      format
    }
  } catch {
    throw new Error(`Could not parse image ID "${id}"`)
  }
}
