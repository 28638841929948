import { useEffect } from 'react'

function useOnClickOutside (ref, handler, enabled) {
  useEffect(() => {
    if (enabled) {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener, { passive: true })
      document.addEventListener('touchstart', listener, { passive: true })
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [ref, handler, enabled])
}

export default useOnClickOutside
