import cn from 'classnames'
import { useFormContext } from 'react-hook-form'
import { createUseStyles } from '../../helpers/createStyles'
import { theme } from '../../styles/theme'
import get from 'lodash/get'

const InputContainer = ({ name, className, lightTheme, InputComponent = 'input', required, validation = {}, validationMessages = {}, containerClassName, ...rest }) => {
  const styles = useStyles()
  const { register, formState: { errors } } = useFormContext()
  const errorMessage = get(validationMessages, [errors[name]?.type], 'This field is required')
  rest.placeholder += required ? '*' : ''
  return (
    <div className={containerClassName}>
      <InputComponent {...register(name, { required, ...validation })} className={cn(className, styles.input, lightTheme ? styles.lightInput : styles.darkInput)} {...rest} />
      <span className={cn(styles.error, { show: errors[name] })}>
        {errorMessage}
      </span>
    </div>
  )
}

export default InputContainer

export const useStyles = createUseStyles({
  input: {
    fontFamily: theme.fonts.body,
    width: '100%',
    fontSize: 14,
    padding: `${theme.spacing(1)}px 0`,
    backgroundColor: 'transparent',
    borderWidth: '0 0 1px 0',
    outline: 'none',
    marginBottom: theme.spacing(0.5),
    borderRadius: 0,
    'select&': {
      appearance: 'none',
      cursor: 'pointer'
    }
  },
  lightInput: {
    color: theme.colors.pageTheme.default.background, // milk
    borderBottom: '1px solid rgba(249, 252, 248, 0.5)', // milk with 0.5 opacity
    '&::placeholder': {
      color: theme.colors.pageTheme.default.background,
      opacity: 0.8
    },
    '&:focus::placeholder': {
      color: theme.colors.pageTheme.default.background,
      opacity: 0.6
    },
    '&:focus': {
      borderBottom: `1px solid ${theme.colors.pageTheme.default.background}`
    },
    'select&': {
      background: `url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m1 1 5 5 5-5' stroke='${theme.colors.pageTheme.default.background.replace('#', '%23')}'/></svg>") no-repeat`,
      backgroundSize: '13px 6.5px',
      backgroundPositionX: '100%',
      backgroundPositionY: '50%'
    }
  },
  darkInput: {
    color: theme.colors.text,
    borderBottom: `1px solid ${theme.colors.border}`,
    'select&': {
      background: `url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m1 1 5 5 5-5' stroke='${theme.colors.pageTheme.default.foreground.replace('#', '%23')}'/></svg>") no-repeat`,
      backgroundSize: '13px 6.5px',
      backgroundPositionX: '100%',
      backgroundPositionY: '50%'
    }
  },
  error: {
    fontSize: 10,
    color: theme.colors.error,
    display: 'block',
    visibility: 'hidden',
    marginBottom: theme.spacing(2),
    height: '1.2em',
    textAlign: 'left',
    '&.show': {
      visibility: 'visible'
    }
  }
})
