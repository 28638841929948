import ImageWithHotspots from '../../ImageWithHotspots'
import first from 'lodash/first'
import get from 'lodash/get'
import { useProductVariantDetails } from '../../../hooks/useProductVariantDetails'
import Link from '../../Link'

export default function Tile ({ className, tile, aspect }) {
  const { image } = tile
  const hasMoreThenOneHotspot = get(image.hotspots, ['length']) > 1
  const firstProductVariant = get(first(image.hotspots), ['productVariant'], {})
  const { url } = useProductVariantDetails({ product: firstProductVariant?.product, colorVariantId: firstProductVariant.variantId })
  return (
    <article className={className}>
      <Link to={hasMoreThenOneHotspot ? null : url}>
        <ImageWithHotspots {...image} aspect={aspect} hostspotAsLink={hasMoreThenOneHotspot} />
      </Link>
    </article>
  )
}
