import { useState, useMemo } from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { theme } from '../../../styles/theme'
import useSnapshot from '../../../store/useSnapshot'
import { isShopFiltersDialogOpen } from '../../../store/layoutSlice'
import cn from 'classnames'
import { isShopFilterEmpty } from '../../ShopFiltersDialog/index'
import SlidersIcon from '../../../icons/sliders.svg'
import { layouts, setLayout, getLayout } from '../../../store/productListing'
import Button from '../../Button'

export default function FilterButton ({ onClick, label, hidden = false, size = 'sm', hideViewToggle = false }) {
  const styles = useStyles()
  const [isHovering, setIsHovering] = useState(false)
  const snap = useSnapshot()
  const open = isShopFiltersDialogOpen(snap)
  const isFilterEmpty = isShopFilterEmpty(snap)
  const shopListingLayout = getLayout(snap)

  const renderLayoutOptions = useMemo(() => (
    <div className={styles.layoutButtons}>
      <div className={cn(styles.layoutOptionsLabel, 'layoutLabel')}>
        View
      </div>
      {layouts && layouts.map(layout => (
        <button
          key={layout.mode}
          className={cn(styles.layoutOption, { active: shopListingLayout === layout.mode })}
          onClick={() => setLayout(layout.mode)}
        >
          {layout.icon}
        </button>
      ))}
    </div>
  ), [shopListingLayout])

  return (
    <div className={cn(styles.pillContainer, (hidden || open) && styles.hidden, size)} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <Button size="sm" className={styles.filterButton} onClick={onClick} active={isHovering}>
        <SlidersIcon className={styles.filterIcon} />
        {label} {!isFilterEmpty && <div className={styles.dot} />}
      </Button>
      {!hideViewToggle && renderLayoutOptions}
    </div>
  )
}

const useStyles = createUseStyles({
  pillContainer: {
    width: '100%',
    background: 'var(--background)',
    color: 'var(--foreground)',
    opacity: 1,
    transition: 'opacity 0.3s ease-out, transform 0.3s ease-out',
    transform: 'translateY(0)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: 330
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 16
    },
    '&:after': {
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      boxShadow: '0px 7px 15px rgba(0, 0, 0, 0.15)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      transition: 'opacity 0.3s ease-out'
    },
    '&:hover:after': {
      opacity: 1
    },
    '&:hover svg rect': {
      stroke: 'white'
    },
    '&:hover .layoutLabel': {
      color: 'white'
    },
    '&.sm': {
      height: 53
    },
    '&.md': {
      height: 72
    }
  },
  filterIcon: {
    marginRight: theme.spacingPx(1),
    height: 12
  },
  filterButton: {
    padding: `0 0 0 ${theme.spacingPx(3)}`,
    height: '100%',
    textAlign: 'left',
    flex: 1,
    position: 'relative',
    justifyContent: 'flex-start !important',
    '& span': {
      justifyContent: 'flex-start'
    }
  },
  layoutButtons: {
    position: 'absolute',
    right: theme.spacingPx(3),
    display: 'flex',
    pointerEvents: 'none'
  },
  layoutOptionsLabel: {
    opacity: 0.5,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px'
  },
  layoutOption: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    opacity: 0.3,
    pointerEvents: 'all',
    '&.active': {
      opacity: 1
    }
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    transform: 'translateY(20px)'
  },
  dot: {
    display: 'inline-block',
    height: 6,
    width: 6,
    background: 'currentColor',
    borderRadius: '50%',
    marginLeft: 8
  }
})
