import { createUseStyles } from '../helpers/createStyles'
import { PortableText } from '@portabletext/react'

const TopBanner = ({ content, topBannerRef }) => {
  const styles = useStyles()

  return (
    <div className={styles.topBanner} ref={topBannerRef}>
      <PortableText value={content} />
    </div>
  )
}

const useStyles = createUseStyles({
  topBanner: {
    position: 'relative',
    width: '100%',
    background: '#566160',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: 'initial',
    padding: '10px',
    zIndex: 6,
    '& *': {
      margin: '0px',
      padding: '0px',
      lineHeight: 'initial',
      color: '#fff'
    }
  }
})

export default TopBanner
