import { useRef, useCallback } from 'react'
import usePageThemeColorTransition from '../../../hooks/usePageThemeColorTransition'
import Slices from '../../Slices'
import { theme } from '../../../styles/theme'
import Link from '../../Link'
import cn from 'classnames'
import { createUseStyles } from '../../../helpers/createStyles'

const MenuItem = ({ index, item, hoverEffectEnabled, onClick, open }) => {
  const styles = useStyles()
  const containerRef = useRef()
  const { link } = item
  usePageThemeColorTransition(theme.colors.pageTheme[link.color.title], containerRef, 0)
  const { slices } = link
  const handleClick = useCallback((e) => {
    if (item.onClick) item.onClick(e, item)
    if (onClick) onClick(e, item)
  }, [onClick, item])

  return (
    <div className={cn(styles.container, { open })}>
      <Link ref={containerRef} link={link} className={cn(styles.link, { hover: hoverEffectEnabled })} showText={false} onClick={handleClick}>
        <div className={styles.slices}>
          <Slices slices={slices} page={link} summary />
        </div>
        <div className={cn(styles.titleContainer, 'menu-item-title')}>
          <span className={cn(styles.title, { firstMenuItem: index === 0 })}>{link.title}</span>
        </div>
      </Link>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100vh',
    overflow: 'hidden',
    transition: 'box-shadow 0.2s',
    willChange: 'transform',
    [theme.breakpoints.up('md')]: {
      overflow: 'visible'
    },
    '&.open': {
      boxShadow: '-40px 0px 80px rgba(0, 0, 0, 0.05)'
    }
  },
  link: {
    textDecoration: 'none',
    minHeight: '100%',
    height: '100vh',
    display: 'block',
    overflow: 'hidden',
    backgroundColor: theme.colors.background,
    color: theme.colors.text,
    transform: `translate(0, -${theme.spacing(4)}px)`,
    [theme.breakpoints.up('md')]: {
      transition: `transform .8s ${theme.eases.easeOutQuint}`,
      transform: 'translate(0, 0)',
      '&.hover:hover': {
        transform: 'translate(-60px, 0)'
      }
    }
  },
  slices: {
    pointerEvents: 'none'
  },
  titleContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  title: {
    display: 'block',
    // fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.12em',
    paddingTop: theme.spacing(7),
    paddingLeft: theme.gutter.sm,
    // textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      fontSize: '11px',
      paddingTop: 12,
      lineHeight: 13,
      paddingLeft: theme.gutter.md,
      textTransform: 'uppercase'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      lineHeight: '13px',
      paddingTop: 49,
      textTransform: 'uppercase'
    }
  }
})

export default MenuItem
