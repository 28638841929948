import { useRef, useCallback, useState } from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { theme } from '../../../styles/theme'
import { useScrollListener } from '../../SmoothScrollbar/useSmoothScrollbar'
import cn from 'classnames'
import { primaryInput } from 'detect-it'
import FilterButton from './FilterButton'

export default function FloatingFilterButton ({ onClick, label, containerRef, hideViewToggle }) {
  const styles = useStyles()
  const ref = useRef(null)
  const [hidden, setHidden] = useState(true)

  const isMobile = primaryInput === 'touch'

  useScrollListener(useCallback(({ y }) => {
    if (containerRef.current) {
      const end = containerRef.current.offsetHeight - containerRef.current.offsetTop
      if (!isMobile && ref.current) {
        ref.current.style.transform = `translate3d(0, ${y}px ,0)`
      }

      const offset = isMobile ? 100 : 200
      if (y >= offset && y <= end + offset) {
        setHidden(false)
      } else {
        setHidden(true)
      }
    }
  }), [])

  return (
    <div className={cn(styles.wrapper, isMobile && styles.wrapperMobile)} ref={ref}>
      <FilterButton hidden={hidden} onClick={onClick} label={label} hideViewToggle={hideViewToggle} />
    </div>
  )
}

const useStyles = createUseStyles({
  wrapper: {
    position: 'fixed',
    top: '100vh',
    marginTop: '-75px',
    right: 92,
    left: 24,
    zIndex: 999,
    [theme.breakpoints.up('md')]: {
      top: '100vh',
      bottom: 'unset',
      marginTop: '-75px',
      right: 100,
      left: 'unset'
    }
  },
  wrapperMobile: {
    left: 0,
    bottom: 24,
    top: 'unset',
    marginTop: 0
  }
})
