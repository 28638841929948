import { forwardRef, useCallback, useEffect, useRef } from 'react'
import cn from 'classnames'
import forEach from 'lodash/forEach'
import { useIntersection } from 'use-intersection'

import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'

import { createUseStyles } from '../helpers/createStyles'
import { theme } from '../styles/theme'
import useSnapshot from '../store/useSnapshot'
import useWindowResize from '../hooks/useWindowResize'
import useDebouncedCallback from '../hooks/useDebouncedCallback'
import { isFontsLoaded } from '../store/preloadSlice'
import useComposeRefs from '../hooks/useComposeRefs'

gsap.registerPlugin(SplitText)

const ROTATE = 5

function useSplitText (initialOpacity = 0) {
  const splitTextRef = useRef({})
  const locals = splitTextRef.current

  const constructSplitText = useCallback((el) => {
    // Decode HTML strings to allow tags to be rendered correctly
    const doc = new DOMParser().parseFromString(el.innerHTML, 'text/html')
    el.innerHTML = doc.documentElement.textContent

    locals.split = new SplitText(el, { type: 'lines, words', linesClass: 'split-line' })
    gsap.set(locals.split.words, { yPercent: 105, rotate: ROTATE, opacity: 1 })
    gsap.set(el, { opacity: 1 })
    locals.blurElements = locals.split.words.map(element => ({ yPercent: 105, rotate: ROTATE, element, opacity: 1 }))
    if (locals.animatedIn) {
      gsap.set(locals.split.words, { yPercent: 0, rotate: 0, opacity: 1 })
    } else {
      gsap.set(locals.split.words, { yPercent: 105, rotate: ROTATE, opacity: 1 })
    }

    locals.el = el
  }, [locals])

  const destructSplitText = useCallback(() => {
    if (locals.split) {
      locals.split.revert()
      delete locals.targets
      delete locals.split
    }
  }, [locals])

  return [splitTextRef, constructSplitText, destructSplitText]
}

const createTimeline = (splitText, duration = 1, stagger = 0.7) => {
  const timeline = gsap.timeline()
  forEach(splitText.blurElements, (blurElement, i) => {
    timeline.to(blurElement.element, {
      yPercent: 0,
      rotate: 0,
      duration: duration,
      ease: 'expo.out'
    }, i === 0 ? null : `-=${stagger}`)
  })

  timeline.eventCallback('onComplete', () => {
    splitText.animatedIn = true
  })

  return timeline
}

const BlurUpText = forwardRef(({ children, onTimelineCreated, tag = 'div', initialOpacity, duration = 1, stagger = 0.7, text, animateWhenInView = false, className, titleMaxWidth }, ref) => {
  const styles = useStyles()
  const containerRef = useRef()
  const timelineRef = useRef()
  const snap = useSnapshot()
  const fontsLoaded = isFontsLoaded(snap)
  const inView = useIntersection(containerRef, { once: true })

  const [splitTextRef, constructSplitText, destructSplitText] = useSplitText(initialOpacity)

  const initSplitText = useCallback((rerunTimeline) => {
    destructSplitText()
    constructSplitText(containerRef.current)

    if (timelineRef.current) timelineRef.current.kill()
    timelineRef.current = createTimeline(splitTextRef.current, duration, stagger)
    if (!rerunTimeline && splitTextRef.current.animatedIn) {
      // We pause it here so the animation does not run again when the timeline is recreated because of a window resize
      timelineRef.current.pause()
    }
    if (onTimelineCreated) onTimelineCreated(timelineRef.current)
  }, [destructSplitText, constructSplitText, onTimelineCreated])

  useEffect(() => {
    if (fontsLoaded) {
      initSplitText(false)
    }
  }, [fontsLoaded, constructSplitText])

  useWindowResize(useDebouncedCallback(() => {
    if (!fontsLoaded) return
    if (splitTextRef.current.el) {
      initSplitText(false)
    }
  }, 150, [initSplitText, fontsLoaded]), false, true)

  useEffect(() => {
    if (animateWhenInView && inView && fontsLoaded) {
      // gsap.set(containerRef, { opacity: 1 })
      timelineRef.current.play()
    }
  }, [animateWhenInView, inView, fontsLoaded, splitTextRef, constructSplitText])

  useEffect(() => {
    return () => {
      destructSplitText()
    }
  }, [destructSplitText])

  const Tag = tag

  return (
    <Tag className={cn(className, styles.container)} style={{ opacity: initialOpacity, maxWidth: titleMaxWidth }} ref={useComposeRefs(ref, containerRef)}>
      {text}
      {children}
    </Tag>
  )
})

const useStyles = createUseStyles({
  container: {
    color: theme.colors.text,
    opacity: 0,
    '& .split-line': {
      overflow: 'hidden'
    }
  }
})

export default BlurUpText
