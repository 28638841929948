import React, { useRef, useEffect, useContext } from 'react'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import Scrollbar from 'smooth-scrollbar'
// import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import remove from 'lodash/remove'
import forEach from 'lodash/forEach'
import { useRouter } from 'next/router'

import useWindowResize from '../../hooks/useWindowResize'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'

import ScrollHistory from './scrollHistory'
import inDOM from 'dom-helpers/canUseDOM'
import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect'
import SoftScrollPlugin from './SoftScrollPlugin'
import DisableScrollPlugin from './DisableScrollPlugin'

export const SmoothScrollContext = React.createContext(null)

gsap.registerPlugin(ScrollTrigger)

export const useDisableScrollBar = (disabled) => {
  useEffect(() => {
    if (disabled) {
      const scrollTracks = document.getElementsByClassName('scrollbar-track')
      gsap.set(scrollTracks, { pointerEvents: 'none' })
      return () => {
        gsap.set(scrollTracks, { pointerEvents: 'all' })
      }
    }
  }, [disabled])
}

export function useScrollListener (callback, enabled = true, desktopOnly) {
  const context = useContext(SmoothScrollContext)
  useEffect(() => {
    if (!enabled) return
    if (context && context.current) {
      if (primaryInput === 'touch') {
        if (desktopOnly) return
        context.current.nativeScrollCallbacks.push(callback)
        return () => {
          remove(context.current.nativeScrollCallbacks, cb => cb === callback)
        }
      } else {
        context.current.scrollCallbacks.push(callback)
        return () => {
          remove(context.current.scrollCallbacks, c => c === callback)
        }
      }
    }
  }, [callback, enabled])
}

export const useScrollReadyListener = (callback) => {
  const context = useContext(SmoothScrollContext)
  useIsomorphicLayoutEffect(() => {
    if (context && context.current) {
      if (context.current.ready) {
        callback(context.current)
      } else {
        context.current.scrollReadyCallbacks.push(callback)
        return () => {
          remove(context.current.scrollReadyCallbacks, c => c === callback)
        }
      }
    }
  }, [callback])
}

const setScrollerProxy = (element, scrollbarRef) => {
  ScrollTrigger.scrollerProxy(element, {
    scrollTop (value) {
      if (arguments.length) {
        scrollbarRef.current.setScrollY(value)
      }
      return scrollbarRef.current.getScrollY()
    }
  })
}

const useSmoothScrollbar = (options) => {
  const { bodyScroll, restoreScrollState = true, scrollHistoryKey = 'page', disableHorizontalScroll } = (options || {})
  const ref = useRef()
  const router = useRouter()

  const scrollbarRef = useRef({
    scrollbar: null,
    scrollElement: null,
    scrollCallbacks: [],
    nativeScrollCallbacks: [],
    scrollReadyCallbacks: [],
    scrollHistoryPaused: false,
    scrollHistory: new ScrollHistory(scrollHistoryKey),
    ready: false,
    data: { y: 0, previousY: 0 }
  })

  const triggerScrollCallbacks = (offset) => {
    forEach(scrollbarRef.current.scrollCallbacks, cb => {
      if (cb) cb(offset)
    })
    forEach(scrollbarRef.current.nativeScrollCallbacks, cb => {
      if (cb) cb(offset)
    })
  }

  scrollbarRef.current.getScrollY = () => {
    if (scrollbarRef.current && scrollbarRef.current.scrollbar) {
      return scrollbarRef.current.scrollbar.offset.y
    }
    if (!bodyScroll && scrollbarRef.current.scrollElement) {
      return scrollbarRef.current.scrollElement.scrollTop
    }
    return (window.pageYOffset || document.documentElement.scrollTop)
  }

  scrollbarRef.current.setScrollY = (y, duration = 0) => {
    if (isNaN(y)) {
      console.error('y is NAN, this should not happen!')
      return
    }
    if (scrollbarRef.current) {
      if (scrollbarRef.current.scrollbar) {
        scrollbarRef.current.scrollbar.scrollTo(0, y, duration)
      } else {
        const { scrollElement } = scrollbarRef.current
        if (!bodyScroll && scrollElement) {
          scrollElement.scrollTop = y
        } else {
          if (inDOM) {
            window.scrollTo(0, y)
          }
        }
      }
    }
  }

  scrollbarRef.current.restoreScrollState = () => {
    if (window?.history?.state?.idx) {
      const { scrollHistory } = scrollbarRef.current
      const y = scrollHistory.get(window.history.state.idx, window.history.state.as) || 0
      scrollbarRef.current.setScrollY(y, 0)
      triggerScrollCallbacks({ y })
      scrollbarRef.current.scrollHistoryPaused = false
    }
  }

  useEffect(() => {
    if (scrollbarRef.current.scrollbar && primaryInput !== 'touch') {
      scrollbarRef.current.scrollbar.updatePluginOptions('disableScroll', { direction: disableHorizontalScroll ? 'x' : '' })
    }
  }, [disableHorizontalScroll])

  useEffect(() => {
    const handleRouterChangeStart = () => {
      scrollbarRef.current.scrollHistoryPaused = true
    }
    const handleRouteChange = (url) => {
      if (restoreScrollState) {
        scrollbarRef.current.restoreScrollState()
      }
    }

    router.beforePopState((state) => {
      // We will manage the scroll our selves
      state.options.scroll = false
      return true
    })

    router.events.on('routeChangeStart', handleRouterChangeStart)
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouterChangeStart)
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [restoreScrollState])

  const updateScrollHistory = (y) => {
    const { data, scrollHistory, scrollHistoryPaused } = scrollbarRef.current
    if (!scrollHistoryPaused) {
      data.y = y
      scrollHistory.set(window.history.state.idx, window.history.state.as, y)
    }
  }

  useEffect(() => {
    const init = async () => {
      // const SoftScrollPlugin = (await import('./SoftScrollPlugin')).default
      if (ref.current && primaryInput === 'touch') {
        const element = bodyScroll ? window : ref.current.parentElement
        gsap.set(ref.current, { height: 'auto', overflowY: 'visible' })
        const handleScroll = () => {
          updateScrollHistory(element.scrollY || element.scrollTop)
        }
        element.addEventListener('scroll', handleScroll, { passive: true })

        gsap.ticker.add(() => {
          const { previousY, y } = scrollbarRef.current.data
          const { nativeScrollCallbacks } = scrollbarRef.current
          if (previousY !== y) {
            forEach(nativeScrollCallbacks, fn => fn({ y }))
          }
          scrollbarRef.current.data.previousY = y
        })

        scrollbarRef.current.scrollElement = bodyScroll ? document.body : ref.current.parentElement
        scrollbarRef.current.ready = true
        forEach(scrollbarRef.current.scrollReadyCallbacks, cb => cb(scrollbarRef.current))
        return () => {
          element.removeEventListener('scroll', handleScroll)
        }
      }

      if (ref.current && primaryInput !== 'touch') {
        setScrollerProxy(ref.current, scrollbarRef)

        Scrollbar.use(SoftScrollPlugin)
        Scrollbar.use(DisableScrollPlugin)
        const scrollbar = Scrollbar.init(ref.current, {
          damping: 0.18,
          thumbMinSize: 20,
          renderByPixels: true,
          alwaysShowTracks: false,
          syncCallbacks: true,
          plugins: {
            disableScroll: {
              direction: disableHorizontalScroll ? 'x' : ''
            }
          }
        })
        // scrollbar.updatePluginOptions('overscroll', { effect: 'bounce' })

        scrollbar.addListener(({ offset }) => {
          // Update the scroll history
          updateScrollHistory(offset.y)
          // Updates the scroll position for the scroll triggers
          ScrollTrigger.update()
          // Trigger the scrollbar callbacks
          triggerScrollCallbacks(offset)
        })

        ScrollTrigger.defaults({ scroller: ref.current })

        ScrollTrigger.refresh(true)

        scrollbarRef.current.scrollbar = scrollbar
        scrollbarRef.current.scrollElement = ref.current
        scrollbarRef.current.ready = true

        forEach(scrollbarRef.current.scrollReadyCallbacks, cb => cb(scrollbarRef.current))

        return () => {
          scrollbarRef.current.scrollbar.destroy()
          scrollbarRef.current.scrollbar = null
        }
      }
    }
    init()
  }, [])

  useWindowResize(useDebouncedCallback(() => {
    triggerScrollCallbacks({ y: scrollbarRef.current.getScrollY() })
  }))

  return { ref, scrollbarRef }
}

export default useSmoothScrollbar
