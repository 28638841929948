import isEmpty from 'lodash/isEmpty'
import Button from '../Button'
import { createUseStyles } from '../../helpers/createStyles'
import { getSiteData } from '../../store/layoutSlice'
import useSnapshot from '../../store/useSnapshot'
import { theme } from '../../styles/theme'
import { useCallback, useMemo } from 'react'
import { getCartState } from '../../store/cartSlice'
import { addToCart } from '../../store/cartApi'
import { getProducts } from '../../store/productsSlice'
import cn from 'classnames'

const AddToCartButton = ({ className, children, product, productId, variantId, disabled, size = 'md', onClick, openCart = true, active = false, onClickOverride }) => {
  const snap = useSnapshot()
  const styles = useStyles()
  const addingToCart = getCartState(snap).addingToCart
  const removingFromCart = getCartState(snap).removingFromCart
  const { addingToCartLabel, addToCartLabel, removingFromCartLabel = 'Removing from cart' } = getSiteData(snap)
  const lineItems = [{
    productId,
    variantId,
    quantity: 1
  }]

  const handleClick = useCallback((e) => {
    // Data required for google analytics
    const variant = product.product.variants.find((variant) => variant.id === variantId)
    const datalayerObject = {
      id: variant.id,
      name: product.title,
      category: product.primaryCategory?.name,
      variant: variant.option_values.map((option) => option.label).join(' - '),
      price: variant.price.tax_inclusive
    }

    if (onClickOverride) {
      onClickOverride()
    } else {
      if (onClick) onClick(e)
      addToCart(lineItems, [], false, [datalayerObject], openCart)
        .then(res => {
          // Klaviyo Add to Cart tracking
          window._learnq.push(['track', 'Added to Cart', {
            $value: res.cart.cartAmount,
            AddedItemProductName: product.title,
            AddedItemProductID: product.bigCommerceId,
            AddedItemSKU: variant.sku,
            AddedItemCategories: getProducts(snap).filter(currProduct => currProduct.bigCommerceId === product.bigCommerceId ? currProduct : false)[0]?.categories.map(category => category.name),
            AddedItemImageURL: (product.product.images && product.product.images[0] && product.product.images[0].url_standard) || '',
            AddedItemPrice: product.product.price.tax_inclusive,
            AddedItemQuantity: 1,
            ItemNames: res.cart?.lineItems?.physical_items?.map(item => item.name),
            CheckoutURL: `https://shop-${snap.prices.countryCode === 'au' ? 'au' : 'us'}.armadillo-co.com/checkout`,
            Items: res.cart?.lineItems?.physical_items?.map(item => {
              return {
                ProductID: item.product_id,
                SKU: item.sku,
                ProductName: item.name,
                Quantity: item.quantity,
                ItemPrice: item.sale_price,
                RowTotal: item.sale_price * item.quantity,
                ProductURL: item.url,
                ProductCategories: getProducts(snap).filter(currProduct => currProduct.bigCommerceId === item.product_id ? currProduct : false)[0]?.categories.map(category => category.name)
              }
            })
          }])
        })
    }
  }, [lineItems, product, openCart])

  const renderButtonText = useMemo(() => {
    if (removingFromCart === variantId) return removingFromCartLabel
    if (addingToCart === variantId) return addingToCartLabel
    if (isEmpty(children)) return addToCartLabel
    return children
  }, [children, addingToCartLabel, removingFromCartLabel, addingToCart, removingFromCart])

  return (
    <Button
      className={cn(className, styles.addToCartButton)}
      disabled={disabled || addingToCart === variantId}
      onClick={handleClick}
      size={size}
      active={active}
    >
      {renderButtonText}
    </Button>
  )
}

const useStyles = createUseStyles({
  addToCartButton: {
    display: 'flex',
    borderColor: `${theme.colors.button.backgroundHover}!important`
  }
})

export default AddToCartButton
