import { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import layoutSlice from '../../store/layoutSlice'

const US_COUNTRY_LIST = [
  'United States of America',
  'Albania',
  'Andorra',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Aruba',
  'Austria',
  'Bahamas',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Bermuda',
  'Bolivia',
  'Bosnia & Herzegovina',
  'Brazil',
  'British Virgin Islands',
  'Bulgaria',
  'Canada',
  'Caribbean Netherlands',
  'Cayman Islands',
  'Channel Islands',
  'Chile',
  'Colombia',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Czech Republic',
  'Denmark',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'El Salvador',
  'Estonia',
  'Faeroe Islands',
  'Falkland Islands',
  'Finland',
  'France',
  'French Guiana',
  'Germany',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guatemala',
  'Guyana',
  'Haiti',
  'Holy See (Vatican City)',
  'Honduras',
  'Hungary',
  'Iceland',
  'Ireland',
  'Isle of Man',
  'Italy',
  'Jamaica',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Martinique',
  'Mexico',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Montserrat',
  'Netherlands',
  'Nicaragua',
  'Norway',
  'Panama',
  'Paraguay',
  'Peru',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Romania',
  'Russia',
  'Saint Kitts & Nevis',
  'Saint Lucia',
  'Saint Pierre & Miquelon',
  'San Marino',
  'Serbia',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Spain',
  'St. Vincent & Grenadines',
  'Suriname',
  'Sweden',
  'Switzerland',
  'TFYR Macedonia',
  'Trinidad and Tobago',
  'Turks and Caicos',
  'U.K.',
  'U.S. Virgin Islands',
  'Ukraine',
  'Uruguay',
  'Venezuela'
]

const AU_COUNTRY_LIST = [
  'Australia',
  'Afghanistan',
  'Algeria',
  'American Samoa',
  'Angola',
  'Armenia',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Benin',
  'Bhutan',
  'Botswana',
  'Brunei',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Central African Republic',
  'Chad',
  'China',
  'Comoros',
  'Congo',
  'Cook Islands',
  "Côte d'Ivoire",
  'Cyprus',
  'Djibouti',
  'DR Congo',
  'Egypt',
  'Equatorial Guinea',
  'Eritrea',
  'Ethiopia',
  'Fiji',
  'French Polynesia',
  'Gabon',
  'Gambia',
  'Georgia',
  'Ghana',
  'Guam',
  'Guinea',
  'Guinea-Bissau',
  'Hong Kong',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Israel',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Micronesia',
  'Mongolia',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'New Caledonia',
  'New Zealand',
  'Niger',
  'Nigeria',
  'Niue',
  'North Korea',
  'Northern Mariana Islands',
  'Oman',
  'Pakistan',
  'Palau',
  'Papua New Guinea',
  'Philippines',
  'Qatar',
  'Réunion',
  'Rwanda',
  'Saint Helena',
  'Samoa',
  'Sao Tome & Principe',
  'Saudi Arabia',
  'Senegal',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Sri Lanka',
  'State of Palestine',
  'Sudan',
  'Swaziland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'United Arab Emirates',
  'Uzbekistan',
  'Vanuatu',
  'Viet Nam',
  'Wallis & Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe'
]

const getCountryOptionsForCurrentLocale = () => {
  switch (layoutSlice.state.site.locale) {
    case 'en-US':
      return US_COUNTRY_LIST
    case 'en-AU':
      return AU_COUNTRY_LIST
    default:
      return AU_COUNTRY_LIST
  }
}

const CountrySelect = forwardRef(({ name, placeholder, required, ...rest }, ref) => {
  // For some reason the auto registration of the select is not working,
  // so i am setting the value manually
  const { setValue } = useFormContext()
  const handleChange = (e) => {
    setValue(name, e.target.value)
  }

  return (
    <select {...rest} onChange={handleChange} name={name} ref={ref}>
      <option value=''>{placeholder}</option>
      {getCountryOptionsForCurrentLocale().map((countryLabel, index) =>
        <option key={index} value={countryLabel}>{countryLabel}</option>
      )}
    </select>
  )
})

export default CountrySelect
