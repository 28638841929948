import Section from '../../Section'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import first from 'lodash/first'
import { createUseStyles } from '../../../helpers/createStyles'
import { theme, grid } from '../../../styles/theme'
import {
  getCurrentJournalCategory,
  getCurrentJournalPage,
  getJournals,
  getPostCategories,
  getSiteData,
  getTotalJournals
} from '../../../store/layoutSlice'
import useSnapshot from '../../../store/useSnapshot'
import PostTile from '../PostTiles/PostTile'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useIntersection } from 'use-intersection'
import Dropdown from '../../Dropdown'

const aspects = {
  landscape: {
    3: null,
    4: 448 / 382,
    5: 565 / 380,
    8: 920 / 556
  },
  portrait: {
    3: 330 / 380,
    4: 450 / 556,
    5: 566 / 816,
    8: 920 / 556
  }
}

export default function JournalListing ({ data, page, summary }) {
  const snap = useSnapshot()
  const styles = useStyles()
  const { title: defaultTitle, copy: defaultCopy, category_overrides: categoryOverrides } = data
  const router = useRouter()
  const loadMoreRef = useRef()
  const localsRef = useRef({ loading: false })
  const [loading, setLoading] = useState()
  const [title, setTitle] = useState()
  const [copy, setCopy] = useState()
  localsRef.current.loading = loading

  const { showAllLabel = 'Show All', filterByLabel = 'Filter By' } = getSiteData(snap)

  const journals = getJournals(snap)
  const total = getTotalJournals(snap)
  const currentPage = getCurrentJournalPage(snap)
  const category = getCurrentJournalCategory(snap)
  const categories = getPostCategories(snap)

  localsRef.current.hasMorePlages = !summary && journals && total > journals.length
  localsRef.current.currentPage = currentPage
  localsRef.current.category = category

  useEffect(() => {
    const copyOverrides = first(categoryOverrides?.filter(x => x.postCategory?.slug === category))

    setTitle(copyOverrides?.title || defaultTitle)
    setCopy(copyOverrides?.copy || defaultCopy)
  }, [category, categoryOverrides, setTitle, setCopy])

  const intersected = useIntersection(loadMoreRef, { once: false })
  useEffect(() => {
    // We use the locals loading ref here as we do not what to trigger this when the
    // loading state changes only when it intersects but we do not want to load the
    // next page when it pending a previous load next
    if (intersected && !localsRef.current.loading && localsRef.current.hasMorePlages) {
      const loadNextPage = async () => {
        setLoading(true)
        const { category, currentPage } = localsRef.current
        await router.replace(category
          ? `/journal/${category}/${currentPage + 1}`
          : `/journal/${currentPage + 1}`
        )
        setLoading(false)
      }
      loadNextPage()
    }
  }, [intersected])

  const onCategoryChanged = useCallback((value) => {
    router.push(value ? `/journal/${value}` : '/journal')
  }, [])

  const categoryOptions = useMemo(() => {
    if (!categories) return []
    return [
      { text: showAllLabel, value: null },
      ...categories.map(c => ({ text: c.title, value: c.slug }))
    ]
  }, [categories])

  const renderTiles = (tiles) => {
    return tiles.map((post, i) => {
      const mod = i % 14
      const layout = [0, 3, 4, 8, 10, 11].includes(mod) || category === 'playlist' ? 'landscape' : 'portrait'
      const columns = category === 'playlist' ? 4 : [0, 4, 7, 11].includes(mod)
        ? 4
        : [1, 5, 9, 12].includes(mod)
            ? 3
            : [2, 6, 8, 13].includes(mod) ? 5 : 8
      const aspect = post.category?.title === 'Playlist' ? 1 : aspects[layout][columns]
      const mobileAspect = post.category?.title === 'Playlist' ? 1 : 327 / 350

      return (
        <PostTile
          key={post._id}
          className={cn(styles.tile, `columns${columns}`)}
          post={post}
          layout={layout}
          aspect={aspect}
          largeTile={columns >= 5}
          mobileAspect={mobileAspect}
          renderLinks={!summary}
          eagerLoadImage={i < 3}
          summary
        />
      )
    })
  }

  const tilesMemo = useMemo(() => {
    const _journals = journals || data.journals
    if (_journals) {
      const tiles = summary ? _journals.slice(0, 3) : _journals

      const copyOverrides = first(categoryOverrides?.filter(x => x.postCategory?.slug === category))

      setTitle(copyOverrides?.title || defaultTitle)
      setCopy(copyOverrides?.copy || defaultCopy)

      return renderTiles(tiles)
    }
  }, [journals, summary, data.journals, categoryOverrides, defaultTitle, defaultCopy, category])

  return (
    <Section className={styles.container} grid key={category}>
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        {copy && <p className={styles.headerCopy}>{copy}</p>}
        <Dropdown
          className={styles.dropdown}
          label={filterByLabel}
          value={category}
          options={categoryOptions}
          onChange={onCategoryChanged}
          emptyText={showAllLabel}
        />
      </div>
      { tilesMemo }
      <div className={styles.loadMoreContainer} ref={loadMoreRef}>
        {loading && <span>Loading...</span>}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  container: {
    paddingTop: theme.spacing(14),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(26)
    }
  },
  header: {
    gridColumn: '1 / -1',
    display: 'grid',
    gap: `${grid.md.gutter}px`,
    gridTemplateColumns: `repeat(${grid.md.columns}, minmax(0px, 1fr))`,
    [theme.breakpoints.up('md')]: {
      height: 240
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column'
    }
  },
  dropdown: {
    gridColumn: '9 / -1',
    justifySelf: 'flex-end'
  },
  title: {
    gridColumn: 'span 4',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  headerCopy: {
    gridColumn: 'span 4',
    maxWidth: '28em'
  },
  tile: {
    gridColumn: 'span 8',
    [theme.breakpoints.up('md')]: {
      paddingBottom: 122,
      '&.columns3': {
        gridColumn: 'span 3'
      },
      '&.columns4': {
        gridColumn: 'span 4'
      },
      '&.columns5': {
        gridColumn: 'span 5'
      },
      '&.columns8': {
        gridColumn: 'span 8 / -1'
      }
    }
  },
  loadMoreContainer: {
    gridColumn: '1 / -1',
    textAlign: 'center'
  }
})
