import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import { useCallback, useEffect, useRef } from 'react'
import { theme } from '../styles/theme'
import { useScrollReadyListener } from '../components/SmoothScrollbar/useSmoothScrollbar'
import useWindowResize from './useWindowResize'

gsap.registerPlugin(ScrollTrigger)

export default function useScrollTrigger (props = () => ({}), animation, desktopOnly = false) {
  const ref = useRef()
  const localsRef = useRef({ })
  const setupScrollTrigger = useCallback(({ scrollElement }) => {
    if (ref.current) {
      const createTrigger = () => {
        const tl = gsap.timeline()
        localsRef.current.tl = tl
        localsRef.current.scrollTrigger = ScrollTrigger.create({
          scroller: scrollElement,
          trigger: document.documentBody,
          start: 'top top',
          end: 'bottom top',
          scrub: 0.1,
          animation: tl,
          ...props()
        })
        if (animation) animation(tl, ref)
      }

      if (desktopOnly) {
        ScrollTrigger.matchMedia({
          [`(min-width: ${theme.breakpoints.values.md}px)`]: createTrigger
        })
      } else {
        createTrigger()
      }
    }
  }, [])

  useWindowResize(() => ScrollTrigger.refresh(true), false, false)

  useEffect(() => {
    return () => {
      localsRef.current.tl?.kill()
      localsRef.current.scrollTrigger?.kill()
    }
  }, [])

  useScrollReadyListener(setupScrollTrigger)

  return ref
}
