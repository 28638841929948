import { useMemo } from 'react'
import Link from './Link'
import cn from 'classnames'
import { resolveLink } from '../lib/resolveLink'
import { theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'
import { expoOut } from '../styles/easing'

const Button = ({ className, type, link, to, onClick, children, size = 'md', active, safariFix, ...rest }) => {
  const styles = useStyles()
  const { url, text } = useMemo(() => {
    if (to) return { url: to }
    if (link) {
      return resolveLink(link)
    }
    return {}
  }, [link, to])

  const classes = cn(className || null, styles.button, { active, safariFix }, size)

  // External or Internal Link
  if (url || to) {
    return (
      <Link
        to={url || to}
        className={classes}
        showExternalIcon={false}
        onClick={onClick}
        {...rest}
      >
        {children || text}
        <span className={cn(styles.copy, 'copy')} aria-hidden>
          <span className={styles.copyText}>{children || text}</span>
        </span>
      </Link>
    )
  }

  // Button
  if (onClick || type) {
    return (
      <button
        type={type || 'button'}
        onClick={onClick}
        className={classes}
        {...rest}
      >
        {children || text}
        <span className={cn(styles.copy, 'copy')} aria-hidden>
          <span className={styles.copyText}>{children || text}</span>
        </span>
      </button>
    )
  }

  return null
}

const useStyles = createUseStyles({
  button: {
    textAlign: 'center',
    textDecoration: 'none',
    padding: `0 ${theme.spacingPx(3)}`,
    color: theme.colors.text,
    border: `1px solid ${theme.colors.border}`,
    transition: `border 0.5s ${expoOut}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&.sm': {
      minHeight: 42,
      [theme.breakpoints.up('md')]: {
        minHeight: 42
      }
    },
    '&.md': {
      minHeight: 48,
      [theme.breakpoints.up('md')]: {
        padding: `0 ${theme.spacingPx(4)}`,
        minHeight: 64
      }
    },
    '&.md-lg': {
      minHeight: 64,
      [theme.breakpoints.up('md')]: {
        padding: `0 ${theme.spacingPx(4)}`,
        minHeight: 72
      }
    },
    '&.lg': {
      minHeight: 72,
      [theme.breakpoints.up('md')]: {
        padding: `0 ${theme.spacingPx(4)}`,
        minHeight: 72
      }
    },
    '&:hover:not(:disabled), &.active': {
      borderColor: theme.colors.button.backgroundHover,
      '& .copy': {
        transform: 'translate(0, 0%)',
        '& > span': {
          transform: 'translate(0, 0%)',
          opacity: 1
        }
      }
    },
    '&:disabled': {
      opacity: 0.6,
      cursor: 'inherit'
    },
    '&.safariFix': {
      [theme.breakpoints.down('md')]: {
        '& .copy': {
          display: 'none'
        },
        '&:active': {
          backgroundColor: theme.colors.button.backgroundHover,
          color: theme.colors.button.foregroundHover
        }
      }
    }
  },
  copy: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.button.backgroundHover,
    color: theme.colors.button.foregroundHover,
    overflow: 'hidden',
    transform: 'translate(0, 100%)',
    transition: `transform 0.5s ${expoOut}`
  },
  copyText: {
    padding: `0 ${theme.spacingPx(3)}`,
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
    transform: 'translate(0, -100%)',
    opacity: 0,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    transition: `transform 0.5s ${expoOut}`,
    '.md > span > &, .md-lg > span > &, .lg > span > &': {
      [theme.breakpoints.up('md')]: {
        padding: `0 ${theme.spacingPx(4)}`
      }
    }
  }
})

export default Button
