import dynamic from 'next/dynamic'

const TradeRegistrationForm = dynamic(() => import('./TradeRegistration'))
const DesignConsultation = dynamic(() => import('./DesignConsultation/DesignConsultation'))
const AffiliationForm = dynamic(() => import('./AffiliationForm'))
const GiftCertificate = dynamic(() => import('./GiftCertificate'))
const SignUpForm = dynamic(() => import('./SignUpForm'))
const PostalAddressForm = dynamic(() => import('./PostalAddressForm'))

const forms = {
  'trade-registration': TradeRegistrationForm,
  'design-consultation': DesignConsultation,
  'affiliation-form': AffiliationForm,
  'gift-certificate': GiftCertificate,
  'sign-up-form': SignUpForm,
  'postal-address-form': PostalAddressForm
}

export default function PageForm ({ page, first }) {
  const formType = page?.formType
  if (!formType) return null
  const Component = forms[formType]
  return <Component page={page} first={first} />
}
