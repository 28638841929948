import cn from 'classnames'
import { useRouter } from 'next/router'
import { createUseStyles } from '../helpers/createStyles'
import { resolveLink } from '../lib/resolveLink'
import { theme } from '../styles/theme'
import Link from './Link'

const isSubPathOf = (parentPath = '/', currentPath = '/') => {
  return currentPath.indexOf(parentPath + '/') === 0
}

const RoundButton = ({ to, link, active, activateOnChildRoute, className, summary = false, children, ...props }) => {
  const styles = useStyles()
  const { asPath } = useRouter()

  if (to || link) {
    const isActiveChildPath = activateOnChildRoute ? null : isSubPathOf(resolveLink(link)?.url || to, asPath)
    const isActiveLink = link ? resolveLink(link)?.url === asPath : false
    const isActive = active || isActiveLink || isActiveChildPath || to === asPath

    return (
      <Link
        to={to}
        link={!summary && link}
        nonLinkTag='div'
        className={cn(styles.filterButton, className, { active: isActive })}
        {...props}
      >
        {summary ? link?.page?.title : children}
      </Link>
    )
  }

  return (
    <button
      className={cn(styles.filterButton, className, { active })}
      {...props}
    >
      {children}
    </button>
  )
}

const useStyles = createUseStyles({
  filterButton: {
    height: 26,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    marginTop: theme.spacingPx(1),
    padding: '0 12px !important',
    borderRadius: '92px',
    textAlign: 'center',
    textDecoration: 'none',
    color: 'var(--foreground)',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    transition: 'color 0.2s ease-out, border-color 0.2s ease-out, background-color 0.2s ease-out',
    lineHeight: '1em',
    '&:hover': {
      '&:not(.active)': {
        // color: 'var(--background)',
        // background: 'var(--foreground)',
        border: '1px solid rgba(0, 0, 0, 0.5)'
      }
    },
    '&.active': {
      color: 'var(--foreground)',
      border: '1px solid rgba(0, 0, 0, 0.5)'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 12px !important'
    }
  }
})

export default RoundButton
