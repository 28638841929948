import { useMemo } from 'react'
import { getPrice, getSalePrice } from '../store/pricesSlice'
import { getProductByBigCommerceId } from '../store/productsSlice'
import useSnapshot from '../store/useSnapshot'
import sortBy from 'lodash/sortBy'
import first from 'lodash/first'

export default function usePrice (productId, variantId, colorVariantId) {
  const snap = useSnapshot()
  const product = getProductByBigCommerceId(snap, productId)
  const resolvedVariantId = useMemo(() => {
    if (variantId && !colorVariantId) return variantId
    if (product) {
      if (colorVariantId) {
        return first(sortBy(product.product.variants.filter(x => (x.calculated_price > 0 && first(x.option_values.filter(y => (y.option_display_name === 'Colour' || y.option_display_name === 'Color')))?.id === colorVariantId)), x => x.calculated_price))?.id
      } else {
        return first(sortBy(product.product.variants.filter(x => x.calculated_price > 0), x => x.calculated_price))?.id
      }
    }
  }, [product, productId, variantId, colorVariantId])
  const price = getPrice(snap, productId, resolvedVariantId)
  const salePrice = getSalePrice(snap, productId, resolvedVariantId)
  const salePercentage = price && salePrice && Math.floor(100 - salePrice.as_entered / price.as_entered * 100)
  return { price, salePrice, salePercentage }
}
