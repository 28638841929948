import { ScrollbarPlugin } from 'smooth-scrollbar'

// See https://github.com/idiotWu/smooth-scrollbar/discussions/357 for reference
class DisableScrollPlugin extends ScrollbarPlugin {
  transformDelta (delta) {
    if (this.options.direction) {
      delta[this.options.direction] = 0
    }

    return { ...delta }
  }
}

DisableScrollPlugin.pluginName = 'disableScroll'
DisableScrollPlugin.defaultOptions = {
  direction: ''
}

export default DisableScrollPlugin
