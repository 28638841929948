import { css } from 'goober'
import reduce from 'lodash/reduce'
import { useMemo } from 'react'
import { isBrowser } from '../lib/helpers'

const createdStyles = []

function createStyles (obj) {
  return reduce(obj, (result, value, key) => {
    result[key] = css(value)
    return result
  }, {})
}

export const createUseStyles = (obj) => {
  return () => {
    return useMemo(() => {
      if (isBrowser) {
        const existingStyles = createdStyles.find(({ ref }) => ref === obj)
        if (existingStyles) return existingStyles.styles
        const styles = createStyles(obj)
        createdStyles.push({ ref: obj, styles })
        return styles
      }
      return createStyles(obj)
    }, [])
  }
}
