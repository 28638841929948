import { useMemo } from 'react'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import useSnapshot from '../../../store/useSnapshot'
import { getMinMaxPrices, isTaxInclusive } from '../../../store/pricesSlice'
import { isColorVariant } from '../../../helpers/productHelper'

export default function useProductVariants (products) {
  const snap = useSnapshot()
  const taxInclusive = isTaxInclusive(snap)
  return useMemo(() => {
    if (products) {
      return reduce(products, (result, product) => {
        return [
          ...result,
          ...(isEmpty(product.colors)
            ? [{ product }]
            : product.colors.map(c => {
              const variantVariants = product.product.variants.filter(variant => {
                return variant.option_values.findIndex(optionVal =>
                  isColorVariant(optionVal) &&
                  optionVal.label === c.label) !== -1
              })
              const variantPrices = variantVariants.map(option => option.price)
              const { min: minPrice, max: maxPrice } = getMinMaxPrices(variantPrices, taxInclusive)
              const onSale = !!variantVariants.find(variant => variant.sale_price)
              return { product, variantId: c.bigCommerceId, minPrice, maxPrice, onSale }
            })
          )
        ]
      }, [])
    }
    return { }
  }, [products, taxInclusive])
}
