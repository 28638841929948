import { useCallback, useMemo, useRef, forwardRef } from 'react'
import compact from 'lodash/compact'
import { createUseStyles } from '../../helpers/createStyles'
import { theme } from '../../styles/theme'
import Tabs from '../Tabs'
import RotateUpSplitText from '../RotateUpSplitText'
import useSnapshot from '../../store/useSnapshot'
import { getCollectionData, getTransition } from '../../store/layoutSlice'
import { resolveInternalLinkUrl } from '../../lib/resolveLink'
import gsap from 'gsap'
import Section from '../Section'
import cn from 'classnames'
import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect'

const NavigationTabs = forwardRef(({ className, summary, title, hideFilterPills, children, hasCollectionScrollLock, collectionScrollLockText, sections, useLocalNavigation }, ref) => {
  const styles = useStyles()
  const snap = useSnapshot()
  const { links } = getCollectionData(snap)
  const tabsRef = useRef()
  const actionsRef = useRef()
  const collectionScrollLockTextRef = useRef()
  const { type: transitionType } = getTransition(snap)

  const sectionTabs = useMemo(() => {
    if (!sections) return []
    return sections.map(({ links }) => ({
      url: resolveInternalLinkUrl({
        parent: links[0].page.parent,
        slug: links[0].page.slug,
        locale: links[0].page.locale
      }),
      text: links[0].page.title
    }))
  })

  const localsRef = useRef({ transitionType })

  const animateTitle = !summary && !hasCollectionScrollLock && !localsRef.current.transitionType

  const collectionTabs = useMemo(() => {
    if (!links) return []
    return links.map(({ title, link }) => ({
      url: resolveInternalLinkUrl(link),
      text: title
    }))
  }, [])

  const onTitleTimelineCreated = useCallback((titleTimeline) => {
    if (!animateTitle) return
    if (tabsRef.current) {
      const timeline = gsap.timeline()
      timeline.add(titleTimeline)
      timeline.to(compact([actionsRef.current, tabsRef.current]), { opacity: 1, duration: 0.8, ease: 'sine.inOut' }, '-=0.5')
    } else if (collectionScrollLockTextRef.current) {
      const timeline = gsap.timeline()
      timeline.add(titleTimeline)
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (!animateTitle) {
      gsap.set(compact([actionsRef.current, tabsRef.current]), { opacity: 1 })
    }
    // removed for now to fix about page ghost transition - lachie
    // clearTransition() // This just insures the transition state is cleared
  }, [])

  return (
    <Section tag='header' fullWidth grid noBottomMargin className={cn(styles.toolbar, className)} ref={ref}>
      <div className={cn(styles.titleContainer, hasCollectionScrollLock && styles.collectionScrollLockTitle)}>
        {!animateTitle && <h1>{title}</h1>}
        {animateTitle && <RotateUpSplitText initialOpacity={0} duration={1.5} stagger={1.35} tag='h1' onTimelineCreated={onTitleTimelineCreated}>{title}</RotateUpSplitText>}
      </div>
      {hasCollectionScrollLock && <div className={styles.collectionScrollLockText} ref={collectionScrollLockTextRef}>{collectionScrollLockText}</div>}
      {!hasCollectionScrollLock && <div className={styles.actions} ref={actionsRef}>{children}</div>}
      {!hasCollectionScrollLock && <Tabs className={styles.tabs} tabs={useLocalNavigation ? sectionTabs : collectionTabs} hideFilterPills={hideFilterPills} ref={tabsRef} />}
    </Section>
  )
})

const useStyles = createUseStyles({
  collectionScrollLockText: {
    gridRow: '4',
    gridColumn: ' 3 / span 7',
    textIndent: '16.55vw',
    // opacity: 0,
    fontSize: 20,
    marginTop: 48,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      fontSize: 14,
      marginTop: 0
    }
  },
  toolbar: {
    paddingTop: theme.spacing(14),
    margin: `0 ${theme.gutter.sm}px ${theme.spacing(8)}px`,
    [theme.breakpoints.up('md')]: {
      height: 341,
      paddingTop: theme.spacing(26),
      margin: `0 ${theme.gutter.md}px ${theme.spacing(16)}px`
    }
  },
  titleContainer: {
    gridColumn: '1 / -1',
    gridRow: 1,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 6',
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& h1': {
      marginBottom: 0
    }
  },
  tabs: {
    gridColumn: '1 / -1',
    gridRow: 2,
    opacity: 0
  },
  actions: {
    opacity: 0,
    gridColumn: '1 / -1',
    gridRow: 3,
    [theme.breakpoints.up('md')]: {
      justifySelf: 'end',
      gridRow: '1 / 3',
      alignSelf: 'flex-end',
      gridColumn: 'span 6'
    }
  },
  collectionScrollLockTitle: {
    maxWidth: '40em'
  }
})

export default NavigationTabs
