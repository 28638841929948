import cn from 'classnames'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { grid, theme } from '../../../styles/theme'
import ResponsiveImage from '../../ResponsiveImage'
import Section from '../../Section'
import Buttons from './Buttons'
import RichContent from '../../RichContent'

const TradeLandingHero = ({ data, page, summary }) => {
  const { title: sliceTitle, links, introRichContent, services } = data
  const { title: pageTitle, featuredImage } = page

  const styles = useStyles()
  const title = useMemo(() => sliceTitle || pageTitle, [sliceTitle, pageTitle])

  return (
    <Section grid className={cn(styles.root)}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>{title}</h1>
        {!!links && <Buttons items={links} className={styles.buttons} summary={summary} />}
      </div>
      {featuredImage && (
        <ResponsiveImage className={cn(styles.image)} image={featuredImage} loading={summary ? 'eager' : 'lazy'} showPreview={!summary} aspect={1406 / 1718} mobileAspect={327 / 423} />
      )}
      <div className={styles.details}>
        <div className={styles.introWrapper}>
          <RichContent content={ introRichContent } className={styles.introContent} />
          <div className={styles.services}>
            <p>{services.title}</p>
            <ul className={styles.serviceList}>
              {services.serviceList.map((service, index) => (
                <li key={index}>
                  <span>0{index + 1}</span> <span>{service}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  root: `
    padding-top: ${theme.spacingPx(14)};
    row-gap: 0!important;
    ${theme.breakpoints.up('md')} {
      padding-top: ${theme.spacingPx(26)};
    }
  `,
  titleWrapper: `
    grid-column: 1 / -1;
    ${theme.breakpoints.up('md')} {
      height: 264px;
    }
  `,
  title: `
    margin-bottom: ${theme.spacingPx(2)};
    max-width: 10em;
    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacingPx(4)};
    }
  `,
  buttons: `
    margin-bottom: ${theme.spacingPx(5)};
    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacingPx(7)};
    }
  `,
  image: `
    grid-column: 1 / -1;
    margin-bottom: ${theme.spacingPx(4)};
    ${theme.breakpoints.up('md')} {
      grid-column: 1 / span 4;
      margin-bottom: 0;
    }
  `,
  details: `
    grid-column: 1 / -1;
    ${theme.breakpoints.up('md')} {
      grid-column: 5 / span 8;
    }
    ${theme.breakpoints.up(1352)} {
      grid-column: 5 / span 8;
    }
  `,
  desktopTitle: `
    display: none;
    ${theme.breakpoints.up('md')} {
      display: block;
    }
  `,
  introWrapper: `
    ${theme.breakpoints.up('md')} {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      column-gap: ${grid.md.gutter}px;
    }
  `,
  introContent: `
    ${theme.breakpoints.up('md')} {
      grid-column: span 3;
    }
  `,
  services: `
    grid-column: 5 / span 3;
    max-width: 15em;
  `,
  serviceList: `
    list-style: none none;
    padding-left: 0;

    li {
      height: 35px;
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0,0,0,0.1);
      &:last-child {
        border-bottom: 1px solid rgba(0,0,0,0.1);
      }

      span:first-child {
        font-size: 9px;
        margin-right: ${theme.spacingPx(2)};
      }
      span:last-child {
        font-size: inherit;
      }
    }
  `
})

TradeLandingHero.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    intro: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.object),
    services: PropTypes.shape({
      title: PropTypes.string.isRequired,
      serviceList: PropTypes.arrayOf(PropTypes.string)
    })
  }).isRequired,
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    featuredImage: PropTypes.object
  }).isRequired
}

export default TradeLandingHero
