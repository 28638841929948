import { useCallback } from 'react'
import useSnapshot from '../store/useSnapshot'
import { getPageData } from '../store/layoutSlice'
import locales from '../../locales'
import { getIsImperialUnitSystem } from '../store/unitSystemSlice'

const METRIC_TO_IMPERIAL_CONVERSION_FACTOR = 39.3701

const convertMetricDimensionsToImperial = (metricString) => {
  const isCircular = metricString.includes('diam.')
  if (isCircular) {
    const metricDiameter = metricString.replace('m diam.', '')
    const imperialDiameter = convertMetricToImperial(metricDiameter)
    return `${imperialDiameter} diam.`
  }
  const isRectangle = metricString.includes(' x ')
  if (isRectangle) {
    const metricSizeArray = metricString.split(' x ')
    const metricHeight = metricSizeArray[0]
    const imperialHeight = convertMetricToImperial(metricHeight)
    const metricWidth = metricSizeArray[1].replace('m', '')
    const imperialWidth = convertMetricToImperial(metricWidth)
    return `${imperialHeight} × ${imperialWidth}`
  }

  // string must not be a circular or rectangular set of dimensions, therefore fallback to original string
  return metricString
}

const convertImperialDimensionsToMetric = (imperialDimensions) => {
  const isCircular = imperialDimensions.includes('diam.')
  if (isCircular) {
    const imperialDiameter = imperialDimensions.replace('diam.', '')
    const metricDiameter = convertImperialToMetric(imperialDiameter)
    return `${metricDiameter}m diam.`
  }
  const isRectangle = imperialDimensions.includes(' x ')
  if (isRectangle) {
    const imperialSizeArray = imperialDimensions.split(' x ')
    const imperialHeight = imperialSizeArray[0]
    const metricHeight = convertImperialToMetric(imperialHeight)
    const imperialWidth = imperialSizeArray[1].replace('m', '')
    const metricWidth = convertImperialToMetric(imperialWidth)
    return `${metricHeight} × ${metricWidth}m`
  }

  // string must not be a circular or rectangular set of dimensions, therefore fallback to original string
  return imperialDimensions
}

const convertMetricToImperial = (meters) => {
  let inches = meters * METRIC_TO_IMPERIAL_CONVERSION_FACTOR
  const feet = Math.floor(inches / 12)
  inches = Math.round(inches % 12)
  if (inches === 12) {
    return `${feet + 1}'`
  }

  return `${feet}'${inches}"`
}

const convertImperialToMetric = (imperialString) => {
  const imperialStringArray = imperialString.split("'")
  const feet = imperialStringArray[0]
  const inches = Number(imperialStringArray[1]?.replace('"', '')) + feet * 12
  const meters = inches / METRIC_TO_IMPERIAL_CONVERSION_FACTOR
  return meters.toFixed(1).replace('.0', '')
}

export function useConvertUnitSystem () {
  const snap = useSnapshot()
  const locale = getPageData(snap)?.page?.locale
  const isImperialUnitSystem = getIsImperialUnitSystem(snap)

  const convertSizeString = useCallback((sizeObject, targetFormat) => {
    if ((locale === locales.countryCodes.us.locale && !isImperialUnitSystem) || (targetFormat === 'metric')) {
      if (sizeObject.conversionOverrideValue) {
        return sizeObject.conversionOverrideValue.replace('x', '×')
      }
      // If en-us and metric selected - convert imperial to metric
      return convertImperialDimensionsToMetric(sizeObject.value)
    } else if ((locale === locales.countryCodes.au.locale && isImperialUnitSystem) || (targetFormat === 'imperial')) {
      if (sizeObject.conversionOverrideValue) {
        return sizeObject.conversionOverrideValue.replace('x', '×')
      }
      // If en-au and imperial selected - convert metric to imperial
      return convertMetricDimensionsToImperial(sizeObject.value)
    }

    return sizeObject.value.replace('x', '×')
  }, [isImperialUnitSystem])

  return convertSizeString
}
