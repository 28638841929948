import cn from 'classnames'
import Section from '../../Section'
import React, { useMemo, useRef } from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { grid, theme } from '../../../styles/theme'
import Tile from './Tile'
import reverse from 'lodash/reverse'
import forEach from 'lodash/forEach'
import findLast from 'lodash/findLast'
import get from 'lodash/get'
import NavigationTabs from '../NavigationTabs'
import useIsomorphicLayoutEffect from '../../../hooks/useIsomorphicLayoutEffect'
import gsap from 'gsap'

export default function InstagramListing ({ data, summary }) {
  const styles = useStyles()
  const { title, tiles } = data

  const tileRows = useMemo(() => {
    const rows = []
    const remainingTiles = reverse([...tiles]).slice(0, 25)

    // This will ensure it is the same on the server as well as the client
    let seed = 84
    // Simple but not high quality seeded randomness
    const random = () => {
      const x = Math.sin(seed++) * 10000
      return x - Math.floor(x)
    }

    const insertTile = (row, tile) => {
      for (let i = 0; i < row.spaces; i++) {
        if (!row.tiles[i]) {
          row.tiles[i] = tile
          break
        }
      }
      row.remainingSpaces = row.remainingSpaces - 1
    }

    const fitTile = (tile) => {
      let fitted = false
      forEach(rows, row => {
        if (tile.orientation === row.orientation && row.remainingSpaces > 0) {
          insertTile(row, tile)
          fitted = true
        }
      })
      if (!fitted) {
        const row = { tiles: [], orientation: tile.orientation }
        if (tile.orientation === 'landscape') {
          row.spaces = 2
        } else {
          const previousSpaces = get(findLast(rows, x => x.orientation === tile.orientation), 'spaces')
          row.spaces = !previousSpaces || previousSpaces !== 3 ? 3 : 4
        }
        row.tiles[Math.floor(random() * row.spaces)] = { type: 'blank' }
        row.remainingSpaces = row.spaces - 1
        insertTile(row, tile)
        rows.push(row)
      }
    }

    while (remainingTiles.length > 0) {
      const tile = remainingTiles.pop()
      fitTile(tile)
    }
    return rows
  }, [tiles])

  const sectionRef = useRef()
  useIsomorphicLayoutEffect(() => {
    if (sectionRef.current) {
      const defaults = {
        ease: 'power2.out',
        duration: 0.8
      }
      gsap.fromTo(sectionRef.current.children, { y: 40, zIndex: 5, opacity: 0 }, { y: 0, zIndex: 5, opacity: 1, clearProps: 'transform,zIndex', stagger: 0.1, ...defaults }, '+=0.25')
    }
  }, [])

  return (
    <section className={styles.container}>
      <NavigationTabs title={title} summary={summary} />
      <Section tag='div' className={styles.grid} ref={sectionRef}>
        {tileRows.map((row, i) => {
          const className = row.spaces === 2
            ? styles.span7
            : row.spaces === 3
              ? styles.span4
              : styles.span3
          return (
            <React.Fragment key={i}>
              {row.tiles.map((tile, i) => {
                if (tile.type === 'blank') return <div key={i} className={cn(styles.tile, className, 'blank')} />
                return <Tile tile={tile} key={tile._key} className={cn(styles.tile, className, row.orientation)} />
              })}
            </React.Fragment>
          )
        })}
      </Section>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    overflow: 'hidden'
  },
  title: {
    marginBottom: theme.spacingPx(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(18)
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(${grid.sm.columns}, minmax(0px, 1fr))`,
    rowGap: theme.spacingPx(4),
    columnGap: `${grid.sm.gutter}px`,
    [theme.breakpoints.up('md')]: {
      rowGap: theme.spacingPx(10),
      columnGap: `${grid.md.gutter}px`,
      gridTemplateColumns: `repeat(${grid.md.columns}, minmax(0px, 1fr))`
    }
  },
  tile: {
    opacity: 0,
    gridColumn: 'span 4',
    '&.blank': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      }
    },
    '&.landscape': {
      gridColumn: 'span 8'
    }
  },
  span7: {
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 7'
    },
    '&.blank': {
      [theme.breakpoints.up('md')]: {
        gridColumn: 'span 5'
      }
    }
  },
  span4: {
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 4'
    }
  },
  span3: {
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 3'
    }
  }
})
