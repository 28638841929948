import { useCallback } from 'react'
import get from 'lodash/get'
import { createUseStyles } from '../../helpers/createStyles'
import RoundButton from '../RoundButton'
import { setTransition } from '../../store/layoutSlice'

export default function Tab ({ selected, tab, index, onTabChanged }) {
  const styles = useStyles()
  const url = get(tab, ['url'])
  const title = get(tab, ['text'], tab)
  const onClick = useCallback((e) => {
    if (selected) {
      e.preventDefault()
      return
    }
    // If it is a link do not call the click event, we will just route the client to the url
    setTransition('tabs', url, { index })
    if (url) return
    onTabChanged(index)
  }, [index, selected, onTabChanged, url])
  return (
    <RoundButton
      to={url}
      className={styles.button}
      active={selected}
      onClick={onClick}
      activateOnChildRoute
    >
      {title}
    </RoundButton>
  )
}

const useStyles = createUseStyles({
  button: {
    whiteSpace: 'nowrap'
  }
})
