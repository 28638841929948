import { useCallback } from 'react'
import { theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'
import ModalDialog from './Dialog/ModalDialog'
import useSnapshot from '../store/useSnapshot'
import { dismiss, isOpen } from '../store/enquiryPopup'
import { getSiteData, getSelectedCustomColor } from '../store/layoutSlice'
import RichContent from './RichContent'
import EnquiryForm from './EnquiryForm'

const EnquiryPopup = () => {
  const styles = useStyles()
  const snap = useSnapshot()
  const open = isOpen(snap)
  const selectedCustomColor = getSelectedCustomColor(snap)
  const { copy, leadTimeMessage, disclaimerMessage, submitButtonLabel, successMessage } = getSiteData(snap)?.enquiryPopup || {}
  const onClose = useCallback(() => {
    dismiss()
  }, [])

  return (
    <>
      <ModalDialog
        open={open}
        className={styles.cart}
        onClose={onClose}
        size='xxs'
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <h4 className={styles.title}>Enquire now</h4>
            <RichContent content={copy} className={styles.copy} />
            <div className={styles.productContainer}>
              <div className={styles.productDetails}>
                <div className={styles.productName}>
                  {selectedCustomColor?.label}
                </div>
                {leadTimeMessage && (
                  <span className={styles.leadTimeMessage}>{leadTimeMessage}</span>
                )}
              </div>
              <div className={styles.productImage}>
                <img src={selectedCustomColor?.topShot?.asset?.url} />
              </div>
            </div>
            <EnquiryForm size='sm' selectedColor={selectedCustomColor?.label} submitButtonLabel={submitButtonLabel} disclaimerMessage={disclaimerMessage} successMessage={successMessage} />
          </div>
        </div>
      </ModalDialog>
    </>
  )
}

const useStyles = createUseStyles({
  productContainer: {
    display: 'flex',
    gap: 16,
    background: theme.colors.pageTheme.ecru.background,
    margin: '0 -24px 24px',
    padding: '16px 24px'
  },
  productDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  productName: {
    flex: 1,
    textTransform: 'uppercase',
    fontSize: '12px'
  },
  leadTimeMessage: {
    maxWidth: 300,
    '> *': {
      fontSize: '12px',

      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  productImage: {
    width: 130,
    height: 'auto',

    '> img': {
      width: '100%',
      height: 'auto'
    }
  },
  cart: {
    backgroundColor: theme.colors.pageTheme.default.background,
    zIndex: theme.zIndex.dialog + 1,
    color: theme.colors.text,
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.cart
    }
  },
  container: {
    height: 'calc(100vh - 96px)',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.gutter.sm,
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      padding: theme.gutter.md,
      paddingBottom: 0
    }
  },
  imageContainer: {
    marginBottom: theme.spacing(4.25)
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  copy: {
    marginBottom: theme.spacing(3)
  }
})

export default EnquiryPopup
