import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import cn from 'classnames'
import { createUseStyles } from '../helpers/createStyles'

export default function Overlay ({ className, show, onClick, zIndex }) {
  const styles = useStyles()
  const ref = useRef()
  useEffect(() => {
    gsap.to(ref.current, { autoAlpha: show ? 1 : 0, duration: 0.25, ease: 'sine.inOut', pointerEvents: show ? 'all' : 'none' })
  }, [show])
  const stylesObj = { zIndex: zIndex }
  return (
    <div
      ref={ref}
      className={cn(styles.overlay, className)}
      onClick={onClick}
      style={{ ...stylesObj }}
    />
  )
}

const useStyles = createUseStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    opacity: 0,
    pointerEvents: 'none'
  }
})
