import { forwardRef } from 'react'
import cn from 'classnames'
import { theme } from '../styles/theme'
import { createUseStyles } from '../helpers/createStyles'

const Section = forwardRef(({
  className,
  tag = 'section',
  fullWidth,
  noBottomMargin,
  children,
  grid,
  ...props
}, ref) => {
  const styles = useStyles()
  const Tag = tag
  return (
    <Tag
      ref={ref}
      className={cn(className, styles.section, {
        gutter: !fullWidth,
        bottomMargin: !noBottomMargin,
        grid
      })}
      {...props}
    >
      {children}
    </Tag>
  )
})

Section.displayName = 'Section'

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    '&.gutter': {
      marginLeft: `${theme.gutter.sm}px`,
      marginRight: `${theme.gutter.sm}px`,
      [theme.breakpoints.up('md')]: {
        marginLeft: `${theme.gutter.md}px`,
        marginRight: `${theme.gutter.md}px`
      }
    },
    '&.bottomMargin': {
      marginBottom: theme.section.marginBottom.sm,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.section.marginBottom.md
      },
      [theme.breakpoints.up('xl')]: {
        marginBottom: theme.section.marginBottom.xl
      }
    },
    '&.grid': {
      ...theme.gridStyle
    },
    '.dialog-grid &.grid': {
      ...theme.dialogGridStyle
    }
  }
})

export default Section
