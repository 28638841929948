import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { createUseStyles } from '../../../helpers/createStyles'
import { span, theme } from '../../../styles/theme'
import ResponsiveImage from '../../ResponsiveImage'
import RichContent from '../../RichContent'
import Section from '../../Section'
import useSnapshot from '../../../store/useSnapshot'
import { getIndex, setIndex } from '../../../store/productCollectionSlice'
import gsap from 'gsap'
import compact from 'lodash/compact'

import NavigationTabs from '../NavigationTabs'
import RotateUpSplitText from '../../RotateUpSplitText'
import Link from '../../Link'
import { isMobile } from '../../../lib/helpers'
import Button from '../../Button'
import Pager from './Pager'
import useScrollTrigger from '../../../hooks/useScrollTrigger'
import { useIntersection } from 'use-intersection'

function Collection ({ index, last, pin, summary, collection, isAbout, collectionSubtitleType }) {
  const { productCategory, title, titleMaxWidth, image, copy, links, subtitle } = collection
  const styles = useStyles()
  const titleRef = useRef()
  const subtitleRef = useRef()
  const linkRef = useRef()
  const copyRef = useRef()
  const [ready, setReady] = useState()
  const localsRef = useRef({})

  const imageRef = useScrollTrigger(
    () => ({
      trigger: containerRef.current,
      start: 'top top',
      end: 'bottom top'
    }),
    (tl, ref) => {
      tl.fromTo(imageRef.current, { y: '0%' }, { y: '-10%', ease: 'none' })
    },
    true
  )

  const containerRef = useScrollTrigger(
    () => ({
      trigger: containerRef.current,
      start: 'top top',
      pin: pin ? containerRef.current : null,
      pinSpacing: false,
      scrub: 1,
      end: '',
      onEnter: () => {
        setIndex(index)
      },
      onEnterBack: () => {
        setIndex(index)
      },
      onLeave: () => {
        if (last) {
          setIndex(-1)
        }
      }
    }),
    null,
    true
  )

  const createTimeline = () => {
    if (!subtitleRef.current || summary) return
    if (localsRef.current.timeline) localsRef.current.timeline.kill()
    const timeline = gsap.timeline()
    timeline.add(localsRef.current.titleTimeline, 0)
    timeline.to(compact([subtitleRef.current, copyRef.current, linkRef.current?.querySelectorAll(`.${styles.collectionLink}`)]), { opacity: 1, y: 0, duration: 0.8, stagger: 0.3, ease: 'sine.inOut' }, '-=0.8')
    if (linkRef.current) {
      timeline.to(linkRef.current.querySelectorAll(`.${styles.collectionLinkUnderline}`), { scaleX: 1, duration: 0.4, stagger: 0.3, ease: 'sine.inOut' }, '-=0.6')
    }
    timeline.pause()
    localsRef.current.timeline = timeline
  }

  const onTitleTimelineCreated = useCallback((titleTimeline) => {
    localsRef.current.titleTimeline = titleTimeline
    createTimeline()
    setReady(true)
  }, [])

  const contentRef = useRef()
  const intersected = useIntersection(contentRef, { once: true, threshold: 0.5 })

  useEffect(() => {
    if (ready && intersected && !isMobile()) {
      localsRef.current.timeline.play()
    }
  }, [intersected, ready])

  useEffect(() => {
    if (ready && isMobile() && !summary) {
      localsRef.current.timeline.play()
    }
  }, [ready])

  const oneLink = links?.length > 1
  const Component = (oneLink || summary) ? 'div' : Link
  const ComponentProps = (oneLink || summary) ? {} : { link: links[0], showText: false }

  const ChildLink = (oneLink && !summary) ? Link : 'span'
  const ChildLinkProps = oneLink ? { showText: false } : { }

  const sectionSubtitle = collectionSubtitleType === 'subtitles' ? subtitle : (collectionSubtitleType === 'none' ? false : `N°0${index}`)

  return (
    <Component ref={containerRef} className={`${styles.collection} ${last ? styles.collectionLast : ''}`} style={{ '--foreground': theme.colors.white }} {...ComponentProps}>
      <div className={styles.imageContainer} ref={imageRef}>
        <ResponsiveImage image={image} className={`${styles.collectionImage} ${last ? styles.collectionImageLast : ''}`} />
        <div className={`${styles.overlay} ${last ? styles.overlayLast : ''}`} />
      </div>
      <Section grid noBottomMargin className={styles.collectionGrid}>
        <div className={styles.collectionContent} ref={contentRef}>
          <span ref={subtitleRef} className={styles.collectionSubtitle}>{sectionSubtitle}</span>
          {!summary &&
            <RotateUpSplitText initialOpacity={0} duration={1} stagger={0.85} tag='h2' className={cn(isAbout && styles.collectionTitleAbout, styles.collectionTitle)} ref={titleRef} onTimelineCreated={onTitleTimelineCreated} titleMaxWidth={titleMaxWidth}>
              {title || productCategory.name}
            </RotateUpSplitText>}
          <RichContent ref={copyRef} content={copy} className={styles.collectionCopy} />
          {links &&
            <div className={styles.collectionLinkWrap} ref={linkRef}>{links.map((link, i) => (
              <ChildLink key={i} className={styles.collectionLink} link={link} {...ChildLinkProps}>
                {link.text}
                <span className={styles.collectionLinkUnderline} />
              </ChildLink>))}
            </div>}
        </div>
      </Section>
    </Component>
  )
}

export default function ProductCollections ({ data, summary, showPager }) {
  const styles = useStyles()
  const { title, collections, lastSlideLink } = data
  const snap = useSnapshot()
  const index = getIndex(snap)

  const items = useMemo(() => {
    if (!collections) return collections
    return summary ? collections.slice(0, 1) : collections
  }, [collections, summary])

  const navigationRef = useScrollTrigger(
    () => ({
      trigger: navigationRef.current,
      start: 'top top',
      pin: navigationRef.current,
      pinSpacing: false,
      end: '',
      onEnter: () => {
        setIndex(0)
      },
      onEnterBack: () => {
        setIndex(0)
      }
    }),
    null,
    true
  )

  const isAbout = data.isAboutPage
  const collectionSubtitleType = data.collectionSubtitleType
  const useLocalNavigation = data.useLocalNavigation

  return (
    <Section fullWidth className={styles.container}>
      <div className={styles.nav} ref={navigationRef}>
        <NavigationTabs title={title} summary={summary} isAbout={isAbout} aboutText={data.aboutPageText} sections={items} useLocalNavigation={useLocalNavigation} />
      </div>
      {items && items.map((item, i) => (
        <Collection key={i} collection={item} index={i + 1} last={items.length === i + 1} isAbout={isAbout} collectionSubtitleType={collectionSubtitleType} summary={summary} pin={i < items.length - 1} />
      ))}
      {showPager && (
        <Pager
          className={cn(styles.pager, { show: index > 0 })}
          index={Math.max(index - 1, 0)}
          totalPages={items ? items.length : 0}
          onIndexChanged={(i) => { setIndex(i + 1) }}
        />
      )}
      <div className={styles.lastSlide}>
        <Button className={styles.lastSliceButton} link={lastSlideLink} size='lg' />
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  collection: {
    height: '100vh',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.white,
    textDecoration: 'none',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: '200vh',
      paddingBottom: '100vh'
    }
  },
  collectionLast: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      paddingBottom: '0'
    }
  },
  imageContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      height: '100vh'
    }
  },
  nav: {
    overflow: 'hidden',
    marginBottom: 1
  },
  collectionGrid: {
    width: '100%',
    zIndex: theme.zIndex.imageContent
  },
  collectionImage: {
    position: 'absolute !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh + 10%)'
    }
  },
  collectionImageLast: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh + 20%)'
    }
  },
  overlay: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    zIndex: theme.zIndex.image,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh + 10%)'
    }
  },
  overlayLast: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh + 20%)'
    }
  },
  collectionContent: {
    position: 'relative',
    gridColumn: '2 / -1',
    display: 'grid',
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / -1'
    }
  },
  collectionTitle: {
    fontSize: 34,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      fontSize: 48
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 64
    }
  },
  collectionTitleAbout: {
    maxWidth: '13em',
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      marginBottom: theme.spacing(2)
    }
  },
  collectionCopy: {
    maxWidth: 340,
    marginBottom: theme.spacing(4),
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      transform: 'translate(0, 5px)',
      opacity: 0
    }
  },
  collectionSubtitle: {
    position: 'absolute',
    display: 'block',
    fontSize: 12,
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
    top: `calc(-${theme.spacing(4)}px - 1.2em)`,
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      transform: 'translate(0, 5px)',
      top: 14,
      left: span(-1, 'md')
    }
  },
  collectionLink: {
    display: 'inline-block',
    position: 'relative',
    justifySelf: 'start',
    textTransform: 'uppercase',
    fontSize: 11,
    letterSpacing: '0.12em',
    marginRight: theme.spacing(2),
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      transform: 'translate(0, 5px)'
    }
  },
  collectionLinkUnderline: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.text,
    bottom: -4,
    transformOrigin: '0 50%',
    transform: 'scaleX(0)'
  },
  lastSlide: {
    backgroundColor: theme.colors.background,
    margin: `${theme.spacing(6)}px ${theme.gutter.sm}px 0`,
    display: 'flex',
    justifyContent: 'center',
    height: '0 !important',
    paddingBottom: '0 !important',
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(10)}px ${theme.gutter.md}px 0`
    }
  },
  lastSliceButton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 330
    }
  },
  pager: {
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out',
    '&.show': {
      opacity: 1
    }
  }
})
