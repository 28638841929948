import { forwardRef, useImperativeHandle, useState } from 'react'
import { createUseStyles } from '../../helpers/createStyles'
import useComposeRefs from '../../hooks/useComposeRefs'
import { theme } from '../../styles/theme'
import useSmoothScrollbar, { SmoothScrollContext } from './useSmoothScrollbar'
import cn from 'classnames'

const SmoothScrollbarProvider = forwardRef(({ pageThemeColor, children, tag = 'main', bodyScroll, scrollRef, id, disableHorizontalScroll }, ref) => {
  const styles = useStyles()
  const { ref: scrollAreaRef, scrollbarRef } = useSmoothScrollbar({ bodyScroll, restoreScrollState: false, disableHorizontalScroll })
  const MainContainer = tag
  const composedRefs = useComposeRefs(scrollAreaRef, ref)
  const [pageTheme] = useState(pageThemeColor)
  useImperativeHandle(scrollRef, () => ({
    restoreScrollState: () => {
      scrollbarRef.current.restoreScrollState()
    },
    setScrollY: (y, duration) => {
      scrollbarRef.current.setScrollY(y, duration)
    }
  }), [])

  return (
    <SmoothScrollContext.Provider value={scrollbarRef}>
      <MainContainer
        className={cn(styles.main, 'main-container')}
        ref={composedRefs}
        id={id}
        style={{
          '--foreground': pageTheme?.foreground,
          '--background': pageTheme?.background,
          '--transparentBackground': pageTheme?.transparentBackground,
          '--border': pageTheme?.border
        }}
      >
        <div className={styles.inner}>
          {children}
        </div>
      </MainContainer>
    </SmoothScrollContext.Provider>
  )
})

const useStyles = createUseStyles({
  main: {
    flex: '1',
    position: 'initial !important',
    backgroundColor: theme.colors.background,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      overflowY: 'hidden',
      overflowX: 'hidden',
      height: '100vh'
    }
  },
  inner: {
    '&:after': {
      content: '""',
      clear: 'both',
      display: 'table'
    }
  }
})

export default SmoothScrollbarProvider
