import ReactDOM from 'react-dom'
import Hamburger from './Hamburger'
import cn from 'classnames'
import { theme, grid } from '../../styles/theme'
import {
  openWishList,
  toggleSearch,
  toggleCart,
  getSiteData,
  getCollectionData,
  getPageData
} from '../../store/layoutSlice'
import { createUseStyles } from '../../helpers/createStyles'
import useSnapshot from '../../store/useSnapshot'
import Link from '../Link'
import { resolveInternalLinkUrl } from '../../lib/resolveLink'
import { useCallback, useEffect, useState } from 'react'
import { setIndex } from '../../store/productCollectionSlice'
import { isBrowser } from '../../lib/helpers'
import defer from 'lodash/defer'
import isEmpty from 'lodash/isEmpty'
import { getWishListProducts } from '../../store/wishListSlice'
import { hasCartItems } from '../../store/cartSlice'
import useIsMobile from '../../hooks/useIsMobile'
import SearchIcon from '../../icons/search.svg'
import ShoppingIcon from '../../icons/shopping-bag.svg'

const HeaderNav = ({ className }) => {
  const snap = useSnapshot()
  const { title: collectionTitle, mobileTitle: collectionTitleMobile, collectionLink } = getCollectionData(snap)
  const styles = useStyles()
  const { wishlistLabel = 'Wishlist', cartLabel, searchLabel } = getSiteData(snap)
  const cartItems = hasCartItems(snap)
  const hasWishlistItems = !isEmpty(getWishListProducts(snap))

  const onCollectionLinkClick = useCallback(() => {
    // Resets the collection index when it is clicked in the menu
    setIndex(0)
  }, [])

  const isMobile = useIsMobile()

  return (
    <div className={cn(className || null, styles.headerNav)}>
      <div className={styles.grid}>
        <div className={styles.gridLeft}>
          <Link
            className={cn(styles.navButton, 'is-caption', 'styled-link')}
            showText={false}
            to={resolveInternalLinkUrl(collectionLink)}
            onClick={onCollectionLinkClick}
          >
            { collectionTitle }
          </Link>
        </div>
        <div className={styles.gridRight}>
          <div className={styles.navGroup}>
            {isMobile && <Link
                className={cn(styles.navButton, 'is-caption', 'styled-link')}
                showText={false}
                to={resolveInternalLinkUrl(collectionLink)}
                onClick={onCollectionLinkClick}
              >
                { isMobile && !!collectionTitleMobile ? collectionTitleMobile : collectionTitle }
              </Link>
            }
            <button
              onClick={openWishList}
              className={cn(styles.wishlistButton, styles.navButton, 'is-caption', 'styled-link')}
              aria-label={`Browse ${wishlistLabel}`}
            >
              {wishlistLabel}
              <div className={cn(styles.circle, { show: hasWishlistItems })} />
            </button>
            <button
              onClick={toggleSearch}
              className={cn(styles.searchButton, styles.navButton, 'is-caption', 'styled-link')}
              aria-label='Open search dialog'
            >
              {isMobile ? <SearchIcon /> : searchLabel}
            </button>
            <button
              onClick={toggleCart}
              className={cn(styles.cartButton, styles.navButton, 'is-caption', 'styled-link')}
              aria-label='Open cart'
            >
              {isMobile ? <ShoppingIcon /> : cartLabel}
              <div className={cn(styles.circle, { show: cartItems })} />
            </button>
          </div>
          <Hamburger className={styles.hamburger} />
        </div>
      </div>
    </div>
  )
}

const HeaderNavPortal = (props) => {
  const snap = useSnapshot()
  const page = getPageData(snap)
  const { page: { slug } } = page
  const isHome = slug === 'home'

  const stickyNavContainer = isHome && isBrowser ? document.getElementById('sticky-nav') : null
  const [portal, setPortal] = useState(!!stickyNavContainer)
  useEffect(() => {
    defer(() => { setPortal(isHome && isBrowser) })
  }, [isHome])

  return (
    stickyNavContainer && portal
      ? ReactDOM.createPortal(
        <HeaderNav {...props} />,
        stickyNavContainer
        )
      : <HeaderNav {...props} />
  )
}

const useStyles = createUseStyles({
  headerNav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    zIndex: theme.zIndex.header
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: `${grid.sm.gutter}px`,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(8, 1fr)',
      columnGap: `${grid.md.gutter}px`
    }
  },
  gridLeft: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-end',
      gridColumn: '1 / span 3'
    }
  },
  gridRight: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gridColumn: '1 / span 4',
    [theme.breakpoints.down('md')]: {
      marginTop: -5
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      gridColumn: '5 / span 4'
    },
    [theme.breakpoints.up('lg')]: {
      gridColumn: '6 / span 3'
    }
  },
  navGroup: {
    lineHeight: 1,
    display: 'flex'
  },
  navButton: {
    color: theme.colors.text,
    pointerEvents: 'all',
    textDecoration: 'none',
    lineHeight: 1,
    margin: 0,
    padding: 0,
    minHeight: 42,
    display: 'flex',
    alignItems: 'center',
    marginRight: `${theme.spacing(2.5)}px`,
    fontSize: 11,
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      marginRight: `${theme.spacing(4)}px`,
      paddingTop: 4
    },
    '&:before': {
      top: 'calc(100% - 8px)'
    }
  },
  wishlistButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  searchButton: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 'unset'
    }
  },
  cartButton: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 'unset'
    }
  },
  hamburger: {
    marginBottom: '-14px'
  },
  circle: {
    marginLeft: 4,
    transform: 'scale(0)',
    height: 6,
    width: 6,
    backgroundColor: theme.colors.text,
    borderRadius: '50%',
    transition: 'transform 0.25s ease-in-out',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      marginTop: 2
    },
    '&.show': {
      display: 'block',
      transform: 'scale(1)'
    }
  }
})

export default HeaderNavPortal
