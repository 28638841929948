import { useMemo } from 'react'
import { getCartItems } from '../../store/cartSlice'
import { getProducts } from '../../store/productsSlice'
import useSnapshot from '../../store/useSnapshot'
import flatten from 'lodash/flatten'
import isSampleProduct from '../../helpers/isSampleProduct'
import { getSizeVariant } from '../../helpers/productHelper'

export const useSamplesInCart = () => {
  const snap = useSnapshot()
  const products = getProducts(snap) || []
  const cartItems = getCartItems(snap) || []
  return useMemo(() => {
    const variantIds = cartItems.map((x) => x.variant_id)
    const samplesInCart = flatten(products.map(({ product }) => product.variants))
      .filter(({ id }) => variantIds.includes(id))
      .filter(variant => isSampleProduct(getSizeVariant(variant.option_values)?.label))
    return samplesInCart
  }, [products, cartItems]) || []
}

export default useSamplesInCart
