import { useCallback, useRef, useState, useEffect } from 'react'
import { createUseStyles } from '../../helpers/createStyles'
import Accordion from '../Accordion'
import gsap from 'gsap'
import {
  setShopFilters
} from '../../store/shopFiltersSlice'
import { useConvertUnitSystem } from '../../hooks/useConvertUnitSystem'
import RoundButton from '../RoundButton'

const FilterOption = ({ label, stateKey, options, filterState }) => {
  const styles = useStyles()
  const convertSizeString = useConvertUnitSystem()
  const optionsRef = useRef()
  const relevantActiveFilters = filterState[stateKey]
  const [expanded, setExpanded] = useState(false)

  const onClick = useCallback((option, optionIsActive) => {
    if (optionIsActive) {
      const prevFilterState = filterState[stateKey]
      const newFilterState = prevFilterState.filter(item => stateKey === 'size' ? (item.value !== option.value || item.bigCommerceId !== option.bigCommerceId) : item !== option)
      const newState = {
        ...filterState,
        [stateKey]: [
          ...newFilterState
        ]
      }
      setShopFilters(newState)
    } else {
      // Option is not already active, add to state
      setShopFilters({
        ...filterState,
        [stateKey]: [
          ...filterState[stateKey],
          option
        ]
      })
    }
  }, [filterState])

  useEffect(() => {
    const tl = gsap.timeline()
    if (expanded) {
      tl.fromTo(optionsRef.current.children, { opacity: 0, y: -10 }, { opacity: 1, y: 0, duration: 0.08, stagger: 0.03 })
    }
  }, [expanded])

  if (!options) return null

  return (
    <Accordion
      className={styles.filterOption}
      label={label}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <div className={styles.options} ref={optionsRef}>
        {options.map(option => {
          let isActive = false
          const optionLabel = stateKey === 'size' ? convertSizeString(option) : option
          if (relevantActiveFilters) {
            isActive = relevantActiveFilters.findIndex(filterVal => stateKey === 'size' ? (filterVal.value === option.value || filterVal.bigCommerceId === option.bigCommerceId) : filterVal === option) !== -1
          }
          return (
            <RoundButton active={isActive}
              key={optionLabel}
              onClick={() => onClick(option, isActive)}
            >
              {optionLabel}
            </RoundButton>
          )
        })}
      </div>
    </Accordion>
  )
}

const useStyles = createUseStyles({
  filterOption: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '&:first-child': {
      borderTop: '1px solid rgba(0, 0, 0, 0.1)'
    }
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap'
  }
})

export default FilterOption
