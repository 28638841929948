import { useEffect, useState } from 'react'
import { isBrowser } from '../lib/helpers'
import useDebouncedCallback from './useDebouncedCallback'
import useWindowResize from './useWindowResize'

export function measureWindowHeight () {
  if (!isBrowser) return null
  return document.documentElement?.clientHeight || window.innerHeight
}

export default function use100vhStyle () {
  const [height, setHeight] = useState()

  const setMeasuredHeight = () => {
    const measuredHeight = measureWindowHeight()
    setHeight(measuredHeight)
  }

  useWindowResize(useDebouncedCallback(setMeasuredHeight, 200))
  useEffect(setMeasuredHeight, [])

  return height
}
