import { useEffect, useRef, useMemo } from 'react'
import get from 'lodash/get'
import PageDialog from './Dialog/PageDialog'
import TopBanner from './TopBanner'
import PageScrollContainer from './PageScrollContainer'
import Header from './Header'
import {
  setSiteData,
  getSiteData,
  setPagedData,
  getTransition,
  getPageData,
  setPageData,
  getDialogData,
  setDialogData,
  hasSearchBeenToggled,
  hasSamplesDialogBeenToggled,
  hasWishlistBeenToggled,
  hasShopFiltersBeenToggled,
  hasCartBeenToggled,
  isHomepageAnimatedIn,
  isSalesPopupEnabled
} from '../store/layoutSlice'
import useScrollRestoration from './SmoothScrollbar/useScrollRestoration'
import GlobalStyles from './GlobalStyles'
import { createUseStyles } from '../helpers/createStyles'
import { theme } from '../styles/theme'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'
import SalesPopup from './SalesPopup'
import EnquiryPopup from './EnquiryPopup'
import useSnapshot from '../store/useSnapshot'
import delay from 'lodash/delay'
import GorgiasChatBot from './GorgiasChatBot'
import dynamic from 'next/dynamic'

// Dynamic Imports
const Menu = dynamic(() => import('./Header/Menu'), {
  ssr: false
})
const Cart = dynamic(() => import('./Cart'), {
  ssr: false
})
const SearchDialog = dynamic(() => import('./SearchDialog'), {
  ssr: false
})
const WishlistDialog = dynamic(() => import('./Wishlist'), {
  ssr: false
})
const SamplesDialog = dynamic(() => import('./SamplesDialog'), {
  ssr: false
})
const ShopFiltersDialog = dynamic(() => import('./ShopFiltersDialog'), {
  ssr: false
})
const ColorPickerDialog = dynamic(() => import('./ColorPickerDialog'), {
  ssr: false
})

gsap.config({ nullTargetWarn: process.env.NODE_ENV !== 'production' })

const usePageAndDialogTransitionState = (data) => {
  const pageRef = useRef()
  const curtainRef = useRef()
  const snap = useSnapshot()
  const localsRef = useRef({})
  const pageData = getPageData(snap)
  const dialogData = getDialogData(snap)

  const currentPageId = get(pageData, ['page', '_id'])
  const newPageId = get(data, ['page', '_id'])
  const { type: transitionType, path: transitionPath } = getTransition(snap) || {}

  const hasCustomColors = get(data, ['page', 'customColors'])

  // We do not what anything to trigger when this changes, but we do want to use the values in the transition logic
  localsRef.current.transitionType = transitionType
  localsRef.current.transitionPath = transitionPath

  useEffect(() => {
    if (currentPageId !== newPageId) {
      const layout = get(data, ['page', 'layout'])
      if (window.innerWidth >= theme.breakpoints.values.md && layout === 'dialog' && currentPageId && !hasCustomColors) {
        setDialogData(data)
      } else {
        if (localsRef.current.timeline) {
          localsRef.current.timeline.kill()
          localsRef.current.timeline = null
        }
        // If we are transitioning from the menu or the tabs component then we do not want to run the transition animation
        if (localsRef.current.transitionType === 'menu' || localsRef.current.transitionType === 'tabs') {
          setPageData(data)
          setDialogData(null)
        } else {
          const timeline = gsap.timeline()
          // Transition Out
          // timeline.fromTo(curtainRef.current, { x: '100%' }, { x: '0%', duration: 0.8, ease: 'quart.in' })
          timeline.fromTo(pageRef.current, { opacity: 1 }, {
            opacity: 0,
            duration: 0.8,
            ease: 'power2.inOut',
            onComplete: () => {
              delay(() => {
                setPageData(data)
                setDialogData(null)
              }, 100)
            }
          }, 0)

          // Transition In
          timeline.to(pageRef.current, { opacity: 1, duration: 0.8, ease: 'power2.inOut' })
          // timeline.to(curtainRef.current, { x: '-100%', duration: 1, ease: 'expo.out' }, '+=0.5')
          timeline.set(pageRef.current, { clearProps: 'transform' })
          localsRef.current.timeline = timeline
        }
      }
    } else {
      setDialogData(null)
      if (data?.pagedData) {
        // This will update the paged data, this is for the journals page when it is paging
        // This will also stop the scroll restoration
        setPagedData(data.pagedData)
      }
    }
  }, [data, currentPageId, newPageId])

  return {
    page: pageData || data,
    dialog: dialogData,
    curtainRef,
    pageRef
  }
}

const Main = (props) => {
  const { page, children, scrollRef, topBannerRef } = props
  const pageThemeColor = useMemo(() => {
    return theme.colors.pageTheme[page.page.color?.title]
  }, [page.page.color])
  return (
    <PageScrollContainer data={page} page={page.page} bodyScroll scrollRef={scrollRef} topBannerRef={topBannerRef} id='main' pageThemeColor={pageThemeColor}>
      <Header page={page.page} topBannerRef={topBannerRef} />
      {children}
    </PageScrollContainer>
  )
}

const Page = (props) => {
  const styles = useStyles()
  const scrollRef = useRef()
  const topBannerRef = useRef()
  const { data, children } = props
  const snap = useSnapshot()
  const { page, dialog, curtainRef, pageRef } = usePageAndDialogTransitionState(data)
  const isHomepageAnimating = page.page.slug === 'home' && !isHomepageAnimatedIn(snap)
  const siteData = getSiteData(snap)

  // Sets the site data when it changes
  if (!siteData || siteData._id !== page.settings._id) {
    setSiteData(page.settings)
  }

  useScrollRestoration(page, scrollRef)
  return (
    <div>
      <GlobalStyles />
      <div className={styles.curtain} id='curtain' ref={curtainRef} />
      <div className={styles.main}>
        <div className={styles.inner} ref={pageRef}>
          { !isHomepageAnimating && <Menu /> }
          { siteData.topBannerMessage && primaryInput !== 'touch' && (
            <TopBanner content={siteData.topBannerMessage} topBannerRef={topBannerRef} />
          )}
          <Main page={page} scrollRef={scrollRef} topBannerRef={topBannerRef}>{children}</Main>
          { <PageDialog dialog={dialog} page={page.page} />}
          { hasWishlistBeenToggled(snap) && <WishlistDialog /> }
          { hasSearchBeenToggled(snap) && <SearchDialog /> }
          { hasSamplesDialogBeenToggled(snap) && <SamplesDialog page={page.page} /> }
          { hasCartBeenToggled(snap) && <Cart /> }
          { !isHomepageAnimating && page?.page?.slug !== 'subscribe' && isSalesPopupEnabled(snap) && <SalesPopup />}
          { hasShopFiltersBeenToggled(snap) && <ShopFiltersDialog /> }
          { page?.page?.slug === 'agra' && (
            <>
              <ColorPickerDialog />
              <EnquiryPopup />
            </>
          ) }
          <GorgiasChatBot key='gorgiasChatBot' />
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  main: {
    // overflowX: 'hidden'
  },
  inner: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  curtain: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.colors.pageTheme.moss.background,
    zIndex: theme.zIndex.pageTransitionCurtain,
    transform: 'translate(100%, 0)'
  }
})

export default Page
