import { createUseStyles } from '../../helpers/createStyles'
import { theme } from '../../styles/theme'
import { setUnitSystemToImperial, setUnitSystemToMetric, getIsImperialUnitSystem } from '../../store/unitSystemSlice'
import useSnapshot from '../../store/useSnapshot'
import cn from 'classnames'

const StockFilter = ({ label }) => {
  const styles = useStyles()
  const snap = useSnapshot()
  const isImperialUnits = getIsImperialUnitSystem(snap)

  return (
    <div className={styles.stockFilterWrapper}>
      <div className={styles.unitSystemToggle}>
        <span>{label}</span>
        <div className={styles.unitSystemButtonsWrapper}>
          <button role='button' className={cn('is-caption', styles.unitToggle, isImperialUnits && 'active')} onClick={() => setUnitSystemToImperial()}>IMPERIAL</button>
          <button role='button' className={cn('is-caption', styles.unitToggle, !isImperialUnits && 'active')} onClick={() => setUnitSystemToMetric()}>METRIC</button>
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  stockFilterWrapper: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '&:first-child': {
      borderTop: '1px solid rgba(0, 0, 0, 0.1)'
    }
  },
  unitSystemToggle: {
    width: '100%',
    fontSize: 18,
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px 0px`
  },
  unitSystemButtonsWrapper: {
    display: 'flex'
  },
  unitToggle: {
    opacity: 0.5,
    '&.active': {
      opacity: 1
    }
  }
})

export default StockFilter
