import { useMemo, useCallback } from 'react'
import forEach from 'lodash/forEach'
import findIndex from 'lodash/findIndex'
import slice from 'lodash/slice'
import map from 'lodash/map'
import ErrorBoundary from '../ErrorBoundary'
import filter from 'lodash/filter'
import dynamic from 'next/dynamic'

// Add a import instead of a dynamic import will prevent a content jump as the dynamic javascript is loaded.
// For any slices that would appear at the top of the page we should add them to the import list
import InstagramListing from './InstagramListing'
import ProductDetails from './ProductDetails'
import HomeHero from './HomeHero'
import JournalListing from './JournalListing'
import JournalHero from './JournalHero'
import IntroHero from './IntroHero/IntroHero'
import ProductListing from './ProductListing'
import SampleListing from './SampleListing'
import ProductCollections from './ProductCollections'
import QuestionAnswerList from './QuestionAnswerList'
import TradeLandingHero from './TradeHero/TradeLandingHero'
import TradeChildHero from './TradeHero/TradeChildHero'
import PageHero from './PageHero'
import LandingHero from './LandingHero'
import { useScrollListener } from '../SmoothScrollbar/useSmoothScrollbar'
import useSnapshot from '../../store/useSnapshot'
import { isSalesPopupEnabled, setIsSalesPopupEnabled } from '../../store/layoutSlice'
import CustomColorListing from './CustomColorListing'

const ProductSlices = dynamic(() => import('./ProductSlices'))
const ContactSlice = dynamic(() => import('./ContactSlice'))
const ProductRelatedPosts = dynamic(() => import('./ProductRelatedPosts'))
const ProductImageGrid = dynamic(() => import('./ProductImageGrid'))
const CollectionPreview = dynamic(() => import('./CollectionPreview'))
const PostTiles = dynamic(() => import('./PostTiles'))
const BackgroundColorSwitcher = dynamic(() => import('./BackgroundColorSwitcher'))
const ContentSlice = dynamic(() => import('./ContentSlice'))
const TextSlice = dynamic(() => import('./TextSlice'))
const ThreeColumnContent = dynamic(() => import('./ThreeColumnContent'))
const FullWidthImage = dynamic(() => import('./FullWidthImage'))
const ProductTiles = dynamic(() => import('./ProductTiles'))
const JournalText = dynamic(() => import('./JournalText'))
const JournalPlaylist = dynamic(() => import('./JournalPlaylist'))
const JournalRelatedArticles = dynamic(() => import('./JournalRelatedArticles'))
const ImageBlockSlice = dynamic(() => import('./ImageBlockSlice'))
const CareerListing = dynamic(() => import('./CareerListing'))
const CareerContent = dynamic(() => import('./CareerContent'))
const TwoColumnContent = dynamic(() => import('./TwoColumnContent'))
const AboutRichContent = dynamic(() => import('./AboutRichContent'))
const Video = dynamic(() => import('./Video'))
const CustomColors = dynamic(() => import('./CustomColors/CustomColors'))
const HorizontalLine = dynamic(() => import('./HorizontalLine'))
const Showrooms = dynamic(() => import('./Showrooms'))
const PolicySlice = dynamic(() => import('./PolicySlice'))
const FourImageGrid = dynamic(() => import('./FourImageGrid'))

const sliceComponentSelector = {
  home_hero: HomeHero,
  content_slice: ContentSlice,
  policy_slice: PolicySlice,
  contact_slice: ContactSlice,
  text_slice: TextSlice,
  three_column_content_slice: ThreeColumnContent,
  full_width_image_banner: FullWidthImage,
  product_tiles: ProductTiles,
  product_details_slice: ProductDetails,
  product_image_grid_slice: ProductImageGrid,
  product_journal_posts_slice: ProductRelatedPosts,
  collection_preview: CollectionPreview,
  post_tiles: PostTiles,
  product_listing: ProductListing,
  sample_listing: SampleListing,
  custom_color_listing: CustomColorListing,
  instagram_listing: InstagramListing,
  journal_listing: JournalListing,
  journal_hero: JournalHero,
  image_block: ImageBlockSlice,
  journal_text: JournalText,
  journal_playlist: JournalPlaylist,
  journal_related_articles: JournalRelatedArticles,
  landing_hero: LandingHero,
  product_collections: ProductCollections,
  intro_hero: IntroHero,
  page_hero: PageHero,
  career_listing: CareerListing,
  career_content: CareerContent,
  two_column_content: TwoColumnContent,
  about_content: AboutRichContent,
  video: Video,
  faqs: QuestionAnswerList,
  trade_landing_hero: TradeLandingHero,
  trade_child_hero: TradeChildHero,
  custom_colors: CustomColors,
  horizontal_line: HorizontalLine,
  showrooms: Showrooms,
  fourImageGrid: FourImageGrid
}

function groupSlices (page, slices, summary, topBannerRef) {
  const rawGroups = []
  const rawPreListingGroups = []
  let currentGroup = { slices: [] }
  let currentPreListingGroup = { slices: [] }
  rawGroups.push(currentGroup)
  rawPreListingGroups.push(currentPreListingGroup)

  // Check if current page includes Product Listing slice; if true, pass thru slices before it
  // TO DO: create new slice/page type to support adding slices more intuitively on product listing pages
  const productListingSliceIndex = findIndex(slices, e => e._type === 'product_listing')
  const preListingSlices = slice(slices, 0, productListingSliceIndex)

  forEach(slices, (slice, i) => {
    if (i >= productListingSliceIndex) {
      if (i === productListingSliceIndex) {
        forEach(preListingSlices, (currSlice, j) => {
          if (currSlice._type === 'background_color_slice') {
            currentPreListingGroup = {
              backgroundColorSlice: currSlice,
              slices: []
            }
            rawPreListingGroups.push(currentPreListingGroup)
          } else {
            const Component = sliceComponentSelector[currSlice._type]
            if (Component) {
              currentPreListingGroup.slices.push(
                <ErrorBoundary key={`slice-${page._key}-${currSlice._key}`}>
                  <Component data={currSlice} page={page} summary={summary} first={i === 0} topBannerRef={topBannerRef} />
                </ErrorBoundary>
              )
            }
          }
        })
      }
      if (slice._type === 'background_color_slice') {
        currentGroup = {
          backgroundColorSlice: slice,
          slices: []
        }
        rawGroups.push(currentGroup)
      } else {
        const Component = sliceComponentSelector[slice._type]
        if (Component) {
          if (slice._type === 'product_listing') {
            Object.assign(slice, { preListingSlices: preListingSlices })
          }

          currentGroup.slices.push(
            <Component key={slice._key} data={slice} page={page} summary={summary} first={i === 0} topBannerRef={topBannerRef} />
          )
        }
      }
    }
  })

  return filter(rawGroups, ({ slices }) => slices.length > 0)
}

const Slices = ({ page, slices, summary, topBannerRef }) => {
  const snap = useSnapshot()
  const salepopupEnabled = isSalesPopupEnabled(snap)
  useScrollListener(useCallback(({ y }) => {
    if (y >= document.documentElement?.clientHeight * 4) {
      setIsSalesPopupEnabled(true)
    }
  }, []), !salepopupEnabled)
  const sliceComponents = useMemo(() => {
    const groups = groupSlices(page, slices, summary, topBannerRef)
    return map(groups, (group, groupIndex) => {
      const { backgroundColorSlice, slices: groupedSlices } = group
      return !backgroundColorSlice
        ? groupedSlices
        : (
          <BackgroundColorSwitcher key={`group-${groupIndex}`} data={backgroundColorSlice}>
            {groupedSlices}
          </BackgroundColorSwitcher>
          )
    })
  }, [slices, page])

  if (page?.pageType === 'product') {
    return (
      <>
        <ProductSlices page={page}>{sliceComponents}</ProductSlices>
      </>
    )
  }

  return sliceComponents
}

export default Slices
