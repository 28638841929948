const formatters = {}

const createFormatters = (currency, locale, currencyDisplay) => {
  return {
    wholeNumber: new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: currencyDisplay || 'narrowSymbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }),
    fraction: new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: currencyDisplay || 'narrowSymbol'
    })
  }
}

export const formatCurrency = (price, locale, currency = 'AUD', currencyDisplay) => {
  const key = `${locale}-${currency}-${currencyDisplay || 'default'}`
  if (!formatters[key]) {
    try {
      formatters[key] = createFormatters(currency, locale, currencyDisplay)
    } catch {
      formatters[key] = createFormatters(currency, locale, 'symbol')
    }
  }

  const formatter = price % 1 === 0 ? formatters[key].wholeNumber : formatters[key].fraction
  return formatter.format(price)
}
