import React, { useRef, useCallback, useMemo, useState } from 'react'
import cn from 'classnames'
import ResponsiveImage from '../../ResponsiveImage'
import { theme } from '../../../styles/theme'
import { useProductVariantDetails } from '../../../hooks/useProductVariantDetails'
import gsap from 'gsap'
import { createUseStyles } from '../../../helpers/createStyles'
import Placeholder, { PlaceholderProductMeta } from '../../Placeholder'
import useScrollTrigger from '../../../hooks/useScrollTrigger'
import AddToCartButton from '../../Product/AddToCartButton'
import ProductPrice from '../../ProductPrice'
import useSamplesInCart from '../../SamplesDialog/useSamplesInCart'
import { get } from 'lodash'
import { getColorVariant, getSizeVariant } from '../../../helpers/productHelper'
import isSampleProduct from '../../../helpers/isSampleProduct'
import { addToCart, removeItemFromCart } from '../../../store/cartApi'
import useIsMobile from '../../../hooks/useIsMobile'
import { useHoverEvents } from '../../../hooks/useHover'
import { primaryInput } from 'detect-it'
import { getSiteData } from '../../../store/layoutSlice'
import useSnapshot from '../../../store/useSnapshot'
import { getCartItems } from '../../../store/cartSlice'

const MAX_SAMPLE_COUNT_AU = 3
const MAX_SAMPLE_COUNT_US = 5

const SampleTile = ({ tileIndex, className, product, colorVariantId, layout, aspect, mobileAspect, placeholder, currencyCode, animateIn, addToCartLabel }) => {
  const featuredImageRef = useRef()
  const isMobile = useIsMobile()
  const styles = useStyles()
  const snap = useSnapshot()
  const locale = getSiteData(snap).locale
  const maxSampleCount = locale === 'en-US' ? MAX_SAMPLE_COUNT_US : MAX_SAMPLE_COUNT_AU
  const cartItems = getCartItems(snap)
  const samplesInCart = useSamplesInCart()
  const [isHovering, setIsHovering] = useState(false)
  const layoutAspect = layout === 'portrait' ? 563 / 752 : 566 / 424
  const desktopAspect = aspect || layoutAspect

  const sampleVariant = useMemo(() => {
    if (product) {
      const variants = get(product, 'product.variants')
      return variants.find(variant => {
        const { option_values: optionValues } = variant
        const variantSize = getSizeVariant(optionValues)
        const variantColor = getColorVariant(optionValues)
        return (isSampleProduct(variantSize?.label) && variantColor?.id === colorVariantId)
      })
    }
  }, [product, colorVariantId])

  const isInCart = useMemo(() => {
    if (sampleVariant?.id) {
      return samplesInCart.find((sample) => sample.id === sampleVariant.id)
    }
    return false
  }, [samplesInCart, sampleVariant])

  const { title, colorVariant, featureImage, productId } = useProductVariantDetails({ product, variantId: sampleVariant?.id, colorVariantId })
  const topShotImage = colorVariant?.topShot
  const isOutOfStock = product?.product?.availability !== 'available' || product?.product?.inventory_level === 0
  const stockStatus = isOutOfStock ? 'Out of Stock' : 'In Stock'
  const isDisabled = useMemo(() => ((!isInCart && samplesInCart.length >= maxSampleCount) || isOutOfStock), [isInCart, samplesInCart, isOutOfStock])

  const tileRef = animateIn ? useScrollTrigger(() => ({
    trigger: tileRef.current,
    start: () => 'top bottom',
    scrub: false
  }),
  (tl, ref) => {
    tl.from(ref.current, { y: 40, opacity: 0, delay: 0.2 * (tileIndex % 4), ease: 'expo.out', duration: 1.5 })
  }) : useRef()

  useHoverEvents(primaryInput !== 'touch', useCallback((hovering) => {
    if (featuredImageRef.current && !isDisabled) {
      setIsHovering(hovering)
      gsap.to(featuredImageRef.current, { opacity: hovering ? 1 : 0, duration: 0.15, ease: 'sine.inOut' })
    }
  }, [isDisabled]), tileRef)

  const removeItemFromCartCallback = useCallback(() => {
    const datalayerObject = {
      id: sampleVariant.id,
      name: product.title,
      category: product.primaryCategory?.name,
      variant: sampleVariant.option_values.map((option) => option.label).join(' - '),
      price: sampleVariant.price.tax_inclusive
    }

    const cartItem = cartItems.find((item) => item.variant_id === sampleVariant.id)
    removeItemFromCart(cartItem.id, datalayerObject, currencyCode, sampleVariant.id)
  }, [sampleVariant, product, cartItems])

  const onArticleClick = useCallback(() => {
    if (isInCart) {
      removeItemFromCartCallback()
    } else {
      const datalayerObject = {
        id: sampleVariant.id,
        name: product.title,
        category: product.primaryCategory?.name,
        variant: sampleVariant.option_values.map((option) => option.label).join(' - '),
        price: sampleVariant.price.tax_inclusive
      }

      addToCart([{
        productId,
        variantId: sampleVariant.id,
        quantity: 1
      }], [], false, [datalayerObject], currencyCode, samplesInCart.length === (maxSampleCount - 1))
    }
  }, [isInCart])

  if (placeholder) {
    return (
      <article className={className}>
        <div className={styles.link}>
          <Placeholder type='image' aspect={desktopAspect} mobileAspect={mobileAspect} />
          <PlaceholderProductMeta />
          <Placeholder type='text' multiline={2} />
        </div>
      </article>
    )
  }

  if (!product) return null

  const renderPrice = () => <ProductPrice allowFree productId={productId} variantId={sampleVariant?.id} placeholderWidth={40} />

  const renderButtonText = useMemo(() => {
    const isSampleCartFull = samplesInCart.length >= maxSampleCount

    // If sample cart is full and sample is not currently in cart
    if (isSampleCartFull && !isInCart) {
      return (<span className={styles.buttonLabel}>
        <span>SAMPLE CART FULL</span>
        <span className={styles.buttonSecondLabel}>{`${samplesInCart.length} of ${maxSampleCount}`}</span>
      </span>)
    }

    // If item has already been added to cart and hovering
    if (isHovering && isInCart) {
      return (<span className={styles.buttonLabel}>
        <span>REMOVE SAMPLE</span>
        <span className={styles.buttonSecondLabel}>{`${samplesInCart.length} of ${maxSampleCount}`}</span>
      </span>)
    }

    // If item has already been added to cart and not hovering
    if (!isHovering && isInCart) {
      return (<span className={styles.buttonLabel}>
        <span>SAMPLE ADDED</span>
        <span className={styles.buttonSecondLabel}>{`${samplesInCart.length} of ${maxSampleCount}`}</span>
      </span>)
    }

    // If item has not been added to cart
    if (!isInCart) {
      return (<span className={styles.buttonLabel}>
        <span>{addToCartLabel}</span>
        <span className={styles.buttonSecondLabel}>{ renderPrice() }</span>
      </span>)
    }
  }, [isHovering, isInCart, samplesInCart])

  return (
    <article className={cn(className, isDisabled && styles.articleDisabled)} ref={tileRef}>
      <button className={styles.articleButton} onClick={onArticleClick} disabled={isDisabled}>
        {topShotImage && (
          <ResponsiveImage
            className={cn(styles.image, { showBackgroundColor: true })}
            image={topShotImage}
            aspect={desktopAspect}
            mobileAspect={mobileAspect}
            showPreview={false}
            background='transparent'
          >
            {featureImage && (
              <ResponsiveImage
                className={cn(styles.topShotImage)}
                image={featureImage}
                aspect={desktopAspect}
                ref={featuredImageRef}
                showPreview={false}
                background='transparent'
              />
            )}
            </ResponsiveImage>
        )}
        {!topShotImage && <Placeholder type='image' aspect={desktopAspect} mobileAspect={mobileAspect} blink={false} />}
        <div className={styles.meta}><span className={styles.subtitle}>{ isMobile ? renderPrice() : 'SAMPLE' }</span><span>{product?.primaryCategory?.name}</span></div>
        <span className={styles.title}>
          <span className={styles.titleText}>{title}</span>{colorVariant && <span className={styles.variant}>{colorVariant?.label}</span>}
        </span>
      </button>
      <AddToCartButton
        className={styles.addToCartButton}
        product={product}
        productId={productId}
        variantId={sampleVariant?.id}
        disabled={isDisabled}
        size='sm'
        openCart={samplesInCart.length === 4}
        active={isInCart || isHovering}
        onClickOverride={isInCart ? removeItemFromCartCallback : null}
      >
        {isOutOfStock
          ? stockStatus
          : (renderButtonText)}
      </AddToCartButton>
    </article>
  )
}

const useStyles = createUseStyles({
  articleDisabled: {
    opacity: '0.5 !important'
  },
  articleButton: {
    padding: 0,
    textDecoration: 'none',
    display: 'block',
    width: '100%',
    textAlign: 'left',
    '&:disabled': {
      cursor: 'default'
    }
  },
  image: {
    marginBottom: theme.spacingPx(1),
    '&.showBackgroundColor': {
      backgroundColor: theme.colors.imageBackground
    }
  },
  meta: {
    fontSize: 10,
    lineHeight: 1.2,
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacingPx(1),
    '& > *': {
      whiteSpace: 'nowrap',
      display: 'block',
      opacity: '0.6'
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(2)
    }
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      opacity: 1
    }
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    fontFamily: theme.fonts.heading,
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '0.02em',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  titleText: {
    display: 'inline-block',
    marginRight: theme.spacing(1)
  },
  variant: {
    opacity: 0.5,
    display: 'inline-block'
  },
  topShotImage: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    backgroundColor: theme.colors.imageBackground
  },
  buttonLabel: {
    textTransform: 'uppercase',
    fontSize: '9px',
    lineHeight: '11px',
    textAlign: 'center',
    letterSpacing: '0.12em',
    display: 'flex'
  },
  addToCartButton: {
    width: '100%',
    marginTop: 12,
    display: 'flex',
    textTransform: 'uppercase',
    fontSize: '9px',
    lineHeight: '11px',
    textAlign: 'center',
    letterSpacing: '0.12em'
  },
  buttonSecondLabel: {
    opacity: '0.5',
    display: 'none',
    marginLeft: theme.spacingPx(2),
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  }
})

export default SampleTile
