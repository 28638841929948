import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import gsap from 'gsap'
import CustomEase from 'gsap/dist/CustomEase'

import {
  clearTransition,
  closeMenu,
  getSiteData,
  getTransition,
  isCurrentBreakpointMobile,
  isMenuOpen,
  setTransition,
  openWishList,
  isMenuPreloaded,
  setMenuLoaded
} from '../../../store/layoutSlice'
import useSnapshot from '../../../store/useSnapshot'
import { theme } from '../../../styles/theme'
import useBodyScrollLock from '../../../hooks/useBodyScrollLock'
import MenuItem from './MenuItem'
import toArray from 'lodash/toArray'
import reverse from 'lodash/reverse'
import forEach from 'lodash/forEach'
import useComposeRefs from '../../../hooks/useComposeRefs'
import { useRouter } from 'next/router'
import { resolveInternalLinkUrl } from '../../../lib/resolveLink'
import { createUseStyles } from '../../../helpers/createStyles'
import { isMobile } from '../../../lib/helpers'

gsap.registerPlugin(CustomEase)
const _ease = CustomEase.create('menuEase', '.23, 0, 0, 1')

export let menuIsTransitioning = false

const Menu = () => {
  const styles = useStyles()
  const snap = useSnapshot()
  const site = getSiteData(snap)

  const open = isMenuOpen(snap)
  const ref = useRef()
  const scrollLockRef = useBodyScrollLock(open)
  const [loaded, setLoaded] = useState()
  const { menu } = site
  const timelineRef = useRef()
  const router = useRouter()
  const { type: transitionType, options: transitionData } = getTransition(snap) || {}
  const currentBreakpointMobile = isCurrentBreakpointMobile(snap)
  const { wishlistLabel = 'Wishlist' } = site
  const preloadedMenu = isMenuPreloaded(snap)
  const [hoverEffectEnabled, setHoverEffectEnabled] = useState()

  const openWishlist = useCallback((e) => {
    openWishList()
    closeMenu()
    e.preventDefault()
  }, [])

  const menuItems = useMemo(() => {
    if (currentBreakpointMobile) {
      const items = [
        ...(menu || []),
        { onClick: openWishlist, link: { title: wishlistLabel, color: { title: 'ecru' }, size: 'small' } }
      ]
      reverse(items)
      return items
    }
    return menu ? menu.filter(x => !x.mobileOnly) : []
  }, [menu, currentBreakpointMobile, openWishlist])

  useEffect(() => {
    if (!loaded && (open || preloadedMenu)) {
      setLoaded(true)
      setMenuLoaded(true)
    }
  }, [open, preloadedMenu])

  useEffect(() => {
    timelineRef.current = gsap.timeline({
      onComplete: () => {
        setHoverEffectEnabled(open)
      }
    })
    const tl = timelineRef.current
    const menuItemElements = toArray(ref.current.children)
    if (open) {
      let size = 0
      let axis = 'x'
      if (isMobile()) {
        tl.set(menuItemElements, { x: 0 })
        axis = 'y'
        size = menuItems.length * 70 > window.innerHeight ? (window.innerHeight - 120) / menuItems.length : 70
        reverse(menuItemElements)
        forEach(menuItemElements, (item, i) => {
          // On mobile the first item is a smaller version of the other items
          if (i === menuItemElements.length - 1) {
            tl.to(item, { [axis]: `-${((size * i) + 45)}px`, ease: _ease, duration: 0.8, delay: i * 0.06 }, 0)
          } else {
            tl.to(item, { [axis]: `-${(size * (i + 1))}px`, ease: _ease, duration: 0.8, delay: i * 0.06 }, 0)
          }
        })
      } else {
        tl.set(menuItemElements, { y: 0 })
        size = menuItems.length * 200 > window.innerWidth ? (window.innerWidth - 80) / menuItems.length : window.innerWidth / 100 * 13.5
        reverse(menuItemElements)
        const delay = 0.25
        const totalDuration = 1.5
        const totalDelay = delay * (menuItemElements.length - 1)
        forEach(menuItemElements, (item, i) => {
          const itemDelay = totalDelay - (delay * i)
          tl.set(item, { zIndex: menuItemElements.length - i }, 0)
          tl.to(item, { [axis]: `-${(size * (i + 1))}px`, ease: _ease, duration: totalDuration - itemDelay, delay: itemDelay }, 0)
        })
      }
    } else {
      // If we have loaded the page from a menu click, then we close everything but the selected menu item
      const items = transitionType === 'menu'
        ? [
            ...menuItemElements.slice(0, transitionData.index),
            ...menuItemElements.slice(transitionData.index + 1)
          ]
        : menuItemElements
      tl.to(items, { x: 0, y: 0, ease: _ease, duration: 0.8, stagger: 0.02 }, 0)
    }
    return () => {
      if (timelineRef.current) timelineRef.current.kill()
    }
  }, [loaded, open, transitionType, transitionData, menuItems])

  const onMenuItemClick = useCallback((e, item) => {
    const url = resolveInternalLinkUrl(item.link)
    const index = menuItems.indexOf(item)
    // We set this here so that when the page loads we transition to the page differently.
    // The menu has the first slice already loaded, so we want to change the theme without a duration,
    // and we also want to load the any images a soon as it is rendered without a transition
    setTransition('menu', url, { index })
    // Close the other menu items, the close will not close the menu item used in the transition state
    closeMenu()

    // Prefetch the content so it is quicker to load when the animation completes
    router.prefetch(url)
    menuIsTransitioning = true

    const tl = gsap.timeline()
    const menuItemElements = toArray(ref.current.children)
    const itemToOpen = menuItemElements[index]
    // This will reset the child into it original state when it is not hovering
    tl.set(itemToOpen.children[0], { pointerEvents: 'none' })
    tl.to(itemToOpen.children[0], { x: 0, y: 0, duration: 0.1 }, 0)
    const axis = isMobile() ? 'y' : 'x'
    const menuItemTitle = itemToOpen.querySelectorAll('.menu-item-title')
    tl.to(menuItemTitle, { opacity: 0, duration: 0.25 }, 0)
    tl.to(itemToOpen, {
      [axis]: '-100%',
      ease: _ease,
      duration: 1,
      onComplete: () => {
        router.push(url)
          .then(() => {
            const tl2 = gsap.timeline()
            // The delay is there so the scroll history can restore the scroll position and you don't see a flicker
            tl2.to(itemToOpen, { opacity: 0, duration: 0.75, delay: 0.1, ease: 'sine.out' })
            tl2.set(itemToOpen.children[0], { clearProps: 'x,y', pointerEvents: 'all' })
            // We clear the transition state after we have transitioned everything in
            tl2.set(menuItemElements, { x: 0, y: 0, opacity: 1, onComplete: () => { clearTransition() } })
            tl2.add(() => {
              setTimeout(() => {
                menuIsTransitioning = false
              }, 500)
            })
            tl2.set(menuItemTitle, { opacity: 1 })
          })
      }
    }, 0)

    e.preventDefault()
  }, [menuItems])

  const composedRefs = useComposeRefs(scrollLockRef, ref)

  return (
    <div className={styles.root}>
      <div ref={composedRefs} className={styles.container}>
        {loaded && menuItems.map((menuItem, i) => (
          <MenuItem
            key={i}
            index={i}
            item={menuItem}
            onClick={menuItem.onClick || onMenuItemClick}
            open={open}
            hoverEffectEnabled={hoverEffectEnabled}
          />
        ))}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    zIndex: theme.zIndex.menu,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    pointerEvents: 'none'
  },
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    color: theme.colors.text,
    pointerEvents: 'all',
    overflow: 'visible',
    transform: 'translate(0, 100%)',
    filter: 'drop-shadow(0px -10px 20px rgba(49, 41, 36, 0.05)) drop-shadow(0px -20px 40px rgba(0, 0, 0, 0.1))',
    [theme.breakpoints.up('md')]: {
      filter: 'none',
      transform: 'translate(100%, 0)'
    }
  }
})

export default Menu
