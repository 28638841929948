import { useEffect } from 'react'

const useScrollRestoration = (page, scrollRef) => {
  useEffect(() => {
    if (page && scrollRef.current) {
      scrollRef.current.restoreScrollState()
    }
  }, [page])
}

export default useScrollRestoration
